// Encryption function
import React from 'react';
const { subtle } = window.crypto;
export const EncryptValue = async (value, encryptionKey) => {
    //alert("im crypt =ccc");
    const encoder = new TextEncoder();
    const data = encoder.encode(value);
  
    const key = await subtle.importKey(
      'raw',
      encoder.encode(encryptionKey),
      'AES-CBC',
      false,
      ['encrypt']
    );
  
    const iv = window.crypto.getRandomValues(new Uint8Array(16));
  
    const ciphertext = await subtle.encrypt(
      { name: 'AES-CBC', iv },
      key,
      data
    );
  //alert("check aa");
    const combined = new Uint8Array(iv.length + ciphertext.byteLength);
    combined.set(iv);
    combined.set(new Uint8Array(ciphertext), iv.length);
  
    const base64 = btoa(String.fromCharCode(...combined));
    //alert("check=="+base64);
    return base64;
  };
  
  // Decryption function
 export const DecryptValue = async (ciphertext, encryptionKey) => {
    const encoder = new TextEncoder();

    const decoder = new TextDecoder('utf-8');
    const data = new Uint8Array(
      atob(ciphertext)
        .split('')
        .map((c) => c.charCodeAt(0))
    );
  
    const key = await subtle.importKey(
      'raw',
      encoder.encode(encryptionKey),
      'AES-CBC',
      false,
      ['decrypt']
    );
  
    const iv = data.slice(0, 16);
    const encryptedData = data.slice(16);
  
    const decryptedData = await subtle.decrypt(
      { name: 'AES-CBC', iv },
      key,
      encryptedData
    );
  
    const plaintext = decoder.decode(decryptedData);
    return plaintext;
  };
  export default DecryptValue;
import $ from 'jquery';
import React from 'react';
import {appGetLoanUrlPath,getAppUrl} from '../function_lib/common_lib';

let appLoanUrlPath;
let getUrl;
export function View_Document_Comp(props){
  appLoanUrlPath=appGetLoanUrlPath();
  getUrl=getAppUrl();
  let arrDocPaths=[];

   if(typeof props.view_doc["doc_path"]!=="undefined"){
    //alert("i m in condtion");
      if(props.view_doc["doc_path"].trim()!==""){
       arrDocPaths=props.view_doc["doc_path"].trim().split(',');
     }   
  }
  //alert(JSON.stringify(props));
  return( <div className="modal fade"  id="modal_view_doc">
  <div className="modal-dialog modal-xl">
    <div className="modal-content">
      <div className="modal-header custom-modal-header">
        <h5 className="modal-title white_c">View Document</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
      </div>
      <div className="modal-body" style={{minHeight:"50vh"}}>
        <div className="form-group">
          {arrDocPaths.map((item,index)=>{
            if(props.view_type==="extract"){
              return <embed width="100%" height="500px" src={getUrl+"TestPDF"+"&doc="+item+'#toolbar=0&navpanes=0'} toolbar="0"></embed>
            }else{
              if(item.indexOf(".pdf")!==-1){
                return(
              <embed width="100%" height="500px" src={appLoanUrlPath+item+'#toolbar=0&navpanes=0'} toolbar="0"></embed>
                )
  
              }else if(item.indexOf("ext_orc")!==-1){
                return <embed width="100%" height="500px" src={appLoanUrlPath+item+'#toolbar=0&navpanes=0'} toolbar="0"></embed>
              }else{
                return <img className='img-responsive' src={appLoanUrlPath+item} />
              }
            }
           
           
          })}
         
       

         
        </div>
       
      </div>
      <div className="card-footer">
            {/* <button type="button" className="btn _btnSetBank btn-primary">Reject</button> */}
        </div>
    </div>

  </div>
</div>)
}

export default View_Document_Comp;
import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './icon_title.css';
// component ref from ArticleListing
export function Icon_Title(lsData){

  //return JSON.stringify(lsData);
  var appUrtPath=appGetUrlPath();
  var listData=lsData;

  let strBgImg="";
  let title="";
  if(lsData.length>0){
    strBgImg=lsData[0]["bg_img"];
    title=lsData[0]["g_title"];
   
    if(strBgImg!==""){
        strBgImg=appUrtPath+lsData[0]["bg_img"];
      strBgImg="style=\"background-size:cover;background-image:url("+strBgImg+")\"";
    }
  }
  //let isWhite="txt-white";
  let isWhite="";

  /* custome Conditions for data */
  let customDesc="";
  if(title=="offerings"){
    //alert("i m in condition")
    customDesc="<p>"+"we cater entire lifecycle of data Journey right for Architecture, engineering to Put it to business "
  } 


      var strHeader="<div "+strBgImg+" class=\"shop-category-area pb-100px pt-70px\">"+
      "<div class=\"container-sm\">"+
      "<div class=\"row\">"+
        "<div class=\"col-md-12\">"+
            "<div class=\"heading page__title-bar\">"+
                "<h3 class=\"title "+isWhite+"\">"+title+"</h3>"+
            "</div>"+
        "</div>"+
        "<div class=\"col-md-12 mb-5\">"+
        "<div class=\"heading page__title-bar "+isWhite+" \">"+
            customDesc+
        "</div>"+
    "</div>"+
    "</div>"+
          "<div class=\"row\">"+
              "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
              "<div class=\"shop-bottom-area\">"+
              "<div class=\"row\">";
       var strFooter="</div></div></div></div></div></div>";
  
      var strResp="";
      let hrefLink="";
    for(var i=0;i<listData.length;i++){
       hrefLink="blog\\"+urlTitle+"?rf="+lsData[i]["e_id"];
       if(listData[i]["col7"]!==""){
        hrefLink=listData[i]["col7"];
       }
  
      var imgPath="default-product.png"; 
      var urlTitle= lsData[i]["col1"];
      urlTitle=urlTitle.replace("?","_");
      urlTitle=urlTitle.replace(" ","-");
      if(listData[i]["col4"]!=""){
       imgPath=listData[i]["col4"];
        }
        strResp+="<div class=\"col-lg-4 col-md-4 col-sm-6 col-xs-6\">"+
        "<div class=\"border-box-1 product12 mb-5\">"+
        "<form>"+
              "<div class=\"thumb \">"+
                "<a href=\""+hrefLink+"\" class=\"image\">"+
                    "<img src=\""+appUrtPath+imgPath+"\" alt=\"Product\" />"+
                  //   "<img class=\"hover-image\" src=\""+appUrtPath+imgPath+"\" alt=\"Product\" />"+
                "</a>"+
            "</div>"+
            "<div class=\"content\">"+
                "<h6><a class=\"f_color_1\" href=\""+hrefLink+"\">"+listData[i]["col2"]+"</a></h6>"+
            "</div>"+
        "</form>"+"</div>"+
    "</div>";
    //alert(strResp);
    }
    return strHeader+strResp+strFooter;
}
export default Icon_Title;

import $ from 'jquery';
import React from 'react';
import {appGetLoanUrlPath,GetImgCertUser_CBack} from '../function_lib/common_lib';
import updFile from '../assets/images/upload.png';
import './upload_doc_opt_comp.css';
let appLoanUrlPath;

export function UpdDocSubmit(frmName){
 
  GetImgCertUser_CBack(this,frmName,"updMultiTaxReqData",function(data,msg,userMsg){
    alert(data);
  });
}

export function Upload_Doc_Opt_Comp(props){
  //alert(JSON.stringify(props));
  //alert(props.userObject);
  if(props.viewLoadDoc!=="undefined"){
   // alert(props.viewLoadDoc[0].doc_list);
  }
  return( <div class="modal" id="modal_upload_doc" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Upload Document</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form id="frmUpdDocOpt" name="frmUpdDocOpt" className='formarea' enctype="multipart/form-data" method="POST" action="#">
        <input type="hidden" name="tax_request_id" id="tax_request_id" value={props.viewLoadDoc.length>0?props.viewLoadDoc[0]._id.$oid:"00"}/>
        <input type="hidden" name="lsUpdFiles" id="lsUpdFiles" />
        <input type="hidden" name="local_token" id="local_token" value={props.userObject[0].id} />
        <input type="hidden" name="hi_docs" id="hi_docs"/>
        <div className="row">
          <div className="col-md-12">
           <div class="form-group">
           <select id="doc_s" name="doc_s" setTo="hi_docs" className="from-control select2 multiset" multiple="multiple" style={{width:"100%"}}>
            <option>-select-</option>
            {
              props.viewLoadDoc!=="undefined" && props.viewLoadDoc.length>0?props.viewLoadDoc[0].doc_list.map((item,index)=>{
                return <option value={item._id.$oid}>{item.title}</option>
              }):""
            }

           </select>
            </div>
           </div>
           <div className="col-md-12">
               {/* upload file 1 */}
               <div className="card-upd  card-info">
               <div className="row audioLibThumb imgLibThumb">
                      
                      </div>
               <a href="javascript:void(0)" setTo="customFileBr" className="_brFileThumb updthumb">
<div className="card-header">
  <h3 className="card-title center-middle">Upload Files for Selected Categories </h3>
  <img className="upd-icon img-fluid" src={updFile} />
  <small> (Max File Size 1MB) </small>
</div>
<div className="card-body">
  <div className="row">
    
    <div className="col-12">
    <input type="file" ref_img="ImageThumbBack" multiple name="customFileBr" id="customFileBr" accept="image/*,application/pdf" className="updImgThumb" />
      
    </div>
   
    
  </div>
  
</div>
</a>
</div>
{/* end of file upload */}
           </div>
          </div>
        </form>
        
      </div>
      <div class="modal-footer">
        <button type="button" onClick={()=>UpdDocSubmit("#frmUpdDocOpt")} class="btn btn-primary">Upload Now</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>);
}
export default Upload_Doc_Opt_Comp;
import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,BtnSetColor,CallMapMethod_CBack,AjaxCallMethod} from '../function_lib/common_lib';
// import listReactFiles from 'list-react-files'
import Header from './header';
import Tax_Header from '../user_components/tax_header';
import Side_Bar from './side_bar';
import Footer from './footer';
import LogoImg from '../assets/images/my_logo.jpg';
import defaultPic from '../assets/images/9.png';



let loginUser="";
let userObject="";
let logInObj;
let obClsHeaderMaster;
class Header_Master extends React.Component{

    forceUpdateHandler(){
        this.forceUpdate();
      };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          //UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= JSON.parse(localStorage.getItem("userDS"));
          logInObj=userObject;
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          //window.location.href="./Dashboard";
        }
        this.state={ listDocHeader:[]};
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        obClsHeaderMaster=this;
      }
      result(vart){
          alert(vart);
      }
      componentDidMount(){
        this.getDocumentHeaders();
        AjaxCallMethod(this,"okSuccCNOT");
         // alert(userObject[0].type);
         

          const script = document.createElement("script");
          script.src = "/main.js";
          script.async = true;
          //script.onload = () => clsObject.scriptLoaded();
        
          document.body.appendChild(script);
         
          //listReactFiles("pub_dir/").then(files => console.log(files))
      }
      okSucc(data,msg){
        if(data==="001"){
            $("#frmCreate").find("input[type=text]").val("");
            //$("#frmCreate").find()
            $("#is_edit").val("false");
            $("#types").val("0");
            $(".respSSL").html(msg);
            this.getDocumentHeaders();
        }else{
            $(".respSSL").html(msg);
        }
      
    }
    getDocumentHeaders(){
        var map=[];
        map.push({name:"curl_type",value:"getCUserEntry"});
        map.push({name:"res_func",value:"callBackParent"});
        map.push({name:"local_token",value:logInObj[0].app_user_id});
        map.push({name:"parent_id",value:"0"});
        map.push({name:"type_id",value:"5"});
        map["res_func"]="callBackParent";
        map["curl_type"]="getCUserEntry";
        CallMapMethod_CBack(this,map,function(data,msg){
            if(data!=="0" && data!==""){
                let listCate=JSON.parse(data);
                var strData="";
                obClsHeaderMaster.setState({listDocHeader:listCate});
                obClsHeaderMaster.forceUpdateHandler();
                for(var i=0;i<listCate.length;i++){
                    $(".total_loan").text((i+1));
                 strData+="<tr>"+
                        "<td>"+(i+1)+"</td>"+
                        "<td>"+listCate[i]["col1"]+
                        "<td>"+listCate[i]["status"]+"</td>"+
                        "</tr>";
                }
                //$("#tbData").html(strData);
            }
        });
    }
      
    
    
     

    render(){
        
       
        return(<div className="bg-gray">
            <Header/>
             {/* dashboard tax options  */}
            {/* <Tax_Header/> */}
            <div className="container">
            <div className="row">
                <div className="col-md-8 pb-20px pt-20px">
            <div class="card card-primary card-outline cover-height">
                <div className="card-header">
                <h5 class="card-title">Document Header</h5>
                </div>
              <div class="card-body">
              <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap table-responsive">
                  <thead>
                    <tr class="border">
                        <th>S.No.</th>
                        <th>Title</th>
                        <th>Status</th> 
                        <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="tbData">
                    {(this.state.listDocHeader.map((item,index)=>{
                      return(<tr>
                        <td>{index+1}</td>
                        <td>{item["col1"]}</td>
                        <td>{item["status"]}</td>
                        <td> <a data-toggle="modal" data-target="#divEmployee" href="javascript:void(0)" onClick={()=>this.setEditDocHeaders(item)} ><i class="fa fa-pencil text-inverse m-r-10"></i></a>
                        <a href="javascript:void(0)" onClick={()=>this.setDeleteDocHeaders(item)} ><i class="fa fa-window-close text-danger"></i></a>
                        </td>
                      </tr>)
                    }))}
                  </tbody>
                </table>

               
              </div>
            </div>
            </div>
            {/* side card */}
              <div className="col-md-4 pb-20px pt-20px">
              <div class="card" style={{width: "100%;"}}>
  
  <div class="card-body">
  <h5 class="card-title1 pb-5"><i class="fa fa-arrow-circle-right"></i> <b></b><span className="badge bg-primary total_emp"></span><span class="float-right mt-2 badge bg-primary pointer" data-toggle="modal" data-target="#divEmployee">Add New Header</span></h5>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">Total - </li>
  </ul>
</div>

              </div>

             

            </div>
           
  </div>
     
        <Footer/>
{/* add tax payer */}
<div className="modal fade bd-example-modal-xl"  id="divEmployee" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Loan Categroy</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmCreate" name="frmCreate">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                    <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="insertCUser" />
                            <input type="hidden" name="m_type" id="m_type" value="insertCUser" />
                            <input type="hidden" name="types" id="types" />
                            <input type="hidden" className='reset_val' name="is_edit" id="is_edit"/>
                            <input type="hidden" name="type" id="type" value="5" />
                            <input type="hidden" name="app_user_id" id="app_user_id" value={logInObj[0].app_user_id} />
                            <input type="hidden" name="parent_id" id="parent_id" value={logInObj[0].app_user_id} />
                            <div className="card-body">
                            <div className="row">
                              
                              <div className="col-lg-12 col-md-12">
                                  <div className="form-group">
                                      <label for="exampleInputEmail1">Documnet Headers *</label>
                                      <div className="input-group input-group-sm">
                                          <input type="text" id="col1" name="col1" className="form-control required" placeholder="" />
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                              <div className="form-group">
                                      <label for="exampleInputEmail1">Priority Index *</label>
                                      <div className="input-group input-group-sm">
                                          <input type="text" id="ref_id" name="ref_id" className="form-control required" placeholder="Priority Index" />
                                      </div>
                                  </div>
                              </div>
                          </div>
                              
                                <div className="col-md-6 respSSL red"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>

      </div>)
    }
}
export default Header_Master
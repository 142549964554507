import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import QueryString from 'query-string';
import {CallMapMethod,BtnSetColor,webUrlPath,AjaxCallMethod,CallMapMethod_CBack,RespBkMthdMapp} from '../function_lib/common_lib';
// import listReactFiles from 'list-react-files'
import Header from './header';
import Tax_Header from '../user_components/tax_header';
import Side_Bar from './side_bar';
import Footer from './footer';
import LogoImg from '../assets/images/my_logo.jpg';
import defaultPic from '../assets/images/9.png';

//
import Questionnair_Component from '../user_components/questionnair_component';

let loginUser="";
let userObject="";
let logInObj;
let clsTaxReqOpn;

let webUrl;
let rf="0";
let queries;
class Tax_Request_Opn extends React.Component{

  closeMe(clName) {
    //alert("i am in click");
    $(clName).hide();
    $(clName).removeClass("show");
  }
  showMe(clName,refId="0") {
    $(clName).show();
    $(clName).addClass("show");
  }

    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          //UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= JSON.parse(localStorage.getItem("userDS"));
          logInObj=userObject;
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          //window.location.href="./Dashboard";
        }
        if(props !=null){
            queries = QueryString.parse(this.props.location.search);
                  if(queries.rf!=null){
                    rf=queries.rf;
                  }
          }
          this.state={ listLoanRequest:[],viewLoadDoc:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          webUrl=webUrlPath();
          clsTaxReqOpn=this;
      }
      getSearch(data){
        if(data==="0"){
          data="";
        }
        //getSearchOutPut("desk_thumb","child_desk_thumb",data);
       }     


  getTaxRequest(status="0"){
    //child component use this method loan_document_upload_comp
    var map=[];
  map.push({name:"curl_type",value:"getTaxDocOpen"});
  map.push({name:"res_func",value:"callBackloanR"});
  //map.push({name:"local_token",value:logInObj[0].app_user_id});
  if(rf!=="0"){
    map.push({name:"loan_request_id",value:rf});
  }
  
  map.push({name:"parent_id",value:"0"});
  map.push({name:"type_id",value:"4"});
  map["res_func"]="callBackloanR";
  map["curl_type"]="getTaxDocOpen";
    CallMapMethod_CBack(clsTaxReqOpn,map,function(data,msg){
      if(data!=="0" && data!==""){
        let listData=JSON.parse(data);
        var strData="";
        clsTaxReqOpn.setState({listLoanRequest:listData},()=>{
            clsTaxReqOpn.viewLoanDoc(listData[0]);
           
        });
        clsTaxReqOpn.forceUpdateHandler();
      }
    });
  }
  viewLoanDoc(itemRow){
    // alert(JSON.stringify(itemRow));
  let strItemRow="["+JSON.stringify(itemRow)+"]";
  let jsItemRow=JSON.parse(strItemRow);
  this.setState({viewLoadDoc:jsItemRow},()=>{
      clsTaxReqOpn.showMe("#exampleModal");
  });
  this.forceUpdateHandler();
  }

      
  okSucc(data,smg){
    if(data==="001"){
      alert("record added successfully");
      clsTaxReqOpn.getTaxRequest();
      $("#frmCreate").find("input[type=text]").val("");
    }
    // alert(data);
  }
      componentDidMount(){
         this.getTaxRequest();
         AjaxCallMethod(this,"okSuccCNOT");
          const script = document.createElement("script");
          script.src = "/main.js";
          script.async = true;
          document.body.appendChild(script);
      }
 
    render(){
        return(<div className="bg-gray">
            <Header/>
             {/* dashboard tax options  */}
            {/* <Tax_Header/> */}
            <div className="container">
            <div className="row">
                <div className="col-md-12 pb-20px pt-20px">
            <div class="card card-primary card-outline cover-height">
                {/* <div className="card-header">
                <h5 class="card-title1 pb-5"><i class="fa fa-arrow-circle-right"></i> <b>Tax Payers</b><span className="badge bg-primary total_emp"></span><span class="float-right mt-2 badge bg-primary pointer" data-toggle="modal" data-target="#divEmployee">Add Tax Payer</span></h5>
                </div> */}
              <div class="card-body">
                <Questionnair_Component getLoanRequest={this.getLoanRequest} viewLoadDoc={this.state.viewLoadDoc}/>
             {/* update component here */}
              </div>
            </div>
            </div>
       
            </div>
           
  </div>
     
        <Footer/>
      </div>)
    }
}
export default Tax_Request_Opn
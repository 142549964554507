import React from 'react';
import {appGetUrlPath} from '../function_lib/common_lib';
import './product_listing_2.css';
export function Product_Listing(lsData){
    var appUrtPath=appGetUrlPath();
var listData=JSON.parse(lsData);
    var strHeader="<div class=\"shop-category-area pb-100px pt-70px\">"+
    "<div class=\"container\">"+
        "<div class=\"row\">"+
            "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
            "<div class=\"shop-bottom-area\">"+
            "<div class=\"row\">";
     var strFooter="</div></div></div></div></div></div>";

    var strResp="";
    let offerPer=0;
    let offerMsg="";
    let oldPrice="";
    let youSaved="";
  for(var i=0;i<listData.length;i++){
    offerMsg="";
    oldPrice=""; 
    youSaved=""; 
    var imgPath=["default-product.png"];  
    if(listData[i]["img_path"]!=""){
     imgPath=listData[i]["img_path"].split(',');
      }
      offerPer=parseInt(listData[i]["offer_per"]);
      if(listData[i]["pre_cost"]!==listData[i]["cost"]){
          oldPrice="<span class=\"old\">₹"+listData[i]["pre_cost"]+"</span>";
          youSaved="<span style=\"color:#ee7600\">"+"You save ₹ "+(parseFloat(listData[i]["pre_cost"])-parseFloat(listData[i]["cost"]))+"/-";
          if(offerPer!==0){
              youSaved+="("+listData[i]["offer_per"]+"% off)</span>";
          }else{
              youSaved+="</span>";
          }
      }
    
      if(offerPer!==0){
         offerMsg="<span class=\"new\">"+offerPer+"% off</span>";
      }
      strResp+="<div class=\"col-lg-4 col-6\" data-aos=\"fade-up\" data-aos-delay=\"200\">"+
      "<div class=\"product mb-5\">"+
      "<form>"+
    "<input type=\"hidden\" name=\"item_name\" value=\""+listData[i]["product_name"]+"\" />"+
    "<input type=\"hidden\" name=\"item_code\" value=\""+listData[i]["product_code"]+"\"/>"+
    "<input type=\"hidden\" name=\"item_rate\" value=\""+listData[i]["cost"]+"\" />"+
    "<input type=\"hidden\" name=\"pre_cost\" value=\""+listData[i]["pre_cost"]+"\" />"+
    "<input type=\"hidden\" name=\"offer_per\" value=\""+listData[i]["offer_per"]+"\" />"+
    "<input type=\"hidden\" name=\"comp_name\" value=\""+"0"+"\" />"+
    "<input type=\"hidden\" name=\"item_qty\" value=\""+"1"+"\" />"+

    "<input type=\"hidden\" name=\"imgs\" value=\""+imgPath[0]+"\" />"+
    "<input type=\"hidden\" name=\"item_rf_id\" value=\""+listData[i]["id"]+"\" />"+
          "<div class=\"thumb\">"+
              "<a href=\"../\product\\"+listData[i]["product_name"].replace(/\s/g, '_')+"?rf="+listData[i]["rf_id"]+"\" class=\"image\">"+
                  "<img src=\""+appUrtPath+imgPath[0]+"\" alt=\"Product\" />"+
                  "<img class=\"hover-image\" src=\""+appUrtPath+imgPath[0]+"\" alt=\"Product\" />"+
              "</a>"+
              "<span class=\"badges\">"+
              offerMsg+
              "</span>"+
              "<div class=\"actions\">"+
                //   "<a href=\"wishlist.html\" class=\"action wishlist\" title=\"Wishlist\"><i class=\"icon-heart\"></i></a>"+
                //   "<a href=\"#\" class=\"action quickview\" data-link-action=\"quickview\" title=\"Quick view\" data-bs-toggle=\"modal\" data-bs-target=\"#exampleModal\"><i class=\"icon-size-fullscreen\"></i></a>"+
                //   "<a href=\"compare.html\" class=\"action compare\" title=\"Compare\"><i class=\"icon-refresh\"></i></a>"+
              "</div>"+
            //   "<button type=\"button\" title=\"Add To Cart\" class=\"_addToCart add-to-cart\">Add To Cart</button>"+
          "</div>"+
          "<div class=\"content\">"+
              "<h5 class=\"title\"><a href=\"../\product\\"+listData[i]["product_name"].replace(/\s/g, '_')+"?rf="+listData[i]["rf_id"]+"\">"+listData[i]["product_name"]+"</a></h5>"+
              "<span class=\"price\">"+
                  "<span class=\"new\">₹"+listData[i]["cost"]+"</span>"+
                  oldPrice+
              "</span>"+
              "<span class=\"price\">"+youSaved+"</span>"+
              "<button type=\"button\" title=\"Add To Cart\" class=\"_addToCart ext_addto \">Add To Cart</button>"+
          "</div>"+
          
      "</form>"+"</div>"+
  "</div>";
  }
  return strHeader+strResp+strFooter;

}
export default Product_Listing;
import $ from 'jquery';
import React from 'react';
import select2 from 'select2';
import UserToken from '../function_lib/userToken';
import QueryString from 'query-string';
import {new_enc_dec_m,CallMapMethod,BtnSetColor,webUrlPath,AjaxCallMethod,CallMapMethod_CBack,RespBkMthdMapp,setDocLinks} from '../function_lib/common_lib';
// import listReactFiles from 'list-react-files'
import Header from './header';
import Tax_Header from '../user_components/tax_header';
import Side_Bar from './side_bar';
import Footer from './footer';
import LogoImg from '../assets/images/my_logo.jpg';
import defaultPic from '../assets/images/9.png';

import Document_Upload_Withlist_Component from '../user_components/document_upload_withlist_component';
import Upload_Doc_Opt_Comp from '../user_components/upload_doc_opt_comp';
import View_Document_Comp from '../user_components/view_document_comp';

import pdfIcon from '../assets/images/pdf_icon.png';


// import Tax_Submit_Component from '../user_components/tax_submit_component';

let loginUser="";
let userObject=[];
let clsQuestionnair;

let webUrl;
let rf="0";
let queries;

/* internal param */
let yearRf="0";
let yearTitle="";


let objImages = [];
let indexExisting=0;
let arrExistingFiles=[];

class Upload_Document_List extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        if(localStorage.getItem("userDS")!==null){
          //UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= JSON.parse(localStorage.getItem("userDS"));
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          //window.location.href="./Dashboard";
        }
        if(props !=null){
            queries = QueryString.parse(this.props.location.search);
            //alert(JSON.stringify(queries));
                  if(typeof queries.doc_rf!=="undefined"){
                   
                    rf=queries.doc_rf;
                   
                  }
                  if(typeof queries.rf!=="undefined"){
                   
                    yearRf=queries.rf;
                  }
                  if(typeof queries.dis!=="undefined"){
                    yearTitle=new_enc_dec_m("decrypt",queries.dis);
                  }
          }
          //alert(yearTitle);
          this.state={ listLoanRequest:[],viewLoadDoc:[],stType:"documents",listFile:[],
        stYearRf:yearRf,stYearTitle:yearTitle,stview_type:""};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          webUrl=webUrlPath();
          clsQuestionnair=this;
      }

  
      ImageThumbBack(flImg, flName,fObject,othInfo="") {
        
        if(othInfo===""){
          othInfo="nm"+indexExisting+","+indexExisting;
        }
        indexExisting++;
        var arrNmValue=othInfo.split(',');
        //arrExistingFiles.push
        arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
        var strSet = "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
        "<i class=\"icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
          "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
          "</div>";
          if(flImg.indexOf("data:application/pdf")!=-1 || flImg.indexOf("data:application/pdf")!=-1){
           strSet= "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
            "<i class=\"icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
              "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\""+pdfIcon+"\" /></a>" +
              "</div>";
          }
          
        //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
        objImages.push({ name: arrNmValue[1], value: flImg });
        $("#lsUpdFiles").val("{\"objs\":" + JSON.stringify(objImages) + "}");
        //alert(strSet);            
        $(".imgLibThumb").append(strSet);
        if(fObject!==null){
          fObject.val('');
        }
      //   $('.gallery-item').magnificPopup({
      //     type: 'image',
      //     gallery: {
      //         enabled: true
      //     }
      // });
      
      RespBkMthdMapp(clsQuestionnair, "_removeBtn"+indexExisting, "rmFileProc", "2");
        //imgLibThumb
            }
            rmFileProc(data){
             
              if(document.getElementById("ref_div_"+data)!=null){
                document.getElementById("ref_div_"+data).remove();
              }
              
              this.lcRemoveJSON(data,"image");
          }
          lcRemoveJSON(refKey,fType){
            var jsonP=JSON.parse($("#lsUpdFiles").val());
            var arrJson=jsonP.objs;
            for(var ai=0;ai<arrJson.length;ai++){
              if(arrJson[ai]["name"]===refKey){
                arrJson.splice(ai,1);
                $("#lsUpdFiles").val(JSON.stringify(jsonP));
                break;
              }
            }
            objImages=arrJson;
          }
          
          
  getTaxRequest(status="0"){
    //child component use this method loan_document_upload_comp
    var map=[];
  map.push({name:"curl_type",value:"getTaxDocOpen"});
  map.push({name:"res_func",value:"callBackloanR"});
  map.push({name:"local_token",value:userObject[0].id});
  if(rf!=="0"){
    //alert("i m in loan request");
    map.push({name:"loan_request_id",value:rf});
  }
  
  map.push({name:"parent_id",value:"0"});
  map.push({name:"type_id",value:"4"});
  map["res_func"]="callBackloanR";
  map["curl_type"]="getTaxDocOpen";
  
    CallMapMethod_CBack(clsQuestionnair,map,function(data,msg){
      if(data!=="0" && data!==""){
        let listData=JSON.parse(data);
        var strData="";
        //alert("check mas="+JSON.stringify(listData))
        clsQuestionnair.setState({listLoanRequest:listData},()=>{
            clsQuestionnair.viewLoanDoc(listData[0]);
            $(".select2").select2();        
        });
        //clsQuestionnair.forceUpdateHandler();
      }
    });
    
  }
  viewLoanDoc(itemRow){
    // alert(JSON.stringify(itemRow));
    
  let strItemRow="["+JSON.stringify(itemRow)+"]";
  //$("#tssRept").text(strItemRow);
  let jsItemRow=JSON.parse(strItemRow);
  //let jsItemRow=itemRow

  //alert(JSON.stringify(jsItemRow));
  this.setState({viewLoadDoc:jsItemRow},()=>{
    //clsQuestionnair.showMe("#exampleModal");
    let lsSelect=document.getElementsByClassName("select2");
    if(lsSelect.length>0){
     // $(".select2").select2();
    }
    
  });
  this.forceUpdateHandler();
  }

      
  okSucc(data,smg){
    if(data==="001"){
      alert("record added successfully");
      clsQuestionnair.getTaxRequest();
      $("#frmCreate").find("input[type=text]").val("");
    }
    // alert(data);
  }
      
      componentDidMount(){
         this.getTaxRequest();
         if(queries.doc_rf!=null){
          setDocLinks(queries.doc_rf);
         }
          const script = document.createElement("script");
          script.src = "/main.js";
          script.async = true;
        
          document.body.appendChild(script);
          /*
          const script2 = document.createElement("script");
          script2.src = "https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js";
          script2.async = false;
        
          document.body.appendChild(script2);
*/
          AjaxCallMethod(this,"okSuccCNOT");

          
          

          $(".multiset").change(function (e) {
            var setVal = "#" + $(this).attr("setTo");
            $(setVal).val($(this).val());
          });

      }

      viewFile(data,view_type=""){
        //alert("i m in "+JSON.stringify(data));
        clsQuestionnair.setState({listFile:data,stview_type:view_type},()=>{
          clsQuestionnair.forceUpdateHandler();
        })

          }  
    

    render(){
   
        return(<div className="bg-gray">
          <div id="tssRept"></div>
            <Header doc_rf={rf}/>
            
             {/* dashboard tax options  */}
            {/* <Tax_Header/> */}
            <View_Document_Comp  view_doc={this.state.listFile} view_type={this.state.stview_type}/>
            <Upload_Doc_Opt_Comp viewLoadDoc={this.state.viewLoadDoc} userObject={userObject} view_doc={this.state.listFile}/>
            <div className="container">
            <div className="row">
                <div className="col-md-8 pb-20px pt-20px">
            <div class="card card-primary card-outline cover-height">
                <div className="card-header">
                <h5 class="card-title">Documents Upload ({this.state.listLoanRequest.length>0?this.state.listLoanRequest[0]["year_display"]:""})</h5>
                {rf!=="0" && rf!==0?<p>{this.state.listLoanRequest.length>0?this.state.listLoanRequest[0]["first_name"]:''}</p>:''}
                </div>
              <div class="card-body">
              <Document_Upload_Withlist_Component  fnViewFile={this.viewFile} getType={this.state.stType} getLoanRequest={this.getLoanRequest} viewLoadDoc={this.state.viewLoadDoc}/>   
              {/* <Tax_Submit_Component getType={this.state.stType} getLoanRequest={this.getLoanRequest} viewLoadDoc={this.state.viewLoadDoc}/>    */}
              
              </div>
            </div>
            </div>
            {/* side card */}
              <div className="col-md-4 pb-20px pt-20px">
              <div class="card" style={{width: "100%;"}}>
  
  <div class="card-body">
     <h5 class="card-title">Tax Questionnair</h5>
    <p class="card-text">Year wise status/records</p>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">An item</li>
    <li class="list-group-item">A second item</li>
    <li class="list-group-item">A third item</li>
  </ul>
</div>

              </div>

             

            </div>
           
  </div>
     
        <Footer/>
      </div>)
    }
}
export default Upload_Document_List
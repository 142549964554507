import $ from 'jquery';
import React from 'react';
class Account_Opt_Link extends React.Component{
    render(){
        return(<div className="col-lg-3 order-lg-first col-md-12 order-md-last mb-md-60px mb-lm-60px">
        <div className="shop-sidebar-wrap">
           
            <div className="sidebar-widget">
                <div className="main-heading">
                    <h3 className="sidebar-title">My Account</h3>
                </div>
                <div className="sidebar-widget-category">
                    <ul>
                        {/* <li><a href="./my_profile" className="selected">My Profile</a></li> */}
                        <li><a href="./my_orders" className="">My Orders </a></li>
                        <li><a href="./my_invoices" className="">My Invoice</a></li>
                        <li><a href="./my_orders" className="">Order's Tracking</a></li>
                        {/* <li><a href="#" className="">Customer Care</a></li> */}
                    </ul>
                </div>
            </div>
            </div>
            </div>)
    }
}
export default Account_Opt_Link;
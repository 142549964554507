import $ from 'jquery';
import React from 'react';
import {appGetUrlPath,appGetMainUrl} from '../function_lib/common_cms';
import DefUser from '../assets/images/icon-user-default.png';
import './comp_blog_land.css';
let appPathCMS="";
let clsComoBlogLand="";
let ifIframe="";
let ifIframInit=false;
let appPathURL="";
class Comp_Case_Landing extends React.Component{
    
   
    constructor(props){
        super(props);
        appPathCMS=appGetUrlPath();
        appPathURL=appGetMainUrl();
        
    }
    
httpGet(theUrl)
{
    ifIframInit=true;
    //alert(theUrl);
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", appPathURL+"getContentPgData"+"?pg_name="+theUrl, true ); // false for synchronous request
    xmlHttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
            //alert("i m done xml");
            //alert(xmlHttp.responseText);
            ifIframe=xmlHttp.responseText;
            //alert(ifIframe);
           // clsComoBlogLand.onLoadIframe(ifIframe);
           // Typical action to be performed when the document is ready:
           //document.getElementById("blog_landing").innerHTML = xmlHttp.responseText;
        }
    };
    xmlHttp.send();
    // alert(xmlHttp.responseText);
    // return xmlHttp.responseText;
}
    componentWillReceiveProps(props){
        this.props=props;
        if(this.props.blogData && this.props.blogData["col5"].indexOf(".html")!==-1){
           //alert(ifIframe);
            if(!ifIframInit){
                
                //alert(this.props.blogData["col5"]);
                ifIframe=this.httpGet(this.props.blogData["col5"]);
            }
            if(ifIframe){
               
            }
           
            
           
        }
        //alert("child props"+JSON.stringify(this.props.blogData));
        //alert(this.props.blogData["col4"]);
        //this.forceUpdateHandler();
        // Adjusting the iframe height onload event
        let _topTitle=this.props.blogData["col1"];
        let _topKeywords=this.props.blogData["col9"];
        let _topDescription=this.props.blogData["col9"];
        document.title=_topTitle;
        //alert(this.props.blogData["col018"]);
     document.querySelector('meta[name="title"]').setAttribute("content", _topTitle);
     document.querySelector('meta[name="description"]').setAttribute("content", _topDescription);
     document.querySelector('meta[name="keywords"]').setAttribute("content", _topKeywords);
        //$("#blog_landing").html(this.props.blogData["col2"]);
        //$("#sub_cont1").html(this.props.blogData["col017"]);
        //$("#sub_cont2").html(this.props.blogData["col016"]);
        //$("#blog_landing2").html(this.props.blogData["col3"]);

    }
    componentDidMount(){
        clsComoBlogLand=this;

        // $('#myIframe').bind('DOMSubtreeModified', function () {
            
        //     var links = document.getElementById("blog_landing").getElementsByTagName("a");
        //     var len = links.length;
        //     alert("i am in change"+$("#blog_landing").html());
        //   });
       
    }
    componentDidUpdate(){
       /*
        //document.getElementById("blog_landing").innerHTML='<object type="text/html" data="'+appPathCMS+this.props.blogData["col5"]+'" ></object>';
        var links = document.getElementById("blog_landing").getElementsByTagName("a");
        var len = links.length;
        //alert(len);
        for(var i=0; i<len; i++)
        {
           links[i].target = "_blank";
           //alert("check");
        }
        if(ifIframe!==""){
            document.getElementById("blog_landing").innerHTML=ifIframe;
            this.onLoadIframe();
        }
        $("#leading_blog").find('*').removeAttr('style');
*/
       
      
       
    }
    onLoadIframe(srcs){
     // alert("i am in click");
     // document.getElementById("blog_landing").innerHTML=srcs;
     // return;
        //document.getElementById("myIframe").src="#";
        var links = document.getElementById("blog_landing").getElementsByTagName("a");
        
        var len = links.length;
        //alert("loaded"+len);
        for(var i=0; i<len; i++)
        {
           links[i].target = "_blank";
           //alert("check");
        }
    }
    render(){
        return(<div className="blog-posts pt-70px">
       
        <div id="leading_blog" className="single-blog-post  blog-grid-post">
       
            <div className="blog-post-media">
                <div className="blog-image single-blog">
                    <img src={appPathCMS+this.props.blogData["col4"]} alt="blog" />
                </div>
                <center>
            <h1 className="blog-title"><a href="javascript:void(0)">{this.props.blogData["col1"]}</a></h1>
            <div class="review-wrapper">
                            
                        </div>
            {/* <span style={{float:"left"}} className="title12 bg_blog_category">{this.props.blogData["col19"]}</span> */}
            </center>
            </div>
            <div className="blog-post-content-inner mt-6">
                <div className="blog-athor-date">
                    {/* <a className="blog-date" href="#">14 November</a> */}
                </div>
                
               
          
                <div className='row pb-10 pt-10'>
                <div id="sub_cont1" className='col-md-6 center-middle'>
                <div dangerouslySetInnerHTML={{ __html: this.props.blogData["col017"] }}/>
                
                </div>
                <div id="sub_cont2" className='col-md-6'>
                     
                     <img className="w-100 img-responsive" src={appPathCMS+this.props.blogData["col5"]} alt="blog" />
                </div>
            </div>
            <div className='row pb-10 pt-10'>
            <div id="sub_cont1" className='col-md-6'>
                <img className="w-100 img-responsive" src={appPathCMS+this.props.blogData["col6"]} alt="blog" />
                </div>
            <div id="sub_cont2" className='col-md-6 center-middle'>
                     
                     <div dangerouslySetInnerHTML={{ __html: this.props.blogData["col016"] }}/>
                </div>
               
                
            </div>
            <div className='row pb-10 pt-10 case-divs'>
                <div className="col-md-12 bg-case-strip"></div>
                <div className="col-md-12 bg-case-strip-left"></div>
            <div id="sub_cont22" className='col-md-6 center-middle-white'>
                   
                     <div dangerouslySetInnerHTML={{ __html: this.props.blogData["col3"] }}/>
                </div>

                <div id="sub_cont12" className='col-md-6 img-div'>
                <img className="w-100 img-responsive" src={appPathCMS+this.props.blogData["col018"]} alt="blog" />
                </div>
            
            </div>
            
            </div>
            
        </div>
        
    </div>)
    }
}
export default Comp_Case_Landing;
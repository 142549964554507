import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import Image_Grid from '../component_version2/image_grid';
import './left_img_txt_desc.css';
//import lefImg1 from '../assets/images/about-image/1.jpg';

export function Text_Image_Full(lsData,isWhite=false,injIndex=-1){
    var appPath= appGetUrlPath();
    var strResp="";
    let strBtn="";

    let clsWhite="";
    if(isWhite){
        clsWhite="text-white";
    }
        for(var i=0;i<lsData.length;i++){
            let isBackGroundImg="";
            strBtn="";
            if(lsData[i]["col7"].trim()!==""){
                strBtn="<a type=\"button\" class=\"btn text-center btn-primary btn-hover-primary ml-4\" href=\""+lsData[i]["col7"]+"\">"+lsData[i]["col8"]+"</a>";
            }
            if(lsData[i]["col5"].trim()!==""){
                isBackGroundImg="style=\"min-height:400px;background-size:cover;background-image:url("+appPath+lsData[i]["col5"].trim()+")\"";
            }

          let  imgPath=lsData[i]["col4"];
        let strImgPaths="";
        if(lsData[i]["col4"].indexOf(".mp4")!==-1){
          strImgPaths="<center><video id=\"vid_home\" controls><source src=\""+appPath+imgPath+"\" type=\"video/mp4\"></video></center>";
        }else{
          strImgPaths="<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\" img-responsive w-100\" />";
        }
        let bgAssign=lsData[i]["col12"];
        
        let divId="";
        //alert(injIndex);
        if(injIndex!==-1){
            divId="id=\"divind"+injIndex+"\"";
         }  
         
            
                strResp+="<section  "+divId+" "+isBackGroundImg+" class=\"left_img_txt_desc "+bgAssign+" border-box about-area pb-70px pt-70px\">"+
            "<div class=\"container\">"+
                "<div class=\"row align-items-center\">"+
                   
                    "<div data-aos=\"fade-up\" data-aos-duration=\"2000\" class=\"col-lg-12\">"+
                        "<div class=\"about-content\">"+
                            "<div class=\"about-title\">"+
                                "<h3>"+lsData[i]["col1"]+"</h3>"+
                            "</div>"+
                            "<p  data-aos=\"fade-up\" class=\"mb-6\">"+
                               lsData[i]["col2"]+
                            "</p>"+
                            "<p class=\"mb-6\">"+
                            lsData[i]["col3"]+
                         "</p>"+
                            "<p>"+
                                strBtn+
                            "</p>"+
                        "</div>"+
                   "</div>"+
                   "<div class=\"col-lg-12\">"+
                   "<div class=\"about-left-image mb-md-30px mb-lm-30px\">"+
                   strImgPaths+
                   "</div>"+
               "</div>"+
                "</div>"+
            "</div>"+
            "</section>"
            
            
        }
        return strResp;
    

}

export function Left_Txt_Txt_Desc(lsData){
    var appPath= appGetUrlPath();
    var strResp="";
    let strBtn="";
   
        for(var i=0;i<lsData.length;i++){
            let isBackGroundImg="";
            strBtn="";
            if(lsData[i]["col7"].trim()!==""){
                strBtn="<a type=\"button\" class=\"btn text-center btn-primary btn-hover-primary ml-4\" href=\""+lsData[i]["col7"]+"\">"+lsData[i]["col8"]+"</a>";
            }
            if(lsData[i]["col5"].trim()!==""){
                isBackGroundImg="style=\"min-height:400px;background-size:cover;background-image:url("+appPath+lsData[i]["col5"].trim()+")\"";
            }
            let bgAssign=lsData[i]["col12"];
    //alert(lsData[i]["col7"].trim());
            if(i%2==0){
                strResp+="<section "+isBackGroundImg+" class=\"left_img_txt_desc "+bgAssign+" border-box1 about-area pb-70px pt-70px\">"+
                "<div class=\"container\">"+
                    "<div class=\"row align-items-center\">"+
                        "<div class=\"col-lg-6\">"+
                        "<div class=\"about-content\">"+
                       
                        "<p class=\"mb-6\">"+
                           lsData[i]["col2"]+
                        "</p>"+
                       
                    "</div>"+
                        "</div>"+
                        "<div class=\"col-lg-6\">"+
                            "<div class=\"about-content\">"+
                               
                                "<p class=\"mb-6\">"+
                                   lsData[i]["col3"]+
                                "</p>"+
                               
                            "</div>"+
                       "</div>"+
                    "</div>"+
                "</div>"+
                "</section>"
            }else{
                strResp+="<section class=\"left_img_txt_desc "+bgAssign+" border-box about-area pb-70px pt-70px\">"+
            "<div class=\"container\">"+
                "<div class=\"row align-items-center\">"+
                   
                    "<div class=\"col-lg-6\">"+
                        "<div class=\"about-content\">"+
                            "<div class=\"about-title\">"+
                                "<h3>"+lsData[i]["col1"]+"</h3>"+
                            "</div>"+
                            "<p class=\"mb-6\">"+
                               lsData[i]["col2"]+
                            "</p>"+
                            "<p>"+
                                strBtn+
                            "</p>"+
                        "</div>"+
                   "</div>"+
                   "<div class=\"col-lg-6\">"+
                   "<div class=\"about-left-image mb-md-30px mb-lm-30px\">"+
                       "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\" img-responsive w-100\" />"+
                   "</div>"+
               "</div>"+
                "</div>"+
            "</div>"+
            "</section>"
            }
            
        }
        return strResp;
}

export function Tab_Data_LIT(lsData,tabList){
    var appPath= appGetUrlPath();
    let w_100="";
    let strBtn="";
    let clsWhite="";

   //alert(JSON.stringify(tabList));

   let strHeader="";
    for(let h=0;h<lsData.length;h++){
        if(h===0){
            let bgAssign=lsData[h]["col12"];
            let isBackGroundImg="";
     strHeader="<section "+isBackGroundImg+" class=\"left_img_txt_desc "+bgAssign+" border-box1 about-area pb-70px pt-70px\">";
    strHeader+="<div class=\"container\">";
    strHeader+="<div class=\"sidebar-widget-tag d-inline-block pb-100px\">";
    strHeader+="<ul class=\"nav nav-tabs11\" id=\"custom-tabs-three-tab\" role=\"tablist\">";

            strHeader+="<li class=\"nav-item\">"+
            "<a class=\"nav-link active\" id=\"ct"+h+"\" data-toggle=\"pill\" href=\"#dt"+h+"\" role=\"tab\" aria-controls=\"custom-tabs-three-home\" aria-selected=\"true\">"+lsData[h]["col1"]+"</a>"+
"</li>";
        }else{
            strHeader+="<li class=\"nav-item\">"+
            "<a class=\"nav-link\" id=\"ct"+h+"\" data-toggle=\"pill\" href=\"#dt"+h+"\" role=\"tab\" aria-controls=\"custom-tabs-three-home\" aria-selected=\"true\">"+lsData[h]["col1"]+"</a>"+
"</li>";
        }
       
    }
    strHeader+="</ul></div>";

    let strFooter="</div></section>"

    let strResp="<div class=\"tab-content\" id=\"custom-tabs-three-tabContent\">";
    
    let othTabIndex=lsData[0]["col9"].split(',');
    

    for(let i=0;i<lsData.length;i++){
        let isActivePan="";
        if(i===0){
            isActivePan="active show"
        }

        let otheComponent="";
        if(typeof tabList.access[othTabIndex[i]]!=="undefined"){
            //alert(othTabIndex[i]);
            otheComponent= Image_Grid(tabList.access[othTabIndex[i]].image_grid);
            delete tabList.access[othTabIndex[i]];
        }

        strResp+="<div id=\"dt"+i+"\" class=\"row tab-pane "+isActivePan+" fade align-items-center\">"+
        "<div class=\"row\">"+
       
        "<div class=\"col-lg-6\">"+
            "<div class=\""+clsWhite+" about-content\">"+
                "<div class=\"about-title\">"+
                    "<h3 class=\""+clsWhite+"\">"+lsData[i]["col1"]+"</h3>"+
                "</div>"+
                "<p class=\""+clsWhite+" mb-6\">"+
                   lsData[i]["col2"]+
                "</p>"+
                "<p class=\""+clsWhite+"\">"+
                    strBtn+
                "</p>"+
            "</div>"+
       "</div>"+
       "<div class=\"col-lg-6\">"+
       "<div class=\"about-left-image mb-md-30px mb-lm-30px\" >"+
           "<img style=\"margin-left:auto\" src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\"img-responsive "+w_100+"\" />"+
       "</div>"+
   "</div>"+
    "</div>"+
    //logic to add other tab component
    otheComponent+
    
    "</div>";

    
    }
    strResp+="</div>";
return strHeader+strResp+strFooter;
}

export function Right_Img_Two_Div_Text(lsData,isWhite=false,w_100="w-100"){
    var appPath= appGetUrlPath();
    var strResp="";
    let strBtn="";

    let clsWhite="";
    if(isWhite){
        clsWhite="text-white";
    }
    for(var i=0;i<lsData.length;i++){
        let isBackGroundImg="";
        strBtn="";
        if(lsData[i]["col7"].trim()!==""){
            strBtn="<a type=\"button\" class=\"btn text-center btn-primary btn-hover-primary ml-4\" href=\""+lsData[i]["col7"]+"\">"+lsData[i]["col8"]+"</a>";
        }
        if(lsData[i]["col5"].trim()!==""){
            isBackGroundImg="style=\"min-height:400px;background-size:cover;background-image:url("+appPath+lsData[i]["col5"].trim()+")\"";
        }
        let bgAssign=lsData[i]["col12"];
//alert(lsData[i]["col7"].trim());
      let divId="";
      if(i%2==0){
        strResp+="<section "+divId+" "+isBackGroundImg+" class=\"left_img_txt_desc "+bgAssign+" border-box1 about-area pb-50px pt-50px\">"+
        "<div class=\"container\">"+
            "<div class=\"row align-items-center\">"+
               
                "<div class=\"col-lg-7\">"+
                "<div class=\"left_overlay\">"+
                "<div class=\"about-title\">"+
                "<h4 class=\""+clsWhite+"\">"+lsData[i]["col1"]+"</h4>"+
            "</div>"+
                    "<div class=\""+clsWhite+" row\">"+
                       "<div class=\"col\">"+lsData[i]["col2"]+"</div>"+
                       "<div class=\"col\">"+lsData[i]["col3"]+"</div>"+
                    "</div>"+
               "</div>"+
              "</div>"+
               "<div class=\"col-lg-5\">"+
               "<div class=\"about-left-image mb-md-30px mb-lm-30px\" >"+
                   "<img style=\"margin-left:auto\" src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\"img-responsive "+w_100+"\" />"+
               "</div>"+
           "</div>"+   

            "</div>"+
        "</div>"+
        "</section>"
      }else{
        strResp+="<section "+divId+" "+isBackGroundImg+" class=\"left_img_txt_desc "+bgAssign+" border-box1 about-area pb-50px pt-50px\">"+
        "<div class=\"container\">"+
            "<div class=\"row align-items-center\">"+
            "<div class=\"col-lg-5\">"+
            "<div class=\"about-left-image mb-md-30px mb-lm-30px\" >"+
                "<img style=\"margin-left:auto\" src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\"img-responsive "+w_100+"\" />"+
            "</div>"+
        "</div>"+ 
                "<div class=\"col-lg-7\">"+
                "<div class=\"right_overlay\">"+
                "<div class=\"about-title\">"+
                "<h4 class=\""+clsWhite+"\">"+lsData[i]["col1"]+"</h4>"+
            "</div>"+
                    "<div class=\""+clsWhite+" row\">"+
                       "<div class=\"col\">"+lsData[i]["col2"]+"</div>"+
                       "<div class=\"col\">"+lsData[i]["col3"]+"</div>"+
                    "</div>"+
               "</div>"+
              "</div>"+
                

            "</div>"+
        "</div>"+
        "</section>"
      }
       
    }
    return strResp;
}

export function Left_Img_Txt_Desc(lsData,isRight=false,isWhite=false,w_100="w-100",injIndex=-1,tabList){
    
    w_100="";
    // remove above if img ratio not workin
    
    var appPath= appGetUrlPath();
    var strResp="";
    let strBtn="";

    let clsWhite="";
    if(isWhite){
        clsWhite="text-white";
    }

  

    let strHeader="";
    if(lsData.length>0){
        if(lsData[0]["col10"]==="tab"){
        return Tab_Data_LIT(lsData,tabList);
        }
    }

   



    if(!isRight){
        for(var i=0;i<lsData.length;i++){
            let isBackGroundImg="";
            strBtn="";
            if(lsData[i]["col7"].trim()!==""){
                strBtn="<a type=\"button\" class=\"btn text-center btn-primary btn-hover-primary ml-4\" href=\""+lsData[i]["col7"]+"\">"+lsData[i]["col8"]+"</a>";
            }
            if(lsData[i]["col5"].trim()!==""){
                isBackGroundImg="style=\"min-height:400px;background-size:cover;background-image:url("+appPath+lsData[i]["col5"].trim()+")\"";
            }
            let bgAssign=lsData[i]["col12"];
    //alert(lsData[i]["col7"].trim());
          let divId="";
          if(injIndex!==-1){
            divId="id=\"divind"+injIndex+"_"+i+"\"";
         } 
            if(i%2==0){
                strResp+="<section "+divId+" "+isBackGroundImg+" class=\"left_img_txt_desc "+bgAssign+" border-box1 about-area pb-70px pt-70px\">"+
                "<div class=\"container\">"+
                    "<div class=\"row align-items-center\">"+
                        "<div class=\"col-lg-6\">"+
                            "<div class=\"about-left-image mb-md-30px mb-lm-30px\" >"+
                                "<img style=\"margin-left:auto\" src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\"img-responsive "+w_100+"\" />"+
                            "</div>"+
                        "</div>"+
                        "<div class=\"col-lg-6\">"+
                            "<div class=\""+clsWhite+" about-content\">"+
                                "<div class=\"about-title\">"+
                                    "<h3 class=\""+clsWhite+"\">"+lsData[i]["col1"]+"</h3>"+
                                "</div>"+
                                "<p class=\""+clsWhite+" mb-6\">"+
                                   lsData[i]["col2"]+
                                "</p>"+
                                "<p class=\""+clsWhite+"\">"+
                                    strBtn+
                                "</p>"+
                            "</div>"+
                       "</div>"+
                    "</div>"+
                "</div>"+
                "</section>"
            }else{
                strResp+="<section "+divId+" class=\"left_img_txt_desc "+bgAssign+" border-box about-area pb-70px pt-70px\">"+
            "<div class=\"container\">"+
                "<div class=\"row align-items-center\">"+
                   
                    "<div class=\"col-lg-6\">"+
                        "<div class=\"about-content\">"+
                            "<div class=\"about-title\">"+
                                "<h3>"+lsData[i]["col1"]+"</h3>"+
                            "</div>"+
                            "<p class=\"mb-6\">"+
                               lsData[i]["col2"]+
                            "</p>"+
                            "<p>"+
                                strBtn+
                            "</p>"+
                        "</div>"+
                   "</div>"+
                   "<div class=\"col-lg-6\">"+
                   "<div class=\"about-left-image mb-md-30px mb-lm-30px\">"+
                       "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\" img-responsive "+w_100+"\" />"+
                   "</div>"+
               "</div>"+
                "</div>"+
            "</div>"+
            "</section>"
            }
            
        }
        return strHeader+strResp;
    }else{
        for(var i=0;i<lsData.length;i++){
            let isBackGroundImg="";
            strBtn="";
            if(lsData[i]["col5"].trim()!==""){
                isBackGroundImg="style=\"background-repeat:no-repeat;background-size:cover;background-image:url("+appPath+lsData[i]["col5"].trim()+")\"";
            }
            if(lsData[i]["col7"].trim()!==""){
                strBtn="<a type=\"button\" class=\"btn text-center btn-primary btn-hover-primary ml-4\" href=\""+lsData[i]["col7"]+"\">"+lsData[i]["col8"]+"</a>";
            }
            let bgAssign=lsData[i]["col12"];
    //alert(lsData[i]["col7"].trim());
    let divId="";
          if(injIndex!==-1){
            divId="id=\"divind"+injIndex+"_"+i+"\"";
         } 
            if(i%2==0){
                //alert(isBackGroundImg);
                strResp+="<section "+divId+" "+isBackGroundImg+" class=\"left_img_txt_desc "+bgAssign+" border-box1 about-area pb-70px pt-70px\">"+
                "<div class=\"container\">"+
                    "<div class=\"row align-items-center\">"+
                    "<div class=\"col-lg-6\">"+
                    "<div class=\"about-content\">"+
                        "<div class=\"about-title\">"+
                            "<h3>"+lsData[i]["col1"]+"</h3>"+
                        "</div>"+
                        "<p class=\"mb-6 "+clsWhite+"\">"+
                           lsData[i]["col2"]+
                        "</p>"+
                        "<p>"+
                            strBtn+
                        "</p>"+
                    "</div>"+
               "</div>"+
                        "<div class=\"col-lg-6\">"+
                            "<div class=\"about-left-image mb-md-30px mb-lm-30px\" >"+
                                "<img  src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\" img-responsive w-100\" />"+
                            "</div>"+
                        "</div>"+
                       
                    "</div>"+
                "</div>"+
                "</section>"
            }else{
                strResp+="<section "+divId+" class=\"left_img_txt_desc "+bgAssign+" border-box about-area pb-70px pt-70px\">"+
            "<div class=\"container\">"+
                "<div class=\"row align-items-center\">"+
                   
                    "<div class=\"col-lg-6\">"+
                        "<div class=\"about-content\">"+
                            "<div class=\"about-title\">"+
                                "<h3>"+lsData[i]["col1"]+"</h3>"+
                            "</div>"+
                            "<p class=\"mb-6\">"+
                               lsData[i]["col2"]+
                            "</p>"+
                            "<p>"+
                                strBtn+
                            "</p>"+
                        "</div>"+
                   "</div>"+
                   "<div class=\"col-lg-6\">"+
                   "<div class=\"about-left-image mb-md-30px mb-lm-30px\">"+
                       "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\" img-responsive w-100\" />"+
                   "</div>"+
               "</div>"+
                "</div>"+
            "</div>"+
            "</section>"
            }
        }
        return strHeader+strResp;
    }
}



export function Left_Img_Txt_Slider(lsData,isRight=false,d_row="4",w_100="w-100"){
       //alert(lsData);
  // alert(JSON.stringify(lsData));
/*Header Values */
var title="";
if(lsData.length!=0){
title=lsData[0]["g_title"];
}
var appPath=appGetUrlPath();
//var listData=JSON.parse(lsData);
var strResp="";


var strHeader="<div  class=\"orange-bg main-blog-area pt-70px pb-100px\">"+
"<div class=\"container\">"+
    "<div class=\"row\">"+
        "<div class=\"col-md-12\">"+
            "<div class=\"heading page__title-bar_testi\">"+
                //"<h3 class=\"title-black\">"+title+"</h3>"+
            "</div>"+
        "</div>"+
    "</div>"+
    "<div class=\"new-slider_one swiper-container slider-nav-style-1\">"+
    "<div class=\"new-product-wrapper1 pt-100px swiper-wrapper\">";

var strFooter="</div>"+
"<div class=\"swiper-buttons\">"+
"<div class=\"swiper-button-next\"></div>"+
"<div class=\"swiper-button-prev\"></div>"+
"</div>"+
"</div></div></div>";
for(var i=0;i<lsData.length;i++){
    var urlTitle= lsData[i]["col1"];
    urlTitle=urlTitle.replace("?","_");
    urlTitle=urlTitle.replace(" ","-");
    var urlCol7="#";
    if(lsData[i]["col7"]!==""){
        urlCol7=lsData[i]["col7"];
    }
    let urlTxtCol8="Check Product";
    if(lsData[i]["col8"]){
        urlTxtCol8=lsData[i]["col8"];
    }
    var imgPath="default-product.png";
    if(lsData[i]["col4"]!=""){
        imgPath=lsData[i]["col4"];
         }
    let titleC;
    let positionC;
    let arrTitle=lsData[i]["col1"].split(',');
    titleC=arrTitle[0];
    if(arrTitle.length>1){
        positionC=arrTitle[1];
    }


    let strBtn="";
        if(lsData[i]["col7"].trim()!==""){
            let arrStrBtn=lsData[i]["col8"].split(',');
            let arrBtnUrl=lsData[i]["col7"].split(',');
            for(let b=0;b<arrStrBtn.length;b++){
                strBtn+= "<a href=\""+arrBtnUrl[b]+"\" class=\"btn-colored btn-hover-dark btn-2 pdall-10px \">"+arrStrBtn[b]+"</a>";    
            }
            
        }

    /* for left right */
    if(isRight){
        strResp+="<div class=\"card1 mycard bg_transparent single-blog swiper-slide col-md-5\" style=\"text-align:center\">"+
        "<div class=\"card12 mycard12\">"+
          "<div class=\"row no-gutters card-body12\">"+
          
           "<div class=\"col-md-6\" style=\"margin: auto;\">"+
            //"<i class=\"fa fa-quote-left\" aria-hidden=\"true\"></i>"+
            "<a href=\"#\" class=\"title-link\"><h5 class=\"card-title\">"+lsData[i]["col1"]+"</h5></a>"+
            "<p class=\"card-text\">"+lsData[i]["col2"]+"</p>"+
            "<p class=\"font-w\">"+lsData[i]["col3"]+"</p>"+
            strBtn+
          "</div>"+
    
          "<div class=\"col-md-6\">"+
           "<img class=\"card-img-left\" src=\""+appPath+imgPath+"\"  />"+
           "</div>"+
           
          "</div>"+
        "</div>"+
    "</div>";
    }else{
        strResp+="<div class=\"card1 mycard bg_transparent single-blog swiper-slide col-md-5 \"  style=\"text-align:center\">"+
        "<div class=\"card12 mycard12\">"+
          "<div class=\"row no-gutters card-body12\">"+
          
          "<div class=\"col-auto\">"+
          "<img class=\"card-img-left\" src=\""+appPath+imgPath+"\"  />"+
          "</div>"+
           "<div class=\"col\" style=\"margin: auto;\">"+
            //"<i class=\"fa fa-quote-left\" aria-hidden=\"true\"></i>"+
            "<a href=\"#\" class=\"title-link\"><h5 class=\"card-title\">"+lsData[i]["col1"]+"</h5></a>"+
            "<p class=\"card-text\">"+lsData[i]["col2"]+"</p>"+
            "<p class=\"font-w\">"+lsData[i]["col3"]+"</p>"+
          "</div>"+
    
        
           
          "</div>"+
        "</div>"+
    "</div>";
    }
         
    
}
      return strHeader+strResp+strFooter;
    }



export default Left_Img_Txt_Desc;
import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,CallMapMethod_CBack,new_enc_dec_m} from '../function_lib/common_lib';

import Comp_User_Dashboard from '../user_components/comp_user_dashboard';
import Comp_CPA_Dashboard from '../user_components/comp_cpa_dashboard';
import Comp_ADMIN_Dashboard from '../user_components/comp_ADMIN_dashboard';
// import listReactFiles from 'list-react-files'
import Header from './header';
import Tax_Header from '../user_components/tax_header';
import Side_Bar from './side_bar';

import Footer from './footer';
import LogoImg from '../assets/images/my_logo.jpg';
import defaultPic from '../assets/images/9.png';



let loginUser="";
let userObject="";
let logInObj;
let clsDashboardNew;
class DashboardNew extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        if(localStorage.getItem("userDS")!==null){
          //UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= JSON.parse(localStorage.getItem("userDS"));
          logInObj=userObject;
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          //window.location.href="./Dashboard";
        }else{
            window.location.href="../login";
        }
        this.state={listYearMaster:[],taxConsultant:[],
        stCpaTaxTotal:[]};
        clsDashboardNew=this;
      }
     
      getDashboard(){
        //alert("i m in first data");
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getDashboard"});
        map.push({name:"local_token",value:logInObj[0].id});
        map.push({name:"upper_id",value:logInObj[0].app_user_id});
        map.push({name:"user_type",value:logInObj[0].type});
        if(logInObj[0].type==="1" && logInObj[0]["loc"]!=="undefined"){
          //admin dashboard
          map.push({name:"admin",value:logInObj[0].id})//on later stage get admin session

        }else if(logInObj[0].type==="3"){
          map.push({name:"cpa",value:logInObj[0].id});
        }else if(logInObj[0].type==="2"){
          map.push({name:"user",value:logInObj[0].id});
        }
        //alert(status);
        /*
        if(status!=="0"){
          map.push({name:"status",value:status});
        }
        not required as of now
        */
        map["curl_type"]="getDashboard";
        CallMapMethod_CBack(clsDashboardNew,map,function(data,msg){
          
            if(data!=="0" && data!==""){
            
                let listData=JSON.parse(data);
               
                var strData="";
                //alert(listData.year_master);
                if(logInObj[0].type==="1" && logInObj[0]["loc"]!=="undefined"){
                  clsDashboardNew.setState({listYearMaster:listData.year_master,taxConsultant:listData.tax_consultant,
                    stCpaTaxTotal:listData.cpa_tax_total
                    
                  },()=>{
                    clsDashboardNew.forceUpdateHandler();  
                });
                }else if(logInObj[0].type==="3"){
                  clsDashboardNew.setState({listYearMaster:listData.year_master,taxConsultant:listData.tax_consultant,
                    stCpaTaxTotal:listData.cpa_tax_total},()=>{
                    clsDashboardNew.forceUpdateHandler();  
                });
                }else{
                  clsDashboardNew.setState({listYearMaster:listData.year_master,taxConsultant:listData.tax_consultant},()=>{
                    clsDashboardNew.forceUpdateHandler();  
                });
                }
               
               
               
              }
        });
      }
    
      componentDidMount(){
        
        this.getDashboard();
          const script = document.createElement("script");
          script.src = "/main.js";
          script.async = true;
          //script.onload = () => clsObject.scriptLoaded();
        
          document.body.appendChild(script);
         
          //listReactFiles("pub_dir/").then(files => console.log(files))
      }
      
     
      

    render(){
        
        const adminOption=()=>{
          
           if(logInObj[0].type==="2"){
            //alert("i m in user dashboard");
            return  <Comp_User_Dashboard taxConsultant={this.state.taxConsultant} listYearMaster={this.state.listYearMaster} />
           }else if(logInObj[0].type==="3"){
            return  <Comp_CPA_Dashboard 
            taxConsultant={this.state.taxConsultant} 
            listYearMaster={this.state.listYearMaster}
            cpaTaxTotal={this.state.stCpaTaxTotal} />
           }else if(logInObj[0].type==="1"){
            if(typeof logInObj[0]["loc"]!=="undefined"){
              return  <Comp_ADMIN_Dashboard 
              taxConsultant={this.state.taxConsultant} 
              listYearMaster={this.state.listYearMaster} 
              cpaTaxTotal={this.state.stCpaTaxTotal}
              />
            }
           }
        }
        return(<div className="bg-gray">
            <Header/>
             {/* dashboard tax options  */}
             {/* <Tax_Header/> */}

            <div className="container">
            {adminOption()}
           
  </div>
     
        <Footer/>
      </div>)
    }
}
export default DashboardNew
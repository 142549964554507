import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './image_grid.css';
export function Image_Grid(lsData){
    var appPath= appGetUrlPath();
    var strResp="";


   
   
    let strHeader="<div class=\"container pt-70px\">"+
"<div class=\"container-inner\" style=\"max-width:100%\">"+
    "<div class=\"row\">";

let strFooter=  "</div>"+
"</div>"+
"</div>";  

    //alert(lsData.length);
    let topText="";
    for(var i=0;i<lsData.length;i++){
        
        if(lsData[i]["col10"].trim()==="top_text"){
            //alert("i m in condition");
            topText=lsData[i]["col2"]+lsData[i]["col3"];
        }else{
 var stylBgImg="";
 //alert("check img== "+lsData[i]["col4"]);
 if(lsData[i]["col4"]!==""){
    stylBgImg="<img class=\"img-responsive w-100\" src=\""+appPath+lsData[i]["col4"].trim()+"\" style=\"width:100%;height:auto;postion:absolute\" />";
     //stylBgImg="style=\"background:url("+appPath+lsData[i]["col4"].trim()+");background-repeat:no-repeat;background-size:100% auto;background-position:center;\"";
 }
 let bgAssign="";

  let strBtn="";
        if(lsData[i]["col7"].trim()!==""){
            let arrStrBtn=lsData[i]["col8"].split(',');
            let arrBtnUrl=lsData[i]["col7"].split(',');
            for(let b=0;b<arrStrBtn.length;b++){
                strBtn+= "<a href=\""+arrBtnUrl[b]+"\" class=\"btn-transparent btn-hover-dark btn-2 pdall-10px \">"+arrStrBtn[b]+"</a>";    
            }
            
        }

 //alert("check== "+lsData[i]["col12"]);
 if(lsData[i]["col12"]!==""){
    bgAssign=lsData[i]["col12"];
 }   
  
 if(stylBgImg!==""){
    strResp+=  
            "<div class=\"col-md-6 col-sm-12 grid_image_cs\" style=\"position:relative\" align=\"center1\">"+
            "<div class=\"\">"+
            stylBgImg+
            "</div>"+
            
            //"<div data-aos=\"fade-up\" data-aos-duration=\"2000\" class=\"container-middle "+bgAssign+" privacy_content vertical-center-noabs_absolute section_2\">"+
            "<div class=\"pre_txt-hover\">"+lsData[i]["col2"]+"</div>"+
            "<div class=\"txt-hover\">"+lsData[i]["col3"]+"</div>"+
            strBtn+
            //"</div>"+
            
            "</div>";
                
                
               
               
                
            
      
 
 }
     
        }


    }

  if(topText!==""){
    topText="<div class=\"col-md-12 pb-10\">"+topText+"</div>"
  }  
    
    return strHeader+topText+strResp+strFooter;
}
export default Image_Grid;
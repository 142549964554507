import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './top_banner.css';
export function Top_Banner(lsData,isHome=false,injIndex=-1){
    var appPath=appGetUrlPath();
    var strResp="";
    
    let bgHeight="top-banner-img";
    if(isHome){
        bgHeight="top-banner-img-lg";
    }


  //  alert(lsData.length);
    for(var i=0;i<lsData.length;i++){

 var stylBgImg="";
 if(lsData[i]["col4"]!==""){
     //stylBgImg="style=\"background:url("+appPath+lsData[i]["col4"].trim()+") no-repeat;background-size:cover;background-position:center;width:100%;height:auto\"";
     stylBgImg="style=\"background:url("+appPath+lsData[i]["col4"].trim()+"); background-repeat:no-repeat;background-size:100% atuo;background-position:center;\"";
 }
 let bgAreaClass="breadcrumb-area";
 let bgBoxPadd="breadcrumb_box";
 if(lsData[i]["col12"]==="Bg-Img-4"){
    bgAreaClass="breadcrumb-area-4";
    bgBoxPadd="breadcrumb_box-4";
 }else if(lsData[i]["col12"]==="Bg-Img-3"){
    bgAreaClass="breadcrumb-area-3";
    bgBoxPadd="breadcrumb_box-3";
 }
 //alert(lsData[i]["col12"]);  
 let divId="";
 if(injIndex!==-1){
    divId="divind"+injIndex;
 }     
strResp+="<div "+stylBgImg+" id=\""+divId+"\""+" class=\""+bgAreaClass+" custome-responsive text-center top-banner \">"+

//"<img class=\"img-responsive w-100\" src=\""+appPath+lsData[i]["col4"].trim()+"\" style=\"visibility: hidden;max-height:500px;\" />"+
           "<div class=\"container\">"+
            "<div class=\"row\">"+
                "<div class=\""+bgBoxPadd+"  align-items-center col-lg-6 col-md-12 col-sm-12 "+bgHeight+" align-items-center\">"+
                        "<div data-aos=\"fade-up\" data-aos-duration=\"3000\" style=\"text-align: left;\">"+
                           "<h1>"+lsData[i]["col1"]+"</h1>"+
                           "<h4>"+lsData[i]["col2"]+"</h4>"+
                    "</div>"+
                "</div>"+
            "</div>"+
        "</div>"+
     
    "</div>";
    }
    //alert("testing=="+strResp);
    return strResp;
}
export default Top_Banner;
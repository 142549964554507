import React from 'react';
import healthCareImg from '../assets/images/icons/body.webp';
import maleWellImg from '../assets/images/icons/male.webp';
import eyeWellImg from '../assets/images/icons/eye.webp';
//import sexWellImg from '../assets/images/icons/sexual_wellness.webp';
import tummyImg from '../assets/images/icons/tummy.webp';
import immunityImg from '../assets/images/icons/immunity.webp';
import femaleImg from '../assets/images/icons/female.webp';
import liverImg from '../assets/images/icons/liver.webp'
//import skinImg from '../assets/images/icons/Skin_and_Beauty.webp';
import throatImg from '../assets/images/icons/throat.webp';
import congniImg from '../assets/images/icons/cognition.webp'


import './features_slider.css';

export function Features_Slider(){
    var strResp="";
    strResp+="<div data-aos=\"zoom-in-left\" class=\"feature_slider_bg border-box section pt-50px pb-100px\">"+
    "<div class=\"container\">"+
    "<div class=\"heading page__title-bar_testi\  pb-50px\"><h3 class=\"title\">Discover Our Solutions</h3></div>"+
        "<div class=\"category-slider swiper-container\">"+
            "<div class=\"category-wrapper swiper-wrapper\">"+
            
                "<div class=\"swiper-slide\">"+
                    "<a href=\"./products/body_wellness\" class=\"category-inner\">"+
                        "<div class=\"category-single-item feat_img\">"+
                            "<div class=\"feature_img_bg\"><img src=\""+healthCareImg+"\" alt=\""+healthCareImg+"\" /></div>"+
                            "<span class=\"title\">Body Wellness</span>"+
                        "</div>"+
                    "</a>"+
                "</div>"+
                "<div class=\" swiper-slide\">"+
                    "<a href=\"./products/male_wellness\" class=\"category-inner feat_img\">"+
                        "<div class=\"category-single-item\">"+
                            "<div class=\"feature_img_bg\"><img src=\""+maleWellImg+"\" alt=\""+maleWellImg+"\" /></div>"+
                            "<span class=\"title\">Male Wellness</span>"+
                        "</div>"+
                    "</a>"+
                "</div>"+

                "<div class=\"swiper-slide\">"+
                "<a href=\"./products/tummy_wellness\" class=\"category-inner feat_img\">"+
                    "<div class=\"category-single-item\">"+
                        "<div class=\"feature_img_bg\"><img src=\""+tummyImg+"\" alt=\""+tummyImg+"\" /></div>"+
                        "<span class=\"title\">Tummy Wellness</span>"+
                    "</div>"+
                "</a>"+
            "</div>"+

                "<div class=\" swiper-slide\">"+
                    "<a href=\"./products/eye_wellness\" class=\"category-inner feat_img\">"+
                        "<div class=\"category-single-item\">"+
                            "<div class=\"feature_img_bg\"><img src=\""+eyeWellImg+"\" alt=\""+eyeWellImg+"\" /></div>"+
                            "<span class=\"title\">Eye Wellness</span>"+
                        "</div>"+
                    "</a>"+
                "</div>"+
                /*
                "<div class=\" swiper-slide\">"+
                    "<a href=\"sexual_wellness\" class=\"category-inner feat_img\">"+
                        "<div class=\"category-single-item\">"+
                            "<div class=\"feature_img_bg\"><img src=\""+sexWellImg+"\" alt=\"\" /></div>"+
                            "<span class=\"title\">Sexual Wellness</span>"+
                        "</div>"+
                    "</a>"+
                "</div>"+
*/
                "<div class=\" swiper-slide\">"+
                "<a href=\"./products/immunity_wellness\" class=\"category-inner feat_img\">"+
                    "<div class=\"category-single-item\">"+
                        "<div class=\"feature_img_bg\"><img src=\""+immunityImg+"\" alt=\""+immunityImg+"\" /></div>"+
                        "<span class=\"title\">Immunity Wellness</span>"+
                    "</div>"+
                "</a>"+
            "</div>"+
             
            "<div class=\" swiper-slide\">"+
            "<a href=\"./products/female_wellness\" class=\"category-inner feat_img\">"+
                "<div class=\"category-single-item\">"+
                    "<div class=\"feature_img_bg\"><img src=\""+femaleImg+"\" alt=\""+femaleImg+"\" /></div>"+
                    "<span class=\"title\">Female Wellness</span>"+
                "</div>"+
            "</a>"+
        "</div>"+
        "<div class=\" swiper-slide\">"+
        "<a href=\"./products/liver_wellness\" class=\"category-inner feat_img\">"+
            "<div class=\"category-single-item\">"+
                "<div class=\"feature_img_bg\"><img src=\""+liverImg+"\" alt=\""+liverImg+"\" /></div>"+
                "<span class=\"title\">Liver Wellness</span>"+
            "</div>"+
        "</a>"+
    "</div>"+
    "<div class=\" swiper-slide\">"+
    "<a href=\"./products/male_wellness\" class=\"category-inner feat_img\">"+
        "<div class=\"category-single-item\">"+
            "<div class=\"feature_img_bg\"><img src=\""+congniImg+"\" alt=\""+congniImg+"\" /></div>"+
            "<span class=\"title\">Cognition Wellness</span>"+
        "</div>"+
    "</a>"+
"</div>"+
"<div class=\" swiper-slide\">"+
"<a  href=\"./products/throat_wellness\" class=\"category-inner feat_img\">"+
    "<div class=\"category-single-item\">"+
        "<div class=\"feature_img_bg\"><img src=\""+throatImg+"\" alt=\""+throatImg+"\" /></div>"+
        "<span class=\"title\">Throat Wellness</span>"+
    "</div>"+
"</a>"+
"</div>"+
               
            "</div>"+
        "</div>"+
    "</div>"+
"</div>";
//alert("testin g=="+strResp);
return strResp;
}
export default Features_Slider;
import React,{useState,useEffect } from 'react';

export function Comp_List_Map_Quest(props){
    let mappTitle="";
    if(props.titOnEvent==="child"){
        mappTitle="Parent";
    }else if(props.titOnEvent==="parent"){
        mappTitle="Child";
    }
/*
    const[getProps,setProps]=useState(props);    
useEffect(()=>{
    setProps(props);
},[props]);
*/
    //setListMappedQuest(props.listMappedQuest);
    
    return(<div className="modal fade bd-example-modal-xl"  id="divListMapQuest" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">{"Mapped Questionnaire "}({mappTitle})</h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmListMapQuest" name="frmListMapQuest" enctype="multipart/form-data" method="POST" action="#">
       
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                             
                              <div className="card-body">
                                  <div className="row">
                                  <div className="col-md-12">
                                    <table className="dtgrid table table-bordered table-hover text-sm text-nowrap12 table-responsive">
                                    <thead>
                    <tr class="border">
                        <th>S.No.</th>
                        <th>Document</th>
                        <th>Question</th>
                        <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                            {props.listMappedQuest.lenght!==0?props.listMappedQuest.map((item,index)=>{
                                return(<tr>
                                    <td>{index+1}</td>
                                    <td>{item["doc_header"]}</td>
                                    <td>{item["title"]}</td>
                                    <td>{props.titOnEvent==="parent"?<a href="javascript:void(0)" className="_setbackparam" param1={item["_id"]["$oid"]} param2={item["p_quest"]} resback={"onRemoveChildMapp"}>delete child</a>:''}</td>
                                </tr>)    
                            }):''}
                            </tbody>

                                    </table>

                                  </div>
                                      
                                     
                                  
                                      </div>
                                    
                                
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                              </div>
                          
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
         
        </div>
        </form>
      </div>
      </div>
      </div>)
}
export default Comp_List_Map_Quest;
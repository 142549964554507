import React from 'react'
import { appGetUrlPath } from '../function_lib/common_cms'
import parse from 'html-react-parser'
import './custome_section1.css'
export function sortResults(listjs, prop, asc) {
  listjs.sort(function (a, b) {
    if (asc) {
      return parseInt(a[prop]) > parseInt(b[prop])
        ? 1
        : parseInt(a[prop]) < parseInt(b[prop])
        ? -1
        : 0
    } else {
      return parseInt(b[prop]) > parseInt(a[prop])
        ? 1
        : parseInt(b[prop]) < parseInt(a[prop])
        ? -1
        : 0
    }
  })
  return listjs
}
export function Custome_Section1 (props = []) {
  let lsData = sortResults(props.lsData, 'col14', true)
  let appUrtPath = appGetUrlPath()
  //alert(JSON.stringify(lsData));
  let titleTxt = ''
  let titleSubDesc = ''
  if (lsData !== 'undefined') {
    if (lsData[0].col10 === 'top_text') {
      titleTxt = lsData[0].col1
      titleSubDesc = lsData[0].col2
    }
  }
  //for data sample
  return (
    <section class='u-clearfix u-grey-5 u-section-2' id='carousel_86f6'>
      {/* <div class='u-bottom-right-radius-25 u-palette-2-base u-shape u-shape-round u-shape-1'></div> */}
      <div class="container">
         <div class="row">
        <div class="col-md-6 thumb ">
        {lsData !== 'undefined' &&
      lsData[0].col10 === 'top_text' &&
      lsData[0].col4 !== '' ? (
        <img
          class='u-image1 u-image-round1 u-radius-25 u-image-11'
          data-image-width='900'
          data-image-height='855'
          src={appUrtPath + lsData[0].col4}
        />
      ) : (
        ''
      )}

       
      </div>
        <div class="col-md-6">
        <div class='u-align-left u-container-style u-group u-palette-1-dark-2 u-radius-25 u-shape-round u-group-11 vcenter'>
        <div class='u-container-layout u-valign-middle u-container-layout-1'>
          <h5 class='u-text u-text-default u-text-1'></h5>
          <h1 class='u-text u-text-2'>{titleTxt}</h1>
          <p class='u-text u-text-body-alt-color u-text-3'>{titleSubDesc}</p>
        </div>
      </div>
        </div>
      </div>
     
    
      <div class='u-list u-list-1 overlap-1'>
        <div class='u-repeater u-repeater-1'>
          {lsData !== 'undefined'
            ? lsData.map((item, index) => {
                if (item.col10 !== 'top_text') {
                  return (
                    <div class='u-align-left u-container-style u-list-item u-radius-25 u-repeater-item u-shape-round u-white u-list-item-1'>
                      <div class='u-container-layout u-similar-container u-valign-top u-container-layout-2'>
                        <span class='u-file-icon u-icon u-icon-1'>
                          <img src={appUrtPath + item.col4} alt='' />
                        </span>
                        <h6 class='u-custom-font u-font-pt-sans u-text u-text-palette-1-base u-text-4'>
                          {item.col1}
                        </h6>
                        <p class='u-text u-text-5'>{parse(item.col3)}</p>
                      </div>
                    </div>
                  )
                }
              })
            : ''}
        </div>
      </div>
      </div>
    </section>
  )
}
export function Custome_Section1_old (props = []) {
  let lsData = sortResults(props.lsData, 'col14', true)
  let appUrtPath = appGetUrlPath()
  //alert(JSON.stringify(lsData));
  let titleTxt = ''
  let titleSubDesc = ''
  if (lsData !== 'undefined') {
    if (lsData[0].col10 === 'top_text') {
      titleTxt = lsData[0].col1
      titleSubDesc = lsData[0].col2
    }
  }
  //for data sample
  return (
    <section class='u-clearfix u-grey-5 u-section-2' id='carousel_86f6'>
      <div class='u-bottom-right-radius-25 u-palette-2-base u-shape u-shape-round u-shape-1'></div>
      {lsData !== 'undefined' &&
      lsData[0].col10 === 'top_text' &&
      lsData[0].col4 !== '' ? (
        <img
          class='u-image u-image-round u-radius-25 u-image-1'
          data-image-width='900'
          data-image-height='855'
          src={appUrtPath + lsData[0].col4}
        />
      ) : (
        ''
      )}

      <div class='u-align-left u-container-style u-group u-palette-1-dark-2 u-radius-25 u-shape-round u-group-1'>
        <div class='u-container-layout u-valign-middle u-container-layout-1'>
          <h5 class='u-text u-text-default u-text-1'></h5>
          <h1 class='u-text u-text-2'>{titleTxt}</h1>
          <p class='u-text u-text-body-alt-color u-text-3'>{titleSubDesc}</p>
        </div>
      </div>
      <div class='u-list u-list-1'>
        <div class='u-repeater u-repeater-1'>
          {lsData !== 'undefined'
            ? lsData.map((item, index) => {
                if (item.col10 !== 'top_text') {
                  return (
                    <div class='u-align-left u-container-style u-list-item u-radius-25 u-repeater-item u-shape-round u-white u-list-item-1'>
                      <div class='u-container-layout u-similar-container u-valign-top u-container-layout-2'>
                        <span class='u-file-icon u-icon u-icon-1'>
                          <img src={appUrtPath + item.col4} alt='' />
                        </span>
                        <h6 class='u-custom-font u-font-pt-sans u-text u-text-palette-1-base u-text-4'>
                          {item.col1}
                        </h6>
                        <p class='u-text u-text-5'>{parse(item.col3)}</p>
                      </div>
                    </div>
                  )
                }
              })
            : ''}
        </div>
      </div>
    </section>
  )
}
export default Custome_Section1

import React from 'react';
import {new_enc_dec_m} from '../function_lib/common_lib';
export const Comp_CPA_Dashboard=(props)=>{
  let dashDiv1={}
  dashDiv1["total_cpa"]=0;
  dashDiv1["total_tax"]=0;
  if(props.cpaTaxTotal.length>0){
    dashDiv1["total_cpa"]=props.cpaTaxTotal[0]["total_cpa"];
    dashDiv1["total_tax"]=props.cpaTaxTotal[0]["total_tax"];
  }
return (<div className="row mt-4">
{/* invoice order view */}
<div className="col-md-4">
       <div className="card card-widget widget-user-2">
         <div className="widget-user-header bg-danger">
           <div className="widget-user-image">
            <span className="info-box-icon text-red1 bg-info1 elevation-11"><i className="fa fa-cogs fa-3x" aria-hidden="true"></i></span>
           </div>
           <h3 className="widget-user-username"></h3>
           <h5 className="widget-user-desc">{"Customers"}</h5>
         </div>
         <div className="card-footer p-0">
           <ul className="nav flex-column">
           {/* <li className="nav-item">
               <a href="#" className="nav-link gion">
                 <i className="fa fa-info-circle"></i>
               Total CPA<span className="float-right badge bg-success">{"27"}</span>
               </a>
             </li> */}
             <li className="nav-item">
             <a className="nav-link gion" href="../tax_payer_master">
             <i className="fa fa-info-circle"></i>Total TaxPayer
             <span className="float-right badge bg-primary">{dashDiv1["total_tax"]}</span>
             </a>
             </li>
             <li className="nav-item">
             <a className="nav-link gion" href="../tax_payer_master">
             <i className="fa fa-info-circle"></i>Add New TaxPayer <span className="float-right badge bg-danger">{""}</span></a>
             </li>
             {/* <li className="nav-item">
               <a href="javascript:void(0)" className="nav-link gion" data-toggle="modal"  data-target="#divEntityDoc">
               <i className="fa fa-info-circle"></i>Pending Registration Certificates <span className="float-right badge bg-success">{"9"}</span>
               </a>
             </li> */}
            
           </ul>
         </div>
       </div>
     </div>
     {/* end of invoice order view */}


{/* recent orders */}
<div className="col-md-4">
       <div className="card card-widget widget-user-2">
         <div className="widget-user-header card-primary">
           <div className="widget-user-image">
            <span className="info-box-icon text-red1 bg-info1 elevation-11"><i className="fa fa-cubes fa-3x" aria-hidden="true"></i></span>
           </div>
           <h3 className="widget-user-username"></h3>
           <h5 className="widget-user-desc">{"Customers Activities"}</h5>
         </div>
         <div className="card-footer p-0">
           <ul className="nav flex-column">
             <li className="nav-item">
             <a className="nav-link" href="javascript:void(0)">Today
             <span className="float-right badge bg-primary">{"12"}</span>
             </a>
             </li>
             <li className="nav-item">
             <a className="nav-link" href="javascript:void(0)">Yesterday <span className="float-right badge bg-danger">{"10"}</span></a>
             </li>
             <li className="nav-item">
               <a href="#" className="nav-link">
               Weekly <span className="float-right badge bg-success">{"32"}</span>
               </a>
             </li>
             <li className="nav-item">
               <a href="#" className="nav-link">
               Till Date <span className="float-right badge bg-success">{"32"}</span>
               </a>
             </li>
             
           </ul>
         </div>
       </div>
     </div>
{/* end of recent orders */}

{/* Action To DO */}
<div className="col-md-4">
       <div className="card card-widget widget-user-2">
         <div className="widget-user-header bg-info">
           <div className="widget-user-image">
            <span className="info-box-icon text-red1 bg-info1 elevation-11"><i className="fa fa-cubes fa-3x" aria-hidden="true"></i></span>
           </div>
           <h3 className="widget-user-username"></h3>
           <h5 className="widget-user-desc">{"Action To Do"}</h5>
           {/* <p class="card-text">Important to do</p> */}
         </div>
         <div className="card-footer p-0">
           <ul className="nav flex-column">
             <li className="nav-item">
             <a className="nav-link" href="javascript:void(0)">Questionnaire Submission
             <span className="float-right badge bg-primary">{"pending"}</span>
             </a>
             </li>
             <li className="nav-item">
             <a className="nav-link" href="javascript:void(0)">Form Data Submission <span className="float-right badge bg-danger">{"pending"}</span></a>
             </li>
             <li className="nav-item">
               <a href="#" className="nav-link">
               Pending for Returns <span className="float-right badge bg-success">{"9"}</span>
               </a>
             </li>
             <li className="nav-item">
               <a href="#" className="nav-link">
               Completed Returns <span className="float-right badge bg-success">{"9"}</span>
               </a>
             </li>
             
           </ul>
         </div>
       </div>
     </div>
{/* end of Action to DO */}

{/* 4th Item */}
<div className="col-md-4 pb-20px pt-20px">
<div class="card" style={{width: "18rem;"}}>

<div class="card-body">
<h5 class="card-title">Packages</h5>
<p class="card-text"></p>
</div>
<ul class="list-group list-group-flush">

<li class="list-group-item gion"><i className="fa fa-tags"></i>Service Packages</li>
<li class="list-group-item gion"><i className="fa fa-tags"></i>Expiry in Week</li>
<li class="list-group-item gion"><i className="fa fa-tags"></i>Expiry in Month</li>
<li class="list-group-item gion"><i className="fa fa-tags"></i>Expired Packages</li>
</ul>
</div>

</div>
{/* 5th Item */}
<div className="col-md-4 pb-20px pt-20px">
<div class="card" style={{width: "18rem;"}}>

<div class="card-body">
<h5 class="card-title">Payments/Transaction</h5>
<p class="card-text"></p>
</div>
<ul class="list-group list-group-flush">
<li class="list-group-item gion"><i className="fa fa-bank"></i>Make the Payments</li>
<li class="list-group-item gion"><i className="fa fa-inr"></i>Payment in Process</li>
<li class="list-group-item gion"><i className="fa fa-file"></i>Setlled Transaction Log</li>
<li class="list-group-item gion"><i className="fa fa-file"></i>Setlled Transaction Reporting</li>
</ul>
</div>

</div>
{/* 6th Item */}
<div className="col-md-4 pb-20px pt-20px">
<div class="card" style={{width: "18rem;"}}>

<div class="card-body">
<h5 class="card-title">Message/Complaints</h5>
<p class="card-text">Assigned:</p>
</div>
{typeof props.taxConsultant!=="undefined"?props.taxConsultant.map((item,index)=>{
return <ul class="list-group list-group-flush">

<li class="list-group-item">Name :{item.first_name}</li>
<li class="list-group-item">Email :{item.email_id}</li>
<li class="list-group-item">A third item</li>
</ul>

}):''}

</div>

</div>

</div>)
}
export default Comp_CPA_Dashboard;
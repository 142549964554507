import $ from 'jquery';
import React from 'react';
import { Helmet } from 'react-helmet';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,RespBkMthdMapp,AjaxCallMethod,GetImgCertUser} from '../function_lib/common_lib';
import {getRoleDetails,CallCMSMapMethod} from '../function_lib/common_cms';
import Product_Listing from '../sample_comp/product_listing';
import Front_Header from './front_header';
import Footer from './footer';
import Top_Banner from '../sample_comp/top_banner';
import Top_Specified from './top_specified';
import Home_Product from './home_product';
import Product_Slider from '../sample_comp/product_slider';
import Features_Slider  from '../sample_comp/features_slider';
import Feature_Web_List from '../sample_comp/feature_web_list';
import Article_Listing, { Article_Listing_2 } from '../sample_comp/article_listing';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import {Left_Img_Txt_Desc,Left_Txt_Txt_Desc} from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';
import ImageGallery from '../sample_comp/image_gallery';
import Video_Slider from '../sample_comp/video_slider';
import Collapse_Pan from '../sample_comp/collapse_pan';
import Feature_Card from '../sample_comp/feature_card';
import Testimonials from '../sample_comp/testimonials';
import Blog_Listing,{Blog_Listing_2} from '../sample_comp/blog_listing';


import { Player } from 'video-react';
import AOS from 'aos';
import LogoImg from '../assets/images/my_logo.jpg';
import titleVid from '../assets/videos/imp_water.mp4';

//import titleVid from '../assets/images/backg.gif';
import './index.css';
/* Media */
import logoImg from '../assets/images/logo/logo.png';

import ban1 from '../assets/images/slider-image/02.jpg';
import ban2 from '../assets/images/slider-image/02.jpg';

import why_medi from '../assets/images/icons/capsule.png';
import why_edu from '../assets/images/icons/fist.png';
import why_care from '../assets/images/icons/heart.png';
import why_confi from '../assets/images/icons/discretion.png';
import why_trans from '../assets/images/icons/eye.png';

import pImg1 from '../assets/images/product-image/AsthmaticaOil.png'
import Left_Menu_Option from '../sample_comp/left_menu_option';
import Blogs from '../sample_comp/blogs';
import Time_Line from '../sample_comp/time_line';


import Footer_Register from '../sample_comp/footer_register';
import Image_Collarge_Grid from '../component_version2/image_collarge_grid';

import '../sample_comp/why_us.css';

let loginUser="";
let userObject="";
let clsObject="";
let loc="";
let products="";
let rightBarLists;

let _topTitle="";
let _topKeywords="";
let _topDescription="";

let collargeList="";
class Blog_Page extends React.Component{
    constructor(props){
        super(props);
        this.state={viewData:"0"};
        this.multiselectRef = React.createRef();
        this.state={ top_title:"",top_keywords:"",top_desc:""};
        loc=props.location["pathname"].substr(1);
        //alert(JSON.stringify(this.props.match.params[1]));
        //alert(loc);
        if(localStorage.getItem("userData")!=null){
          //alert(localStorage.getItem("userData"));
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
         var listRoles= UserToken.getAccessRoles();
          this.setMetaTags(listRoles);
          if(userObject){
            //loginUser=userObject[0].person_name;
          }
          rightBarLists= getRoleDetails(listRoles,loc);
         
          //window.location.href="./Dashboard";
        }
      }
componentDidMount(){
  clsObject=this;
  var mapCat=[];
  if(loc===""){
      loc="home";
  }
  var pgMap=[];
  pgMap.push({name:"curl_type",value:"getPgData"});
  pgMap.push({name:"res_func",value:"callBackpgData"});
  pgMap.push({name:"loc",value:loc});

  /* only for product */
  pgMap.push({name:"get_prod",value:"d"});
  pgMap.push({name:"p1",value:"FEATURED PRODUCT"});
  pgMap.push({name:"p2",value:"0"});
  //mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
  //mapCat.push({name:"emp_id",value:userObject[0].id});
  pgMap["res_func"]="callBackpgData";
  pgMap["curl_type"]="getPgData";
  CallCMSMapMethod(clsObject,pgMap);
  AjaxCallMethod(clsObject,"okSuccCNOT");
  
}
setMetaTags(listMeta){
   // alert(JSON.stringify(listMeta));

    for(var m=0;m<listMeta.length;m++){
        if(loc===""){
            loc="home";
        }
       if(listMeta[m]["col2"]===loc){
           //alert(listMeta[m]["col2"]);
           _topTitle=listMeta[m]["col3"];
           _topKeywords=listMeta[m]["col4"];
           _topDescription=listMeta[m]["col5"];
        document.querySelector('meta[name="title"]').setAttribute("content", _topTitle);
        document.querySelector('meta[name="description"]').setAttribute("content", _topDescription);
        document.querySelector('meta[name="keywords"]').setAttribute("content", _topKeywords);
       }
      
    }
    
}
callBackpgData(data,msg){
    //alert("testing of2 "+data);
//  $("#pgComp").html(data);
    //  return;
    let listObj=JSON.parse(data);
    //var listObj= JSON.parse("{\"access\":"+data+"}");
    
    // alert(JSON.stringify(listObj.access));
    // $("#pgComp").html(JSON.stringify(listObj.access));
    // return;
    //alert("successfully parse");
    var mainHt="";
    if(loc==="home"){
       // mainHt+=Video_Banner_Comp();
    }

    var injextI=0;
    var injCondition=false;
    var ifImgGallery=false;
    
    var strTopBanner="";
    for(var _dlist=0;_dlist<100;_dlist++){
     
        if(listObj.access[_dlist]){
            
            if(listObj.access[_dlist].top_banner){
               // alert("I am in conditio");
               injCondition=true;
               _topTitle=listObj.access[_dlist].top_banner[0]["top_title"];
               strTopBanner=Top_Banner(listObj.access[_dlist].top_banner);
            }
            else if(listObj.access[_dlist].home_banner){
               // alert("i am in cond..");
               injCondition=true;
               //alert(JSON.stringify(listObj.access[_dlist].home_banner[0]));
               _topTitle=listObj.access[_dlist].home_banner[0]["top_title"];
                mainHt+=Home_Banner(listObj.access[_dlist].home_banner);
            }else if(listObj.access[_dlist].dual_text_card){
                injCondition=true;
                //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
                mainHt+=Left_Txt_Txt_Desc(listObj.access[_dlist].dual_text_card);
            }else if(listObj.access[_dlist].single_img_text){
                injCondition=true;
                _topTitle=listObj.access[_dlist].single_img_text[0]["top_title"];
                mainHt+=Single_Img_Msg(listObj.access[_dlist].single_img_text);
            }else if(listObj.access[_dlist].img_text_desc_img_left){
                injCondition=true;
                _topTitle=listObj.access[_dlist].img_text_desc_img_left[0]["top_title"];
                mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_left);
            }else if(listObj.access[_dlist].img_text_desc_img_right){
                injCondition=true;
                _topTitle=listObj.access[_dlist].img_text_desc_img_right[0]["top_title"];
                mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_right,true);
            }
                else if(listObj.access[_dlist].text_description){
                injCondition=true;
                //alert("i am in text");
                //alert(Txt_Description(listObj.access[_dlist].text_description));
                mainHt+=Txt_Description(listObj.access[_dlist].text_description);
            }else if(listObj.access[_dlist].list_slider){
                injCondition=true;
                mainHt+=Article_Blogs(listObj.access[_dlist].list_slider);
            }else if(listObj.access[_dlist].image_gallery){
                injCondition=true;
                ifImgGallery=true;
                mainHt+=ImageGallery(listObj.access[_dlist].image_gallery);
            }else if(listObj.access[_dlist].video_slider){
                //alert("I am in testing");
                injCondition=true;
                mainHt+=Video_Slider(listObj.access[_dlist].video_slider);
            }
            else if(listObj.access[_dlist].blogs){
                
                injCondition=true;
                mainHt+=Blogs(listObj.access[_dlist].blogs);
            }else if(listObj.access[_dlist].feature_web_list){
                injCondition=true;
                //alert("I am in feature "+listObj.access[_dlist].feature_web_list);
                mainHt+=Feature_Card(listObj.access[_dlist].feature_web_list);
            }else if(listObj.access[_dlist].article_listing){
                injCondition=true;
                //alert("I am in feature "+listObj.access[_dlist].feature_web_list);
                //mainHt+=Article_Listing(listObj.access[_dlist].article_listing);
                mainHt+=Article_Listing_2(listObj.access[_dlist].article_listing);
            }else if(listObj.access[_dlist].blog_listing){
                injCondition=true;
                collargeList=listObj.access[_dlist].blog_listing;
                mainHt+=Blog_Listing_2(listObj.access[_dlist].blog_listing,true);
            }
            else if(listObj.access[_dlist].collapse_pan){
                injCondition=true;
                mainHt+=Collapse_Pan(listObj.access[_dlist].collapse_pan);
            }else if(listObj.access[_dlist].time_line){
                injCondition=true;
                mainHt+=Time_Line(listObj.access[_dlist].time_line);
            }else if(listObj.access[_dlist].testimonials){
                injCondition=true;
                mainHt+=Testimonials(listObj.access[_dlist].testimonials);
            }else if(listObj.access[_dlist].top_info_slider){
                   injCondition=true;
                   //alert("i am in topindo");
                   $("#top_msg_bar").html(listObj.access[_dlist].top_info_slider[0]["col2"]);
            }
            if(injCondition){
                injextI++;
            }
            if(injextI==1){
                //alert("check in condi == "+loc)
                if(loc==="home"){
                    //mainHt+=Features_Slider();
                    //this.callBackProList(JSON.stringify(listObj.product));
                    //alert(products);
                    //mainHt+=products;
                }
           }
          
        }

      
        //$("#pgComp").innerHtml=mainHt;
    }
    if(loc.indexOf("library/")!==-1){
        mainHt+=products;
    }
    //alert(_topTitle);
   
    //alert(mainHt);
    //mainHt+=Product_Hori_Slider();

    //Condition for leftMenuOpt
    if(rightBarLists){
        var getLeftOpt=Left_Menu_Option(rightBarLists,loc);
    var mainDiv="<div class=\"shop-category-area pb-100px pt-70px\">"+
    "<div class=\"container\">"+
        "<div class=\"row\">";
    var mainCloseDiv="</div></div></div>";
    mainHt="<div class=\"col-lg-9 order-lg-last col-md-12 order-md-first\">"+
            mainHt+"</div>";
    mainHt=mainDiv+getLeftOpt+mainHt+mainCloseDiv;
      }
   mainHt=strTopBanner+mainHt;
   let strCollarge=Image_Collarge_Grid(collargeList,true);
   $("#blog_collarge").html(strCollarge);
    $("#pgComp").html(mainHt);

    RespBkMthdMapp(clsObject,"_addToCart","setCartBk");

    AOS.init();
    AOS.init({
        once: true
      });
    // AOS.init({
    //   once: true,
    //   duration: 1000
    // });
   
     
    const script = document.createElement("script");
    script.src = "/main.js";
    script.async = true;
    //script.onload = () => clsObject.scriptLoaded();
  
    document.body.appendChild(script);
      //this.forceUpdateHandler();
    if(ifImgGallery){
        $('.gallery-item').magnificPopup({
            type: 'image',
            gallery: {
                enabled: true
            }
        });
    }
  
}
setCartBk(refId){
   // alert(refId);
    this.setState(state =>({viewData:refId}));
    this.forceUpdate();
    $("#offcanvas-cart").addClass("offcanvas-open");
  }
callBackProList(data,msg){
    if(data!=="0"){
     var resPs= Product_Slider(data);
     products+=resPs;
    }
  
  }
saveEdu(){
  GetImgCertUser(clsObject,"#frmEdu","getTest","okEdut");
}
okEdut(data,datares,userResp){
  //alert(data);
}
    render(){
        return(<div>
          <Front_Header loc={loc}/>

          <div className="offcanvas-overlay"></div>
        
        <div className='container pt-70px'>
        <div id="blog_collarge">
        </div>   
            <div className='row'>
                {/* Side Categories */}
           
                {/* end of side bar */}
                <div className='col-lg-12 order-lg-last col-md-12 order-md-first'>
                <div class="sidebar-widget mt-40px">
                            <div class="sidebar-widget-tag d-inline-block">
                                <ul>
                                    <li><a href="#">All</a></li>
                                    <li><a href="#">Data Engineering</a></li>
                                    <li><a href="#">Artificial Intelligence</a></li>
                                    <li><a href="#">Business Analytic</a></li>
                                    <li><a href="#">Others</a></li>
                                    
                                </ul>
                            </div>
                        </div>
                     
    <div id="pgComp">

</div>
        </div>   
            </div>
        </div>
      
                
        
        
        <Footer_Register/>
         <Footer/>
          
          
          
          
       
          </div>
          
          );
    }
}
export default Blog_Page
import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './image_collarge_grid.css';

// component ref from ArticleListing
export function Image_Collarge_Grid(lsData,isBlog=false){
 
    if(!isBlog){
        let appPath=appGetUrlPath();
        let strHeader="<div  class=\"orange-bg-2 main-blog-area pt-100px pb-100px\">"+
        "<div class=\"container\">"+
        "<div class=\"row\">"+
        "<div class=\"col-md-12\">"+
            "<div class=\"heading page__title-bar_testi\">"+
                "<h3 class=\"pb-50px title-black\">"+"You are"+"</h3>"+
            "</div>"+
        "</div>"+
       "</div>"+
        "<div class=\"grid\">";
        let strFooter="</div></div></div>";
        
        let strResp="";
        for(let i=0;i<lsData.length;i++){
          strResp+="<div class=\"photo\">"+
          "<img src=\""+appPath+lsData[i]["col4"].trim()+"\" width=\"800\" height=\"533\" alt=\"Photo\">"+
        "</div>"
        }
       
       
           return strHeader+ strResp+strFooter;
    }else{
        let appPath=appGetUrlPath();
        let landPgUrl="../blog/";
      
        let strHeader="<div class=\"main-blog-area pt-10px pb-50px\">"+
        "<div class=\"container1\">"+
        "<div class=\"grid\">";
        let strFooter="</div></div></div>";
        
        let strResp="";
        for(let i=0;i<lsData.length;i++){
          let urlTitle= lsData[i]["col1"];
          urlTitle=urlTitle.replace("?","_");
          urlTitle=urlTitle.replace(" ","-");
  

          strResp+="<div class=\"photo\"><a href=\""+landPgUrl+urlTitle+"?rf="+lsData[i]["e_id"]+"\">"+
          "<img src=\""+appPath+lsData[i]["col4"].trim()+"\" width=\"800\" height=\"533\" alt=\"Photo\">"+
          "<div class=\"clrg_title\" >"+lsData[i]["col1"]+"</div>"+
        "</div></a>"
        if(i>=4){
          break;
        }
        }
       
       
           return strHeader+ strResp+strFooter;
    }
   
}
export default Image_Collarge_Grid;

import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
export function Txt_Description(lsData,injIndex=-1){
    var appPath= appGetUrlPath();
    var strResp="";
   
    //alert(lsData.length);
    let styleVHeight="";
    for(var i=0;i<lsData.length;i++){
        let vHeight="auto";
        if(lsData[i]["col11"]==="full_screen_length"){
            vHeight="100vh";
            styleVHeight="set-screen";
        }
 var stylBgImg="";
 //alert("check img== "+lsData[i]["col4"]);
 if(lsData[i]["col4"]!==""){
//     if(lsData[i]["col4"].indexOf(".svg")!==-1){
//      //   stylBgImg="<object data=\""+appPath+lsData[i]["col4"].trim()+"\" width=\"100%\" height=\"auto\"> </object>";
        
//        // stylBgImg+="<img class=\"img-responsive "+styleVHeight+" adjust-layouts-img w-100\" src=\""+appPath+lsData[i]["col5"].trim()+"\" style=\"width:100%;postion:absolute;display:none\" />";
// //         stylBgImg="<svg width=\"100%\" height=\"100%\" version=\"1.1\""+
// //         "xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\">"+
// //      "<image href=\""+appPath+lsData[i]["col4"].trim()+"\" x=\"0\" y=\"0\" height=\"100%\" width=\"100%\"/>"+
// //    "</svg>";
//     }
//     else{
//         stylBgImg="<img class=\"img-responsive "+styleVHeight+" adjust-layouts-img w-100\" src=\""+appPath+lsData[i]["col4"].trim()+"\" style=\"width:100%;postion:absolute\" />";
//     }
    
    stylBgImg="<img class=\"img-responsive "+styleVHeight+" adjust-layouts-img w-100\" src=\""+appPath+lsData[i]["col4"].trim()+"\" style=\"width:100%;postion:absolute\" />";
    
 }
 let bgAssign="";

  let strBtn="";
        if(lsData[i]["col7"].trim()!==""){
            let arrStrBtn=lsData[i]["col8"].split(',');
            let arrBtnUrl=lsData[i]["col7"].split(',');
            for(let b=0;b<arrStrBtn.length;b++){
                strBtn+= "<a href=\""+arrBtnUrl[b]+"\" class=\"btn-transparent btn-hover-dark btn-2 pdall-10px \">"+arrStrBtn[b]+"</a>";    
            }
            
        }

 //alert("check== "+lsData[i]["col12"]);
 if(lsData[i]["col12"]!==""){
    bgAssign=lsData[i]["col12"];
 }   

 let divId="";
 if(injIndex!==-1){
    divId="divind"+injIndex;
 }  

 if(stylBgImg!==""){
    strResp+=""+
    "<div  class=\"container11\" id=\""+divId+"\">"+
        "<div class=\"container-inner11\" style=\"max-width:100%\">"+
            "<div class=\"row12\">"+
            "<div class=\"col-12\" style=\"position:relative\" align=\"center1\">"+
            "<div class=\"\">"+
            stylBgImg+
            "</div>"+
            
            "<div data-aos=\"fade-up\" data-aos-duration=\"2000\" class=\"container-middle "+bgAssign+" privacy_content vertical-center-noabs_absolute section_2\">"+
            
            "<h3 class=\"title\">"+lsData[i]["col1"]+"</h3>"+
            "<div class=\"mob_lenght\">"+lsData[i]["col2"]+"</div>"+
            "<div class=\"mob_lenght\">"+lsData[i]["col3"]+"</div>"+
            strBtn+
            "</div>"+
            
            "</div>"+
                
                
               
               
                
            "</div>"+
        "</div>"+
    "</div>"+
"</div>";
 }else{
    strResp+="<div "+stylBgImg+"  id=\""+divId+"\" class=\"border-box "+bgAssign+" contain-gb privacy_policy_main_area pb12-70px pt-70px\">"+
      "<div class=\"container\">"+
          "<div class=\"container-inner\">"+
              "<div class=\"row\">"+
              "<div class=\"col-12\" align=\"center1\">"+
              "<div class=\"\">"+
              "<img class=\"img-responsive w-100\" src=\""+appPath+lsData[i]["col4"].trim()+"\" style=\"visibility: hidden;max-height:550px;\" />"+
              "</div>"+
              "<div data-aos=\"fade-up\" data-aos-duration=\"2000\" class=\"privacy_content vertical-center-noabs22 section_2\">"+
              "<h3 class=\"title\">"+lsData[i]["col1"]+"</h3>"+
              "<p>"+lsData[i]["col2"]+"</p>"+
              "<p>"+lsData[i]["col3"]+"</p>"+
              "</div>"+
              
              "</div>"+
                  
                  
                 
                 
                  
              "</div>"+
          "</div>"+
      "</div>"+
  "</div>";
 }
     



    }
    
    return strResp;
}
export default Txt_Description;
import React from 'react';
import { Link } from 'react-router-dom';
export function Tax_Header(props){
    return(<div className="container_top position-relative">
    <div className="row">
        
        <div className="col-md-12 align-self-center">
            <div className="main-menu menu-center manu-color-white">
                {/* doc_rf only set when it will be open via CPA/or Admin to view end user docs */}
                {typeof props.doc_rf!=="undefined" && props.doc_rf!=="0"?<ul id="menu_opt12">
                
                  <li><a id="quest_link12" href={"../quest_overview?doc_rf="+props.doc_rf}><i className="icon-question"></i>Questionnair</a></li> 
                  <li><a id="doct_link12" href={"../document_list?doc_rf="+props.doc_rf}><i className="ion-android-document"></i>Documents</a></li>
                  <li><a href="javascript:void(0)"><i className="ion-ios-folder"></i>Verification</a></li>
                  <li><a href="javascript:void(0)"><i className="ion-arrow-move"></i>Returns</a></li>
                  <li><a href="javascript:void(0)"><i className="icon-pin"></i>Tax Payments</a></li>
                  <li><a href="javascript:void(0)"><i className="icon-list"></i>Completed History</a></li>
                  {/* apply login menus here */}
                </ul>:<ul id="menu_opt12">
                <li><Link to={"../dashboard"}><i className="icon-home"></i>Dashboard</Link></li>
                  {/* <li><a id="quest_link" href="../quest_overview"><i className="icon-question"></i>Questionnair</a></li>
                  <li><a id="doct_link" href="../document_list"><i className="ion-android-document"></i>Documents</a></li> */}
                  <li><a href="javascript:void(0)"><i className="ion-ios-folder"></i>Verification</a></li>
                  <li><a href="javascript:void(0)"><i className="ion-arrow-move"></i>Returns</a></li>
                  <li><a href="javascript:void(0)"><i className="icon-pin"></i>Tax Payments</a></li>
                  <li><a href="javascript:void(0)"><i className="icon-list"></i>Completed History</a></li>
                  {/* apply login menus here */}
                </ul>}
                
            </div>
        </div>
        
    </div>

    

</div>)
}
export default Tax_Header
import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
export function Footer_Options(lsData){

    var appPath=appGetUrlPath();

   
    let strResp="";
    
    let strCol_="";
    if(lsData.length===1){
        strCol_="col-md-12";
    }else if(lsData.length===2){
        strCol_="col-md-6";
    }else if(lsData.length===3){
        strCol_="col-md-4";
    }

    let txtCols="";
    if(lsData.length>0){
        if(lsData[0]["col2"].trim()!==""){
            txtCols="col-md-7";
            strCol_="col-md-5";
        }
    }

  

    for(let i=0;i<lsData.length;i++){
        var stylBgImg="";
        if(lsData[i]["col4"]!==""){
            //stylBgImg="style=\"background:url("+appPath+lsData[i]["col4"].trim()+") no-repeat;background-size:cover;background-position:center;width:100%;height:auto\"";
            stylBgImg="style=\"background:url("+appPath+lsData[i]["col4"].trim()+"); background-repeat:no-repeat;background-size:cover;background-position:center;\"";
        }

        if(txtCols!==""){
            strResp+="<div class='"+txtCols+" text-center txt-white pb-10 pt-10'>"+
            lsData[i]["col2"]+
            "</div>"
            strResp+="<div class='"+strCol_+" text-center pb-10 pt-10'>"+
            "<a href=\""+lsData[i]["col7"]+"\" class=\"add-cart btn btn-primary btn-hover-primary ml-4\">"+lsData[i]["col1"]+"</a>"+
            "</div>"
        }else{
            strResp+="<div class='"+strCol_+" text-center'>"+
            "<a href=\""+lsData[i]["col7"]+"\" class=\"add-cart btn btn-primary btn-hover-primary ml-4\">"+lsData[i]["col1"]+"</a>"+
            "</div>"
        }

      
    }

    let strHeader="<div "+stylBgImg+" class=\"border-box txt-white bg-strip2 privacy_policy_main_area p12b-70px pt-70px\">"+
    "<div class=\"container pb-20px pt-20px\">"+
    "<div class=\"container-inner\">"+
   "<div class=\"row\">";
let strFooter="</div></div></div></div>";

 return strHeader+strResp+strFooter;   

}
import $ from 'jquery';
import React from 'react';
import Cart_Canvas from '../sample_comp/cart_canvas';
import PayImg from '../assets/images/icons/payment.png';

import amazon from '../assets/images/footer/amazonpay.png';
import bhim from '../assets/images/footer/fbhim.png';
import gpay from '../assets/images/footer/gpay.png';
import mastercard from '../assets/images/footer/mastercard.png';
import rupay from '../assets/images/footer/rupay.png';
import visa from '../assets/images/footer/visa.png';
import logoImg from '../assets/images/logo/logo.png';
import './footer.css';
let cartItems;
class Footer extends React.Component{
    componentDidMount(){
        cartItems=localStorage.getItem("_cart");
        
        var strList="";
          let finalAmt=0;
        if(cartItems!=null && cartItems!==""){
          cartItems=JSON.parse(cartItems);
          //alert("i am in cart item "+cartItems.length);
          $("._cart_elm_count").html(cartItems.length);
          $("._cart_elm_sun").html("₹"+window.cAmount); 
        }
      }
    render(){
        return(
        <div className="pt-100122px">
        <div className="footer-area">
        <div className="footer-container">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-6 col-lg-3 mb-md-30px mb-lm-30px">
                            <div className="single-wedge">
                               <img className='single_img' src={logoImg} />
                                {/* <p className="about-text1">
                                Email:contact@firstcode.co.in<br></br>
                                Customer care: 1800- 889- 0114
                                <br></br><br></br>
                                 <br></br>
                                
                                </p> */}
                            </div>
                        </div>
                       
                        <div className="col-md-6 col-sm-6 col-lg-3 mb-md-30px mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading"><b>OUR TOOLS</b></h4>
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                            <li className="li"><a className="single-link" href="../our_tools/springboard">SpringBoard</a></li>
                                            <li className="li"><a className="single-link" href="../our_tools/data_collect">Data Collect</a></li>
                                            <li className="li"><a className="single-link" href="../our_tools/OCR_Tool">OCR Tool</a></li>
                                            <li className="li"><a className="single-link" href="../our_tools/integrated_workbook">Integrated Workbook</a></li>
                                            <li className="li"><a className="single-link" href="../our_tools/automation_initiator">Automation Initiator</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6 col-lg-3 col-sm-6 mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading"><b>BENEFITS</b></h4>
                               
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                           <li className="li"><a className="single-link" href="../improved-Taxpayer-experience">TaxPayer Experience</a></li>
                                            <li className="li"><a className="single-link" href="../TaxPreparer_Experience">Tax Preparer Experience</a></li>
                                            <li className="li"><a className="single-link" href="../Improves_productivity">Improves productivity</a></li>
                                            <li className="li"><a className="single-link" href="../leading-edge-technology">Leading-edge technology</a></li>
                                            <li className="li"><a className="single-link" href="../Cost-effective">Cost effective</a></li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                       
                        <div className="col-md-6 col-lg-3">
                            <div className="single-wedge">
                                <h4 className="footer-herading"><b>QUICK LINKS</b></h4>
                               
                                <div className="footer-links">
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                        <li className="li"><a className="single-link" href="../faq">FAQ</a></li>
                                            <li className="li"><a className="single-link" href="../blogs">Blogs</a></li>
                                            {/* <li className="li"><a className="single-link" href="../about_us">About Us</a></li> */}
                                            <li className="li"><a className="single-link" href="../contact_us">Contact Us</a></li>
                                            <li className="li"><a className="single-link" href="../privacy_policy">Privacy Policy</a></li>
                                            <li className="li"><a className="single-link" href="../cookie_policy">Cookie Policy</a></li>
                                            {/* <li className="li"><a className="single-link" href="../login">Login</a></li> */}
                                        </ul>
                                    </div>
                                </div>
                                    
                                </div>
                            </div>
                           
                        </div>
                      
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container border-top">
              
                <div className="col-12" style={{paddingTop:"10px",fontSize:"13px"}}>
                       
                        </div>
                        <br></br>
                    <div className="row flex-sm-row-reverse">
                        <div className="col-md-6 text-right">
                            <div className="payment-link">
                            <ul className="link-follow">
                               
                               {/* <li className="li">
                                   <a target='_blank' className="paypal icon-social-facebook m-0" title="Facebook" href="#"></a>
                               </li>
                               <li className="li">
                                   <a target='_blank' className="tumblr icon-social-instagram" title="Instagram" href="#"></a>
                               </li>
                               <li className="li">
                                   <a target='_blank' className="twitter icon-social-twitter" title="Twitter" href="#"></a>
                               </li> */}
                               <li className="li">
                                   <a target='_blank' className="pinterest icon-social-youtube" title="Youtube" href="https://www.youtube.com/channel/UCvjp5RTgUGWyi9hPYVHSfvA"></a>
                               </li>
                               <li className="li">
                                   <a target='_blank' className="icon-social-linkedin" title="Linkedin" href="https://www.linkedin.com/company/1040-prep/"></a>
                               </li>
                               {/* <li className="li">
                                   <a target='_blank' className="icon-social-spotify" title="Spotify" href="#"></a>
                               </li> */}
                              
                           </ul>
                            </div>
                        </div>
                        <div className="col-md-6 text-left">
                            <p className="copy-text">Copyright@2022  1040-PREP.</p>
                        </div>
                    
                      
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Cart_Canvas/>
        </div>);
    }
}
export default Footer;
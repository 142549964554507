import React from 'react';
class Footer extends React.Component{
    render(){
        return( <footer className="main-footer">
          <div className="container">
        <div className="float-right d-none d-sm-inline">
          Anything you want
        </div>
       
        <strong>Copyright &copy; 1040-PREP <a href="#"></a>.</strong> All rights reserved.
        </div>
      </footer>);
    }
}
export default Footer;
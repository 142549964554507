import $ from 'jquery';
//import 'jquery-ui';
import { Link } from 'react-router-dom';
import React,{ useState } from 'react';

 const appUrl="https://cms.1040-prep.com/controller/pgControl.php?pm=";
 const appUrtPath="https://cms.1040-prep.com/1040_prep_upd/";
// const appUrl="https://cms.shritrinity.com/controller/pgControl.php?pm=";
// const appUrtPath="https://cms.shritrinity.com/shritri_loan/";
//const appUrtPath="https://fj0yms50.cdn.imgeng.in/mbdh/"

export const appGetUrlPath=()=>{
  return appUrtPath;
}
export const appGetMainUrl=()=>{
  return appUrl;
}
export const CallMapMethod_CBack=(obCls,frmValues,callback)=>{
  var curl_type=frmValues['curl_type'];
 
  //alert("check url == "+curl_type);
  var divRes="";
  var getCallFunc=""
  if(frmValues['res_name'])
  {
    divRes=frmValues['res_name'];
  }
  if(frmValues['res_func'])
  {
    getCallFunc=frmValues['res_func'];
  }
      var ss=frmValues;
      //var xs = "{objs:" + JSON.stringify(ss) + "}";
     //alert(xs);
       $.ajax({
          crossDomain:true,
              type: "POST",
              url: appUrl+curl_type,
              data:ss,
           success: function (data) {
           // alert(data);   
           // $("#pgComp").html(data);
           // return;
            //ajaxindicatorstop();
               // return data;
               if(divRes!="")
               {
                   if ($("#" + divRes).length!=0)
                $("#"+divRes).html(data);
               }
               if(getCallFunc!="")
               {
                var checkData=$($($.parseHTML(data)).filter("#resP")).text();
                var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
                //  eval(getCallFunc);
               // alert(checkMsg);
               return callback(checkData,checkMsg);
                  }
                 
              }
          });
}
export const CallCMSMapMethod=(obCls,frmValues)=>{
   
    var curl_type=frmValues['curl_type'];
 
     //alert("check url == "+curl_type);
     var divRes="";
     var getCallFunc=""
     if(frmValues['res_name'])
     {
       divRes=frmValues['res_name'];
     }
     if(frmValues['res_func'])
     {
       getCallFunc=frmValues['res_func'];
     }
         var ss=frmValues;
         //var xs = "{objs:" + JSON.stringify(ss) + "}";
        //alert(xs);
          $.ajax({
             crossDomain:true,
                 type: "POST",
                 url: appUrl+curl_type,
                 data:ss,
              success: function (data) {
              // alert(data);   
              // $("#pgComp").html(data);
              // return;
               //ajaxindicatorstop();
                  // return data;
                  if(divRes!="")
                  {
                      if ($("#" + divRes).length!=0)
                   $("#"+divRes).html(data);
                  }
                  if(getCallFunc!="")
                  {
                   var checkData=$($($.parseHTML(data)).filter("#resP")).text();
                   var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
                   //  eval(getCallFunc);
                  // alert(checkMsg);
                  return obCls[getCallFunc](checkData,checkMsg);
                     }
                    
                 }
             });
 }
export const getRoleDetails=(roles,uriPath)=>{
  const listFltr = d => d.col2 === uriPath && d.status==='side';
  var topMenusList = roles.filter(listFltr);
  var listFilter;
  for(var i=0;i<topMenusList.length;i++){
    const childFltr = d => d.parent_id === topMenusList[i]["parent_id"] && d.status === 'side';
    listFilter = roles.filter(childFltr);
  }
  return listFilter;
}
export const getAccessRoleLink=(props)=>{

  
  //alert(JSON.stringify(props));
  let roles=props;
  var getMenu="";
    const listFltr = d => (d.col6 === 'menu' || d.col6 === 'top_menu') && d.parent_id == 0;
    var topMenusList = roles.filter(listFltr);
  //alert(topMenusList.length);
  return(<ul>
    {topMenusList.map((item,index)=>{
       const childFltr = d => d.parent_id === item["id"] && d.col6 !== 'desk'
        var childMenuList = roles.filter(childFltr);
        var getChMenu = "";
        
       if(childMenuList.length===0){
        
        return (<li>
          <Link to={"../"+item["col2"]}>{item["col1"]}</Link>
        </li>)
       }else{
        return(<li className="dropdown">
            <Link to={"../"+item["col2"]}>{item["col1"]}</Link>
            <ul className="sub-menu">
              {childMenuList.map((childItem,childIndex)=>{
                const child2Fltr=d => d.parent_id === childItem["id"] && d.col6 === 'menu';
                var child2MenuList=roles.filter(child2Fltr);
                if(child2MenuList.length!==0){
                    return (<li className="dropdown position-static">
                      <Link to={"../"+childItem["col2"]}>{childItem["col1"]}</Link>
                      <ul className="sub-menu sub-menu-2">
                         {child2MenuList.map((child2Item,child2Index)=>{
                          return (
                            <li>
                              <Link to={"../"+child2Item["col2"]}>{child2Item["col1"]}</Link>
                            </li>
                          )
                         })}
                      </ul>
                    </li>)
                }else{
                 return (<li>
                  <Link to={"../"+childItem["col2"]}>{childItem["col1"]}</Link>
                 </li>) 
                }
                
              })}
            </ul>
        </li>)
       } 
          
    })}
  </ul>)
 
}
export function getAccessRoleLink_FI(props){
  alert(JSON.stringify(props));
  let roles=props;
  var getMenu="";
    const listFltr = d => (d.col6 === 'menu' || d.col6 === 'top_menu') && d.parent_id == 0;
    var topMenusList = roles.filter(listFltr);
  
    {topMenusList.map((item,index)=>{
       const childFltr = d => d.parent_id === item["id"] && d.col6 !== 'desk'
        var childMenuList = roles.filter(childFltr);
        var getChMenu = "";
        
       if(childMenuList.length===0){
        return (<li>
          <Link to={"../"+item["col2"]}>{item["col1"]}</Link>
        </li>)
       }else{
        return(<li className="dropdown">
            <Link to={"../"+item["col2"]}>{item["col1"]}</Link>
            <ul className="sub-menu">
              {childMenuList.map((childItem,childIndex)=>{
                const child2Fltr=d => d.parent_id === childItem["id"] && d.col6 === 'menu';
                var child2MenuList=roles.filter(child2Fltr);
                if(child2MenuList.length!==0){
                    return (<li className="dropdown position-static">
                      <Link to={"../"+childItem["col2"]}>{childItem["col1"]}</Link>
                      <ul className="sub-menu sub-menu-2">
                         {child2MenuList.map((child2Item,child2Index)=>{
                          return (
                            <li>
                              <Link to={"../"+child2Item["col2"]}>{child2Item["col1"]}</Link>
                            </li>
                          )
                         })}
                      </ul>
                    </li>)
                }else{
                 return (<li>
                  <Link to={"../"+childItem["col2"]}>{childItem["col1"]}</Link>
                 </li>) 
                }
                
              })}
            </ul>
        </li>)
       } 
          
    })}
  
}
export const getAccessRolesNew=(roles)=>{

    var getMenu="";
    const listFltr = d => (d.col6 === 'menu' || d.col6 === 'top_menu') && d.parent_id == 0;
    var topMenusList = roles.filter(listFltr);
    //alert(topMenusList.length);
    for (var i = 0; i < topMenusList.length; i++) {
        const childFltr = d => d.parent_id === topMenusList[i]["id"] && d.col6 !== 'desk'
        var childMenuList = roles.filter(childFltr);
        var getChMenu = "";
        //alert(childMenuList.length + " = " + topMenusList[i]["id"]);
        if (childMenuList.length === 0) {
            getMenu += " <li>" +
                "<a href = \"/" + topMenusList[i]["col2"] + "\">" + topMenusList[i]["col1"] + "</a>" +
                "</li>";
        } else {
            getMenu += "<li class=\"dropdown\">" +
            "<a href=\"#\">"+topMenusList[i]["col1"]+" <i class=\"ion-ios-arrow-down\"></i></a>";
               
            getChMenu = "<ul class=\"sub-menu\">";
            for (var ch = 0; ch < childMenuList.length; ch++) {
                const child2Fltr=d => d.parent_id === childMenuList[ch]["id"] && d.col6 === 'menu';
                var child2MenuList=roles.filter(child2Fltr);
                
                if(child2MenuList.length!=0){
                  getChMenu += "<li class=\"dropdown position-static\"><a href=\"" + childMenuList[ch]["col2"] + "\">" + childMenuList[ch]["col1"] + "<i class=\"ion-ios-arrow-right\"></i></a>";
                  getChMenu+="<ul class=\"sub-menu sub-menu-2\">";
                }else{
                  getChMenu += "<li><a href=\"/" + childMenuList[ch]["col2"] + "\">" + childMenuList[ch]["col1"] + "</a></li>";
                }
                for(var k=0;k<child2MenuList.length;k++){
                  getChMenu += "<li><a href=\"/" + child2MenuList[k]["col2"] + "\">" + child2MenuList[k]["col1"] + "</a></li>";
                }
                if(child2MenuList.length!=0){
                  getChMenu+="</ul></li>";
                }
            }
            getChMenu += "</ul>";
            getMenu += getChMenu + "</li>";
        }
  
        // getMenu += topMenusList[i]["col1"];
    }
    return getMenu;
   
  }
  export default CallCMSMapMethod
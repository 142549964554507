import $ from 'jquery';
import React,{ useState } from 'react';
import {displaySteps,AjaxCallMethod,GetImgCertUser} from '../function_lib/common_lib';

 //import Upload_Doc_Opt_Comp from './upload_doc_opt_comp'; 
import './tax_submit_component.css';

let docHeader;
let applicantList;
let logInObj="";
let clsObject;

let listDocuments;

let listDoc=[];
let listFile=[];

window.jQuery = $;
window.$ = $;
global.jQuery = $;
export function onDocSubmit(frmName){
    GetImgCertUser(clsObject,frmName,"updateTaxRequestData","okSuccUpdate");
}
export function okSuccUpdate(data,msg,msg2){
    //alert(msg2);
    if(data!=="0" && data!==""){
      $(".respSSL_lod").text("Updates Successfully");
      //props.getLoanRequest();
    }
     
}
export function closeMe(clName) {
    //alert("i am in click");
    //$(clName).modal('hide');
    $(clName).hide();
    $(clName).removeClass("show");
  }
 export function showMe(clName,refId="0") {
   $(clName).modal('toggle');
$(clName).modal('show');
    //  $(clName).show();
    //  $(clName).addClass("show");
  }

//for upload doc file
export function uploadFile(data){
    showMe("#modal_upload_doc");
    //$('#myModal').modal('show');
}    
// for view loan doc file {image,pdf,}
      
export function Document_Upload_Withlist_Component(props,{fnViewFile}){
  // alert(JSON.stringify(props));
    //clsObject=this;
    //props=props;
    //   alert("Testing == "+JSON.stringify(props));
      docHeader=[];
      applicantList=[];
      if(props.viewLoadDoc.length>0){
          let docList=props.viewLoadDoc[0].doc_list;
          //alert(JSON.stringify(docList));
          if(props.getType==="questionnair" ){
            applicantList.push("Questionnair");

          //docHeader.push("Basic Details");
          //docHeader.push("KYC");
          docHeader.push("Questionnair");
         

          }else if(props.getType==="documents" || typeof props.getType==="undefined"){
            // as per new logic only this particular category should be workable
            applicantList.push("Documents");

            //docHeader.push("Basic Details");
            //docHeader.push("KYC");
            docHeader.push("Income");
            docHeader.push("Expenses");
            docHeader.push("Other");
          }
          
          
          let checkDocHeaderDisplay=[];
          for(let h=0;h<docList.length;h++){
              let headerName=docList[h]["doc_header"];
              if(!(checkDocHeaderDisplay.includes(headerName))){
                checkDocHeaderDisplay.push(headerName);
              }
        }
        /* remove not required docHeader */
        for(let a=0;a<docHeader.length;a++){
            if(!checkDocHeaderDisplay.includes(docHeader[a])){
                docHeader.splice(a,1);
            }
        }
        listDoc=docList;
         
         
      }
    
        return(<div>
            
        {(props.viewLoadDoc!=="undefined" && props.viewLoadDoc.length>0?<div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">{typeof props.viewLoadDoc[0]["_category"]!=="undefined"?props.viewLoadDoc[0]["_category"]:''}</h5>
              
            </div>
              {/* View docu modal */}
              
              
            {/* End of View Docu Modal */}
            <form id="msform" name="msform" className='msform' enctype="multipart/form-data" method="POST" action="#">
                {(logInObj==="")?<div className='row'>
                    <div className='col-12'>{"Welcome "+props.viewLoadDoc[0]["first_name"]+" "+""+" , Kindly fill requested form to check '"+props.viewLoadDoc[0]["_category"]+"' eligibility"}</div>
                </div>:''}
                <div className='row'>
    <div className='col-md-6'>
        <div id="getReponse" style={{color:"red"}}></div>
        </div>
    <div className='col-md-6'>
    <a type="button" data-toggle="modal"  data-target="#modal_upload_doc"  style={{marginRight:"auto"}} className="button-37 mb-2 btn btn-primary bg-primary text-white">Upload Document</a>
    {/* <a type="button"  onClick={()=>uploadFile(listDocuments)}  style={{marginRight:"auto"}} className="button-37 mb-2 btn btn-primary bg-primary text-white">Upload Document</a>     */}
    {/* <button type="button" onClick={()=>this.onDocSubmit("#msform"+applicant_index)} style={{marginRight:"auto"}} className="button-37 mb-2 bg-primary text-white">Save changes</button> */}
    </div>
    
</div>
            <ul id="progressbar" className="p-0">
                                    
                                    {(applicantList.map((item,index)=>{
                                        if(index==0){
                                            return <li indexed={index} className="active curret_fsFsFix" id={"step"+index}><strong>{item}</strong></li>
                                        }else{
                                            return <li indexed={index} className='curret_fsFsFix'  id={"step"+index}><strong>{item}</strong></li>
                                        }
                                       
                                    }))}
                                        {/* <li class="active" id="account"><strong>Account</strong></li>
                                        <li id="personal"><strong>Personal</strong></li>
                                        <li id="payment"><strong>Image</strong></li>
                                        <li id="confirm"><strong>Finish</strong></li> */}
                                    </ul>
            <div className="modal-body">
            <div className="row">
                      {/* <!-- left column --> */}
                      <div className="col-md-12">
                          <div className="card card-danger">
                                  {/* <input type="hidden" name="local_token" id="local_token" value={logInObj[0]["id"]}/>
                                  <input type="hidden" name="upper_id" id="upper_id" value={logInObj[0]["id"]}/>
                                  <input type="hidden" name="app_user_id" id="app_user_id" value={logInObj[0].app_user_id} /> */}
                                  <div id="chckksdf" className="card-body">
                                  {applicantList.map((applicant_item,applicant_index)=>{
                                    if(applicant_index===0){
                                        listDocuments=props.viewLoadDoc[0].doc_list;
                                    }else if(applicant_index===1){
                                        //alert(JSON.stringify(props.viewLoadDoc[0].doc_list1));
                                        listDocuments=props.viewLoadDoc[0].doc_list1;
                                    }else if(applicant_index===2){
                                        listDocuments=props.viewLoadDoc[0].doc_list2;
                                      }else if(applicant_index===3){
                                        listDocuments=props.viewLoadDoc[0].doc_list3;
                                      }else if(applicant_index===4){
                                        listDocuments=props.viewLoadDoc[0].doc_list4;
                                      }else if(applicant_index===5){
                                        listDocuments=props.viewLoadDoc[0].doc_list5;
                                      }
                                    return (<fieldset className={"fsl field"+applicant_index}>
                                        <form id={"msform"+applicant_index} name={"msform"+applicant_index} enctype="multipart/form-data" method="POST" action="#">
                                        <input type="hidden" name="res_func" id="res_func" value="okSuccUpdate" />
                                  <input type="hidden" name="curl_type" id="curl_type" value="updateLoanRequestData" />
                                  <input type="hidden" name="m_type" id="m_type" value="updateLoanRequestData" />
                                  <input type="hidden" name="type_id" id="type_id" value="2" />
                                  <input type="hidden" name="loan_request_id" id="loan_request_id" value={props.viewLoadDoc[0]._id.$oid}/>
                                  <input type="hidden" name="local_token" id="local_token" value={props.viewLoadDoc[0]._id.$oid}/>
                                  <input type="hidden" name="applicant_index" id="applicant_index" value={applicant_index}/>

                                  
                            
  {(docHeader.map((item,index)=>{
                                    let itemId=item.replace(" ","_");
                                    return(<div class="col-md-12 col-xs-12 panel panel-default no-border single-my-account1">
                                    
                                    <div id={"my-account"+itemId+applicant_index} class="" >
                                <div class="panel-body">
                                {listDocuments.map((docitem,di)=>{
                                               //if(docitem.doc_header===item)
                                               {

                                                if(docitem.type[0]==="text" && docitem.type[0]==="file_upload"){
                                                    return( <div className="row border-bottom">
                                                    <div className="col-lg-6 col-md-6">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">{item+"-"+docitem.title}</label>
                                                                <div className="input-group input-group-sm">
                                                                    <input defaultValue={docitem.doc_value} autoComplete='off' type="text" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={docitem.title} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-3 vcenter-item'>
                                                        {docitem.status}<br></br>
                                                        {docitem.reason_msg}
                                                        </div>
                                                     </div>)
                                                }else if(docitem.type[0]==="radio_button" && docitem.type[0]==="file_upload"){
                                                    //alert(docitem.doc_value);
                                                    let strRadioList=docitem.descr;
                                                    let arrRadioList=strRadioList.split('>');
                                                    //alert(arrRadioList.length);
                                                    
                                                    return(<div className="row border-bottom">
                                                    <div className="col-lg-6 col-md-6">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">{item+"-"+docitem.title}</label>
                                                                <div className="input-group input-group-sm">
                                                                   {arrRadioList.map((itemRadio,indexRadio)=>{
                                                                    return (<div><input checked={docitem.doc_value===itemRadio} id={docitem._id.$oid+"_"+itemRadio} name={docitem._id.$oid} type="radio" value={itemRadio} />
                                                                    <label for={docitem._id.$oid+"_"+itemRadio}>{itemRadio}</label>
                                                                    </div>);
                                                                   })}
                                                                    {/* <input defaultValue={docitem.doc_value} autoComplete='off' type="text" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={docitem.title} /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-3 vcenter-item'>
                                                        {docitem.status}<br></br>
                                                        {docitem.reason_msg}
                                                        </div>
                                                     </div>)
                                                }else if(docitem.type[0]==="dropdown" && docitem.type[0]==="file_upload"){
                                                    let strDropdownList=docitem.descr;
                                                    let arrDropdownList=strDropdownList.split('>');
                                                    //alert(docitem.doc_value);
                                                    
                                                    return(<div className="row border-bottom">
                                                    <div className="col-lg-6 col-md-6">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">{item+"-"+docitem.title}</label>
                                                                <div className="input-group input-group-sm">
                                                                    <select id={docitem._id.$oid} name={docitem._id.$oid} className="form-control">
                                                                       <option value="0">- Select -</option>
                                                                       {arrDropdownList.map((itemdd,indexdd)=>{
                                                                    return (<option selected={docitem.doc_value===itemdd} value={itemdd}>{itemdd}</option>);
                                                                   })}
                                                                    </select>
                                                                   
                                                                    {/* <input defaultValue={docitem.doc_value} autoComplete='off' type="text" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={docitem.title} /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-3 vcenter-item'>
                                                        {docitem.status}<br></br>
                                                        {docitem.reason_msg}
                                                        </div>
                                                     </div>)
                                                }else if(docitem.type[0]==="date" && docitem.type[0]==="file_upload"){
                                                    
                                                    return( <div className="row border-bottom">
                                                    <div className="col-lg-6 col-md-6">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">{item+"-"+docitem.title}</label>
                                                                <div className="input-group input-group-sm">
                                                                    <input defaultValue={docitem.doc_value}  autoComplete='off' type="date" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={docitem.title} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-3 vcenter-item'>
                                                        {docitem.status}<br></br>
                                                        {docitem.reason_msg}
                                                        </div>
                                                     </div>)
                                                }
                                                else if(docitem.type[0]==="file_upload" || docitem.doc_path.trim()!==""){
                                                   let uploadedCheck="";
                                                   let updNoItem=0;
                                                   //alert("i m in doc_path =="+listDocuments.length);
                                                   if(docitem.doc_path!==""){
                                                    //alert("i m in doc path");
                                                       uploadedCheck="<a href=\"javascript:void(0)\">View</a>"
                                                       updNoItem=docitem.doc_path.split(',');
                                                   }
                                                   
                                                return(<div className="row pb-2">
                                                <div className="col-lg-12 col-md-12 border-style1 pb-2 mb-2">
                                                    <div className="parent_tb">
                                                        <div className="line_tb main-menu manu-color-white">
                                                            <div className="left_tb">

                                                            {item+"-"+docitem.title}
                                                            </div>
                                                            <div className="right_tb">
                                                            {uploadedCheck!==""?<ul className="mt-2">
                          <li class="dropdown">
                        <a className="font-sm" href="#"><span className="badge bg-primary">Documents({updNoItem.length}) <i class="ion-ios-arrow-down"></i></span></a>
                        <ul class="sub-menu">
                        <li><a class="dropdown-item" href={"javascript:void(0)"} data-toggle="modal"  data-target="#modal_view_doc" onClick={()=>props.fnViewFile(docitem)}>View Questionnair</a></li>
                        <li><a class="dropdown-item" href={"javascript:void(0)"} data-toggle="modal"  data-target="#modal_view_doc" onClick={()=>props.fnViewFile(docitem,"extract")}>Generate Sheet</a></li>

                          </ul></li></ul>:''}    
                                                            

                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        {docitem.status}{docitem.reason_msg}
                                                    </div>
                                                       
                                                    </div>
                                                   
                                                 </div>)
                                               } 
                                               }
                                        
                                          })}
                                   
                                </div>
                            </div>
                        </div>)
                                  }))}
 
</form>      
    </fieldset>)
                                  })}  
                                      <div className="col-md-6 respSSL_lod red"></div>
                                      <div id="testing2dd2222"></div>
                                  </div>
                          </div>
                      </div>
                  </div>
                  <div className="modal-footer">
           
            </div>
            </div>
           
            </form>
          </div>:'')}
          </div>
         )
    
}
export default Document_Upload_Withlist_Component;
import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import {new_enc_dec_m} from '../function_lib/common_lib';
export const  Comp_User_Dashboard=(props)=>{
 return ( <div className="row mt-4">
    {/* tax questionnare */}
    <div className="col-md-4">
           <div className="card card-widget widget-user-2">
             <div className="widget-user-header bg-danger">
               <div className="widget-user-image">
                <span className="info-box-icon text-red1 bg-info1 elevation-11"><i className="fa fa-newspaper-o fa-3x" aria-hidden="true"></i></span>
               </div>
               <h3 className="widget-user-username"></h3>
               <h5 className="widget-user-desc">{"Tax Questionnaire"}</h5>
             </div>
             <div className="card-footer p-0">
               <ul className="nav flex-column">
               {props.listYearMaster.map((item,index)=>{
  
  return(<li class="nav-item">
  <a className="nav-link gion" href={"../quest_overview?rf="+new_enc_dec_m("encrypt",item.year_master_id)+"&dis="+new_enc_dec_m("encrypt",item.display_year)}>
  <i className="fa fa-question-circle-o"></i>{item.display_year}
  </a>
  {/* <Link className="aaaa" to={"../quest_overview?rf="+new_enc_dec_m("encrypt",item.year_master_id)+"&dis="+new_enc_dec_m("encrypt",item.display_year)}>{item.display_year}</Link> */}
  </li>);
})
}
               </ul>
             </div>
           </div>
         </div>
         {/* end of tax questionnaire */}
         {/* ===================================== */}
{/* tax document upload */}
<div className="col-md-4">
           <div className="card card-widget widget-user-2">
             <div className="widget-user-header bg-info">
               <div className="widget-user-image">
                <span className="info-box-icon text-red1 bg-info1 elevation-11"><i className="fa fa-file-text-o fa-3x" aria-hidden="true"></i></span>
               </div>
               <h3 className="widget-user-username"></h3>
               <h5 className="widget-user-desc">{"Tax Documents Upload"}</h5>
             </div>
             <div className="card-footer p-0">
               <ul className="nav flex-column">
               {props.listYearMaster.map((item,index)=>{
  
  return(<li class="nav-item">
  <a className="nav-link gion" href={"../document_list?rf="+new_enc_dec_m("encrypt",item.year_master_id)+"&dis="+new_enc_dec_m("encrypt",item.display_year)}>
  <i className="fa fa-question-circle-o"></i>{item.display_year}
  </a>
  {/* <Link className="aaaa" to={"../quest_overview?rf="+new_enc_dec_m("encrypt",item.year_master_id)+"&dis="+new_enc_dec_m("encrypt",item.display_year)}>{item.display_year}</Link> */}
  </li>);
})
}
               </ul>
             </div>
           </div>
         </div>
         {/* end of tax document upload */}         

{/* second item */}
<div className="col-md-4 pb-20px pt-20px">
<div class="card" style={{width: "18rem;"}}>

<div class="card-body">
<h5 class="card-title">Tax Return Status</h5>
<p class="card-text">Year wise status/records</p>
</div>
<ul class="list-group list-group-flush">
<li class="list-group-item">An item</li>
<li class="list-group-item">A second item</li>
<li class="list-group-item">A third item</li>
</ul>
</div>

</div>
{/* third item */}
 <div className="col-md-4 pb-20px pt-20px">
<div class="card" style={{width: "18rem;"}}>

<div class="card-body">
<h5 class="card-title">Action / TODO</h5>
<p class="card-text">Year wise status/records</p>
</div>
<ul class="list-group list-group-flush">
<li class="list-group-item">An item</li>
<li class="list-group-item">A second item</li>
<li class="list-group-item">A third item</li>
</ul>
</div>

</div>
{/* 4th Item */}
<div className="col-md-4 pb-20px pt-20px">
<div class="card" style={{width: "18rem;"}}>

<div class="card-body">
<h5 class="card-title">Tax Documents</h5>
<p class="card-text">Year wise status/records</p>
</div>
<ul class="list-group list-group-flush">
{
props.listYearMaster.map((item,index)=>{
return(<li class="list-group-item"><a href={"../document_list?rf="+new_enc_dec_m("encrypt",item.year_master_id)+"&dis="+new_enc_dec_m("encrypt",item.display_year)}>{item.display_year}</a></li>);
})

}
</ul>
</div>

</div>
{/* 5th Item */}
<div className="col-md-4 pb-20px pt-20px">
<div class="card" style={{width: "18rem;"}}>

<div class="card-body">
<h5 class="card-title">Prior Year Return</h5>
<p class="card-text">Year wise status/records</p>
</div>
<ul class="list-group list-group-flush">
<li class="list-group-item">An item</li>
<li class="list-group-item">A second item</li>
<li class="list-group-item">A third item</li>
</ul>
</div>

</div>
{/* 6th Item */}
<div className="col-md-4 pb-20px pt-20px">
<div class="card" style={{width: "18rem;"}}>

<div class="card-body">
<h5 class="card-title">Your Tax Consultant</h5>
<p class="card-text">Your Consultant Details</p>
</div>
{typeof props.taxConsultant!=="undefined"?props.taxConsultant.map((item,index)=>{
return <ul class="list-group list-group-flush">

<li class="list-group-item">Name :{item.first_name}</li>
<li class="list-group-item">Email :{item.email_id}</li>
{/* <li class="list-group-item">A third item</li> */}
</ul>

}):''}

</div>

</div>

</div>)
}
export default Comp_User_Dashboard;
import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,BtnSetColor,AjaxCallMethod,CallMapMethod_CBack} from '../function_lib/common_lib';
// import listReactFiles from 'list-react-files'
import Header from './header';
import Tax_Header from '../user_components/tax_header';
import Side_Bar from './side_bar';
import Footer from './footer';
import LogoImg from '../assets/images/my_logo.jpg';
import defaultPic from '../assets/images/9.png';



let loginUser="";
let userObject="";
let logInObj;
let clsCpaMgmt;
class Cpa_Mgmt extends React.Component{
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          //UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= JSON.parse(localStorage.getItem("userDS"));
          logInObj=userObject;
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          //window.location.href="./Dashboard";
        }
        clsCpaMgmt=this;
      }
      getCPAUser(){
        //alert("i am in dsa");
          var map=[];
          map.push({name:"curl_type",value:"getTeamEmployee"});
          map.push({name:"res_func",value:"callBackParent"});
          map.push({name:"local_token",value:logInObj[0].app_user_id});
          //map.push({name:"upper_id",value:logInObj[0].id});
          map.push({name:"type_id",value:"3"});
          map["res_func"]="callBackParent";
          map["curl_type"]="getTeamEmployee";
          CallMapMethod_CBack(this,map,function(data,msg){
            //alert(msg+data);
            if(data!=="0" && data!==""){
                let listEmp=JSON.parse(data);
                var strData="";
                //alert(listEmp.length);
                for(var i=0;i<listEmp.length;i++){
                    $(".total_emp").text((i+1));
                 strData+="<tr>"+
                        "<td>"+(i+1)+"</td>"+
                        "<td>"+listEmp[i]["first_name"]+listEmp[i]["last_name"]+
                        "<td>"+listEmp[i]["contact_no"]+"</td>"+
                        "<td>"+listEmp[i]["email_id"]+"</td>"+
                        "<td>"+listEmp[i]["password"]+"</td>"+
                        "<td>"+listEmp[i]["status"]+"</td>"+
                        "</tr>";
                }
                $("#tbData").html(strData);
            }
          });
      }

      okSucc(data,msg){
        if(data!=="0"){
            $("#frmCreate").find("input[type=text]").val("");
            //$("#frmCreate").find()
            $(".respSSL").html(msg);
            clsCpaMgmt.getCPAUser();
            //this.getDocumentHeaders();
        }else{
            $(".respSSL").html(msg);
        }
      
    }
    
      componentDidMount(){
        this.getCPAUser();
         // alert(userObject[0].type);
         AjaxCallMethod(this,"okSuccCNOT");

          const script = document.createElement("script");
          script.src = "/main.js";
          script.async = true;
          //script.onload = () => clsObject.scriptLoaded();
        
          document.body.appendChild(script);
         
          //listReactFiles("pub_dir/").then(files => console.log(files))
      }
   
    render(){
        return(<div className="bg-gray">
            <Header/>
             {/* dashboard tax options  */}
            {/* <Tax_Header/> */}
            <div className="container">
            <div className="row">
                <div className="col-md-10 pb-20px pt-20px">
            <div class="card card-primary card-outline cover-height">
                <div className="card-header">
                <h5 class="card-title">CPA List</h5>
                </div>
              <div class="card-body">
              <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap table-responsive">
                  <thead>
                    <tr class="border">
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Contact No</th>
                        <th>Email</th>
                        <th>Pwd</th>
                       <th>Status</th> 
                      {/* <th></th> */}
                    </tr>
                  </thead>
                  <tbody id="tbData">
                    
                  </tbody>
                </table>

               
              </div>
            </div>
            </div>
            {/* side card */}
              <div className="col-md-2 pb-20px pt-20px">
              <div class="card" style={{width: "100%;"}}>
  
  <div class="card-body">
  <h6 class="card-title1 pb-5"><i class="fa fa-arrow-circle-right"></i> <b>CPA Summary</b></h6>
     
    <p class="card-text"><span class="float-right mt-2 badge bg-primary pointer" data-toggle="modal" data-target="#divEmployee">Add New CPA</span></p>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">Total CPA-<span className="badge bg-primary total_emp"></span></li>
  </ul>
</div>

              </div>

             

            </div>
           
  </div>
     
        <Footer/>
{/* add CPA User */}
<div className="modal fade bd-example-modal-xl"  id="divEmployee" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">CPA User</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmCreate" name="frmCreate">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="registration" />
                            <input type="hidden" name="m_type" id="m_type" value="registration" />
                            <input type="hidden" name="role_id" id="role_id" value="3" />
                            <input type="hidden" name="app_user_id" id="app_user_id" value="" />
                            <input type="hidden" name="upper_id" id="upper_id" />
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">First Name *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="first_name" name="first_name" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Last Name *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="last_name" name="last_name" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Contact No *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="contact_no" name="contact_no" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">EmailId</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="email_id" name="email_id" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Password</label>
                                            <div className="input-group input-group-sm">
                                                <input type="password" id="password" name="password" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>

      </div>)
    }
}
export default Cpa_Mgmt
import $ from 'jquery'
import React from 'react'
import { appGetUrlPath, appGetMainUrl } from '../function_lib/common_cms'
import './comp_blog_land.css'
let appPathCMS = ''
let clsComoBlogLand = ''
let ifIframe = ''
let ifIframInit = false
let appPathURL = ''
class Comp_Job_Land extends React.Component {
  constructor (props) {
    super(props)
    appPathCMS = appGetUrlPath()
    appPathURL = appGetMainUrl()
  }

  httpGet (theUrl) {
    ifIframInit = true
    //alert(theUrl);
    var xmlHttp = new XMLHttpRequest()
    xmlHttp.open(
      'GET',
      appPathURL + 'getContentPgData' + '?pg_name=' + theUrl,
      true
    ) // false for synchronous request
    xmlHttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        //alert("i m done xml");
        //alert(xmlHttp.responseText);
        ifIframe = xmlHttp.responseText
        //alert(ifIframe);
        // clsComoBlogLand.onLoadIframe(ifIframe);
        // Typical action to be performed when the document is ready:
        //document.getElementById("blog_landing").innerHTML = xmlHttp.responseText;
      }
    }
    xmlHttp.send()
    // alert(xmlHttp.responseText);
    // return xmlHttp.responseText;
  }
  componentWillReceiveProps (props) {

    this.props = props
    //alert(JSON.stringify(props));
    if (
      this.props.blogData &&
      this.props.blogData['col5'].indexOf('.html') !== -1
    ) {
      //alert(ifIframe);
      if (!ifIframInit) {
        //alert(this.props.blogData["col5"]);
        ifIframe = this.httpGet(this.props.blogData['col5'])
      }
      if (ifIframe) {
      }
    }
    //alert("child props"+JSON.stringify(this.props.blogData));
    //alert(this.props.blogData["col4"]);
    //this.forceUpdateHandler();
    // Adjusting the iframe height onload event
    let _topTitle = this.props.blogData['col1']
    let _topKeywords = this.props.blogData['col9']
    let _topDescription = this.props.blogData['col9']
    document.title = _topTitle
    document
      .querySelector('meta[name="title"]')
      .setAttribute('content', _topTitle)
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', _topDescription)
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute('content', _topKeywords)
    //alert(this.props.blogData["col2"]);
    $('#blog_landing').html(
      this.props.blogData['col2'] + this.props.blogData['col3']
    )
  }
  componentDidMount () {
    clsComoBlogLand = this

    // $('#myIframe').bind('DOMSubtreeModified', function () {

    //     var links = document.getElementById("blog_landing").getElementsByTagName("a");
    //     var len = links.length;
    //     alert("i am in change"+$("#blog_landing").html());
    //   });
  }
  componentDidUpdate () {
    //document.getElementById("blog_landing").innerHTML='<object type="text/html" data="'+appPathCMS+this.props.blogData["col5"]+'" ></object>';
    var links = document
      .getElementById('blog_landing')
      .getElementsByTagName('a')
    var len = links.length
    //alert(len);
    for (var i = 0; i < len; i++) {
      links[i].target = '_blank'
      //alert("check");
    }
    if (ifIframe !== '') {
      document.getElementById('blog_landing').innerHTML = ifIframe
      this.onLoadIframe()
    }
    $('#leading_blog').find('*').removeAttr('style')
    //alert("component updated");
    // var testingcount= window.frames["myIframe"].getElementsByTagName("a");
    // alert("tt="+testingcount.length);
  }
  onLoadIframe (srcs) {
    // alert("i am in click");
    // document.getElementById("blog_landing").innerHTML=srcs;
    // return;
    //document.getElementById("myIframe").src="#";
    var links = document
      .getElementById('blog_landing')
      .getElementsByTagName('a')

    var len = links.length
    //alert("loaded"+len);
    for (var i = 0; i < len; i++) {
      links[i].target = '_blank'
      //alert("check");
    }
  }
  render () {
    return (
      <div>
        <div class='container11'>
          <div class='container-inner11'>
            <div class='row12'>
              <div class='col-12' style={{position:"relative"}} align='center1'>
                <div>
                  <img
                    class='img-responsive  adjust-layouts-img w-100'
                    src={appPathCMS + this.props.blogData['bg_img']}
                    style={{ width: '100%' }}
                  />
                </div>

                <div
                  
                  class='container-middle privacy_content vertical-center-noabs_absolute section_2'
                >
                  
                  <div class='mob_lenght'><h2 class='title'>{this.props.blogData['col1']}</h2></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
        <div className='row'>
          <div className='col-lg-3 pt-70px'>
            <div class='left-sidebar  bg-gray shop-sidebar-wrap'>
              <div class='sidebar-widget '>
                <div
                  style={{ padding: '20px 15px 0', textAlign: 'center' }}
                  class='search-widget bg-blue txt-white'
                >
                  <span>Job Details</span>
                </div>
                <div class='category-post'>
                  <p>
                    Job Code:<b>{this.props.blogData['col19']}</b>
                  </p>
                  <p>
                    Job Title:<b>{this.props.blogData['col1']}</b>
                  </p>
                  <p>
                    Location:<b>{this.props.blogData['col018']}</b>
                  </p>
                  <p>
                    Work Experience:<b>sdfsdfsdf</b>
                  </p>
                  <p>
                    Qualification:<b>sdfsdfsdf</b>
                  </p>
                  <p>
                    Prefered:<b>Industry</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-9'>
            <div className='blog-posts pt-70px'>
              <div
                id='leading_blog'
                className='single-blog-post  blog-grid-post'
              >
                <div className='blog-post-content-inner mt-6'>
                  <div className='blog-athor-date'>
                    {/* <a className="blog-date" href="#">14 November</a> */}
                  </div>

                  <p id='blog_landing'></p>
                </div>
              </div>
            </div>
            {/* Buttons */}
            <div class='border-box txt-white privacy_policy_main_area pb-70px pt-70px'>
              <div class='container11'>
                <div class='container-inner'>
                  <div class='row'>
                    <div class='col-md-6'>
                      <h5>Ready to Get Started</h5>
                      lorem ipsum ts hello testing of register and working
                    </div>
                    <div class='col-md-3 text-right'>
                      <button className='add-cart btn_sim btn-secondary btn-hover-primary ml-4'>
                        Share
                      </button>
                    </div>
                    <div class='col-md-3 text-right'>
                      <a href='../apply_jobs'>
                        <button className='add-cart btn_sim btn-secondary btn-hover-primary ml-4'>
                          Apply Now
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End of Buttons */}
          </div>
        </div>
        </div>
      </div>
    )
  }
}
export default Comp_Job_Land

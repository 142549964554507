import  $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,RespBkMthdMapp,AjaxCallMethod,GetImgCertUser} from '../function_lib/common_lib';
import {CallCMSMapMethod} from '../function_lib/common_cms';
import Front_Header from './front_header';
import Footer from './footer';
import Cart_Canvas from '../sample_comp/cart_canvas';
import Product_Slider from '../sample_comp/product_slider';
import Product_Listing from '../sample_comp/product_listing';

import AOS from 'aos';
/* components */
import Top_Banner from '../sample_comp/top_banner';
import Features_Slider  from '../sample_comp/features_slider';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import Left_Img_Txt_Desc from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';

let loginUser="";
let userObject="";
let clsObject="";
let loc="";
let products="";
let sliderProducts="";

let categoryParam="";

class Product_List extends React.Component{
    constructor(props){
        super(props);
        loc=props.location["pathname"].substr(1);
        //alert(loc);
        //alert(JSON.stringify(this.props.match.params));
        categoryParam= this.props.match.params.name;
        // alert(categoryParam);
        if(localStorage.getItem("userData")!=null){
          //alert(localStorage.getItem("userData"));
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          if(userObject){
            //loginUser=userObject[0].person_name;
          }
          
          //window.location.href="./Dashboard";
        }
      }
    getFeatureProducts(){
      let feMapCat=[];
      feMapCat.push({name:"curl_type",value:"getProducts"});
      feMapCat.push({name:"res_func",value:"callBackProList_Featu"});
      feMapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
      feMapCat.push({name:"p1",value:"FEATURED PRODUCT"});
      feMapCat.push({name:"p2",value:"0"});
     // mapCat.push({name:"emp_id",value:userObject[0].id});
     feMapCat["res_func"]="callBackProList_Featu";
     feMapCat["curl_type"]="getProducts";
      CallMapMethod(clsObject,feMapCat);

    }  
    componentDidMount(){
        clsObject=this;
  var mapCat=[];
  if(typeof categoryParam==='undefined'){
     categoryParam="0";
  }
//   alert(categoryParam);
  mapCat.push({name:"curl_type",value:"getProducts"});
  mapCat.push({name:"res_func",value:"callBackProList"});
  mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
  mapCat.push({name:"p1",value:categoryParam});
  mapCat.push({name:"p2",value:"0"});
 // mapCat.push({name:"emp_id",value:userObject[0].id});
  mapCat["res_func"]="callBackProList";
  mapCat["curl_type"]="getProducts";
  CallMapMethod(clsObject,mapCat);
  //alert("i m in product_list");
    }
  
    callBackpgData(data,msg){
        //alert("testing of2 "+data);
        //$("#pgComp").html(data);
        var listObj= JSON.parse("{\"access\":"+data+"}");
        //alert("successfully parse");
        var mainHt="";
        if(loc==="home"){
           // mainHt+=Video_Banner_Comp();
        }
        var injextI=0;
        var injCondition=false;
      
        for(var _dlist=0;_dlist<50;_dlist++){
         
            if(listObj.access[_dlist]){
            
                if(listObj.access[_dlist].top_banner){
                   // alert("I am in conditio");
                   injCondition=true;
                   mainHt+=Top_Banner(listObj.access[_dlist].top_banner);
                }
                else if(listObj.access[_dlist].home_banner){
                   // alert("i am in cond..");
                   injCondition=true;
                    mainHt+=Home_Banner(listObj.access[_dlist].home_banner);
                }else if(listObj.access[_dlist].dual_text_card){
                    injCondition=true;
                    //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
                   // mainHt+=Dual_Banner(listObj.access[_dlist].dual_text_card);
                }else if(listObj.access[_dlist].single_img_text){
                    injCondition=true;
                    mainHt+=Single_Img_Msg(listObj.access[_dlist].single_img_text);
                }else if(listObj.access[_dlist].img_text_desc_img_left){
                    injCondition=true;
                    mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_left);
                }else if(listObj.access[_dlist].text_description){
                    injCondition=true;
                    //alert("i am in text");
                    //alert(Txt_Description(listObj.access[_dlist].text_description));
                    mainHt+=Txt_Description(listObj.access[_dlist].text_description);
                }else if(listObj.access[_dlist].list_slider){
                    injCondition=true;
                    mainHt+=Article_Blogs(listObj.access[_dlist].list_slider);
                }else if(listObj.access[_dlist].top_info_slider){
                    injCondition=true;
                    //alert("i am in topindo");
                    $("#top_msg_bar").html(listObj.access[_dlist].top_info_slider[0]["col2"]);
                }
                if(injCondition){
                    injextI++;
                }
                
                if(injextI==1){
                   
                    if(loc==="home"){
                        mainHt+=Features_Slider();
                        //alert(products);
                        mainHt+=products;
                    }
               }
            }
    
          
            //$("#pgComp").innerHtml=mainHt;
        }
        if(listObj.access.length==0 || listObj.access.length!==0){
            mainHt+=products;
            mainHt+=sliderProducts;
        }
        //alert(mainHt);
        //mainHt+=Product_Hori_Slider();
        $("#pgComp").html(mainHt);
        RespBkMthdMapp(clsObject,"_addToCart","setCartBk");
        AOS.init();
        AOS.init({
          once: true
        });
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);
          //this.forceUpdateHandler();
      
    }
    setCartBk(refId){
        // alert(refId);
         this.setState(state =>({viewData:refId}));
         this.forceUpdate();
         $("#offcanvas-cart").addClass("offcanvas-open");
       }
       callBackProList_Featu(data,msg){
         //alert(data);
         if(data!=="0"){
          var resPs= Product_Slider(data);
          //var resPs=Product_Listing(data);
          //alert(resPs);
          sliderProducts+=resPs;
         //$("#pgComp").append(resPs);
         }
      var pgMap=[];
      pgMap.push({name:"curl_type",value:"getPgData"});
      pgMap.push({name:"res_func",value:"callBackpgData"});
      pgMap.push({name:"loc",value:loc});
     // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
      //mapCat.push({name:"emp_id",value:userObject[0].id});
      pgMap["res_func"]="callBackpgData";
      pgMap["curl_type"]="getPgData";
      CallCMSMapMethod(clsObject,pgMap);
    }   
    callBackProList(data,msg){
    //alert("testing=="+data);
      if(data!=="0"){
       //var resPs= Product_Slider(data);
       var resPs=Product_Listing(data);
       //alert(resPs);
       products+=resPs;
      //$("#pgComp").append(resPs);
      }
      this.getFeatureProducts();
     
    }
    render(){
        return(<div>
             <Front_Header loc={loc}/>
             <div className="breadcrumb-area product_breadcrumb">
<div className="container">
    <div className="row">
        <div className="col-12">
            <div className="row breadcrumb_box  align-items-center">
                <div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-left">
                    <h2 className="breadcrumb-title">Shop</h2>
                </div>
                <div className="col-lg-6  col-md-6 col-sm-12">
                    {/* <!-- breadcrumb-list start --> */}
                    <ul className="breadcrumb-list text-center text-md-right product_linkssh">
                        <li className="breadcrumb-item"><a href="../">Home</a></li>
                        <li className="breadcrumb-item"><a href="../products">Products</a></li>
                        {(typeof categoryParam!=="undefined")?<li className="breadcrumb-item active"><a href={"../products/"+categoryParam}>{categoryParam}</a></li>:''}
                        
                    </ul>
                    {/* <!-- breadcrumb-list end --> */}
                </div>
            </div>
        </div>
    </div>
</div>
</div>
             <div id="pgComp">
           </div>
     <Footer/>
        </div>);
    }
}
export default Product_List; 
import  $ from 'jquery';
import React from 'react';
import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";
import ReactDOMServer from 'react-dom/server';
import UserToken from '../function_lib/userToken';
import {getAccessRolesNew,CallCMSMapMethod,getAccessRoleLink} from '../function_lib/common_cms';
import {RespBkMthdMapp,setKeyUpMapp,CallMapMethod} from '../function_lib/common_lib';
import {CityList,tmpAutoList} from '../function_lib/city_india';
import './front_header.css';
import logoImg from '../assets/images/logo/logo.png';

let listRoles;
let loginUser;
let userObject;

let logInObj;
let userOpt;
let clsObject;
let personName;


let _topTitle="";
let _topKeywords="";
let _topDescription="";
let loc="";
class Front_Header extends React.Component{
    logout(rf){
        localStorage.clear();
        window.location.href="./";
      }
      forceUpdateHandler(){
        this.forceUpdate();
      };
    
      componentDidUpdate(props){
          //alert(JSON.stringify(this.props));
          if(typeof this.props.checkFdata!=="undefined" && this.props.checkFdata!==null && this.props.checkFdata!==""){
            //alert(JSON.stringify());
            let keyToken="";
            Object.keys(this.props.checkFdata).forEach(function(key) {
               keyToken=key;
                // var value = this.props.checkFdata[1][key];
            
            });
            if(_topTitle===""){
                _topTitle=this.props.checkFdata[keyToken][0]["top_title"];
                _topKeywords=this.props.checkFdata[keyToken][0]["top_keyword"];
                _topDescription=this.props.checkFdata[keyToken][0]["top_desc"];
                document.title=_topTitle;
             document.querySelector('meta[name="title"]').setAttribute("content", _topTitle);
             document.querySelector('meta[name="description"]').setAttribute("content", _topDescription);
             document.querySelector('meta[name="keywords"]').setAttribute("content", _topKeywords);
            }
           //alert(JSON.stringify(this.props.checkFdata[1][keyToken]));
          }
          //alert("now updates");
      }
    constructor(props){
       super(props);
      loc=this.props.loc;
      
     this.state={stRoles:[]}
       if(localStorage.getItem("userDS")!=null){
        logInObj=JSON.parse(localStorage.getItem("userDS"));
        personName=logInObj[0]["first_name"];
        if(personName.trim()===""){
           personName="My Account";
        }
      }
      this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    }
    gb_search(ref){
        if(ref!==""){
         var map=[];
         map.push({name:"curl_type",value:"getSearchAuto"});
     map.push({name:"res_func",value:"callBackNg"});
     map.push({name:"sec",value:"27KeHNL8l225KpBePgi8Hg=="});
     map.push({name:"loc_ser",value:ref})
     map["res_func"]="callBackNg";
     map["curl_type"]="getSearchAuto";
     CallCMSMapMethod(this,map);
        }else{
         //$("#gb_item_search").html(""); 
        }
     }
     callBackNg(data,msg){
        //alert(data);
        //return;
        if(data.trim()!=="0"){
            var listVal= JSON.parse(data);
            //alert(listVal.length);
            var strList="";
            $("#txtSearchHead").autocomplete({
               source:listVal,
               select:function(event,ui){
                   event.preventDefault();
                   if(ui.item.menu==="Blogs"){
                       window.location.href="../blog/"+ui.item.label.replace(/\s/g, '_')+"?rf="+ui.item.e_id;
                   }else if(ui.item.menu==="Product"){
                    window.location.href="../product/"+ui.item.label.replace(/\s/g, '_')+"?rf="+ui.item.e_id;
                   }
                   //alert(ui.item.category_name);
                   //if(event)
                   //alert("i am in select");
               }
             });

             $("#txtSearchHead_sm").autocomplete({
               source:listVal,
               select:function(event,ui){
                   event.preventDefault();
                   if(ui.item.menu==="Blogs"){
                    window.location.href="../blog/"+ui.item.label.replace(/\s/g, '_')+"?rf="+ui.item.e_id;
                }else if(ui.item.menu==="Product"){
                 window.location.href="../product/"+ui.item.label.replace(/\s/g, '_')+"?rf="+ui.item.e_id;
                }
                   //alert(ui.item.category_name);
                   //if(event)
                   //alert("i am in select");
               }
             });
        }
    }
    componentDidMount(){
        clsObject=this;
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getAccessToken"});
        mapCat.push({name:"res_func",value:"callBackAccess"});
       // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
        //mapCat.push({name:"emp_id",value:userObject[0].id});
        mapCat["res_func"]="callBackAccess";
        mapCat["curl_type"]="getAccessToken";
        CallCMSMapMethod(this,mapCat);
       
        userOpt="<li>"+
        "<a href=\"../login\"><i class=\"icon-user\"></i>Login</a>"+
                                        "</li>";
            if(logInObj)
            {
                         userOpt= "<li class=\"dropdown\">"+
                                        "<a href=\"#\">"+"Welcome "+personName+" <i class=\"ion-ios-arrow-down\"></i></a>"+
                                                                            "<ul class=\"sub-menu\">"+
                                                                                // "<li><a class=\"dropdown-item\" href=\"./my_profile\">My account</a></li>"+
                                                                                "<li><a class=\"dropdown-item\" href=\"./dashboard\">Dashboard</a></li>"+
                                                                                // "<li><a class=\"dropdown-item\" href=\"#\">My Cart</a></li>"+
                                                                                "<li><a class=\"dropdown-item _setbackparam\" param1=\"1\"  href=\"javascript:void(0)\">Logout</a></li>"+
                                                                            "</ul>"+
                                                                            "</li>";   
                                 
                                        }
  //alert(userOpt);
  
  setKeyUpMapp(this,"gb_search");

  var locCityList=CityList();
 
  $("#txtSearchHead").autocomplete({
   source:locCityList
 });

 //userOpt="";
  //$("#user_opt_desk").html(userOpt);
  //$("#user_opt_mob").html(userOpt);
  

    }
    setMetaTags(listMeta){
        // alert(JSON.stringify(listMeta));
        // alert(JSON.stringify(this.props));
         for(var m=0;m<listMeta.length;m++){
             if(typeof loc==='undefined'|| loc===""){
                 loc="home";
             }
            
            if(listMeta[m]["col2"].toUpperCase()===loc.toUpperCase() || listMeta[m]["col2"].toUpperCase()==="../"+loc.toUpperCase()){
                //alert(listMeta[m]["col2"]);
                //alert(listMeta[m]["col2"]+"=="+loc);
                _topTitle=listMeta[m]["col3"];
                _topKeywords=listMeta[m]["col4"];
                _topDescription=listMeta[m]["col5"];
                document.title=_topTitle;
               // alert(_topTitle +listMeta[m]["id"]);
             document.querySelector('meta[name="title"]').setAttribute("content", _topTitle);
             document.querySelector('meta[name="description"]').setAttribute("content", _topDescription);
             document.querySelector('meta[name="keywords"]').setAttribute("content", _topKeywords);
            }
           
         }
         
     }
    callBackAccess(data,msg){
        //alert("cback"+data);
        if(data!=="0"){
            localStorage.setItem('userData',data);
            UserToken.setUserObject(localStorage.getItem("userData"));
            listRoles= UserToken.getAccessRoles();
            this.setMetaTags(listRoles);
            
          var listHtm=  getAccessRolesNew(listRoles); 
          $("#menu_opt").html(listHtm+userOpt);
          $("#mob_menu").html(userOpt+listHtm);
          RespBkMthdMapp(clsObject,"_setbackparam","logout");
        }
    }
    render(){
        return(<div>
            <div className="green sticky-header menu_fixed header section">
        <div className="header-bottom border-box-2_22 d-lg-none sticky-nav text-white">
            <div className="container_top position-relative">
                <div className="row align-self-center">
                    
                    <div className="col-auto align-self-center">
                        <div className="header-logo">
                            <a href="/"><img src={logoImg} alt="Site Logo" /></a>
                        </div>
                    </div>
                    
        
                    
                    <div className="col align-self-center">
                        <div className="header-actions">
                            <div className="header_account_list">
                                <a href="javascript:void(0)" className="header-action-btn search-btn"><i
                                        className="icon-magnifier"></i></a>
                                <div className="dropdown_search">
                                    <form className="action-form" action="#">
                                        <input className="form-control" placeholder="Enter your search key" type="text" />
                                        <button className="submit" type="submit"><i className="icon-magnifier"></i></button>
                                    </form>
                                </div>
                            </div>
                           
                            <div id="user_opt_mob" className="header-bottom-set dropdown">
                            </div>
                            
                            
                           
                            <a href="#offcanvas-mobile-menu" className="header-action-btn header-action-btn-menu offcanvas-toggle d-lg-none">
                                <i className="icon-menu"></i>
                            </a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
        <div className="green d-none d-lg-block sticky-nav mb-5px">
            {loc==="home12"?<div className="vertical_tab">
            <ul className="virtical">
  <li><a style={{"color":"#fff"}} href="#divind0">Home</a></li>
  <li><a style={{"color":"#fff"}} href="#divind1">About Us</a></li>
  <li><a style={{"color":"#fff"}} href="#divind2">Offerings</a></li>
  <li><a style={{"color":"#fff"}} href="#divind3">Industries</a></li>
  <li><a style={{"color":"#fff"}} href="#divind4">Modal of Engagement</a></li>
  <li><a style={{"color":"#fff"}} href="#divind5">Work with Us</a></li>
  <li><a style={{"color":"#fff"}} href="#divind7">Blogs</a></li>
  <li><a style={{"color":"#fff"}} href="#divind8">Client Speaks</a></li>
</ul>

            </div>:''}
            
            <div className="container_top position-relative">
                <div className="row">
                    <div className='col-md-1'><a className='vertical-center' href="/"><img className='logo_desk' src={logoImg} alt="Site Logo" /></a></div>
                    <div className="col-md-10 align-self-center">
                        <div className="main-menu manu-color-white">
                            <ul id="menu_opt">
                            </ul>
                            {/* {getAccessRoleLink(this.state.stRoles)} */}
                        {/* <getAccessRoleLink lsRole={this.state.stRoles}/> */}
                        </div>
                    </div>
                    <div className='col-md-1'>
                    <a href="javascript:void(0)" className="header-action-btn search-btn vertical-center"><i
                                        className="icon-magnifier"></i></a>
                    </div>
                </div>
            </div>
        </div>
        
        </div>
        <div id="offcanvas-mobile-menu" className="offcanvas offcanvas-mobile-menu">
        <button className="offcanvas-close"></button>
        <div className="inner customScroll">
        
            <div className="offcanvas-menu mb-4">
                <ul id="mob_menu">
                </ul>
            </div>
        </div>
        </div>
        </div>
        )
    }
}
export default Front_Header;
import React from 'react'
import { appGetUrlPath } from '../function_lib/common_cms'
import parse from 'html-react-parser'
import './custome_section2.css'
export function sortResults (listjs, prop, asc) {
  listjs.sort(function (a, b) {
    if (asc) {
      return parseInt(a[prop]) > parseInt(b[prop])
        ? 1
        : parseInt(a[prop]) < parseInt(b[prop])
        ? -1
        : 0
    } else {
      return parseInt(b[prop]) > parseInt(a[prop])
        ? 1
        : parseInt(b[prop]) < parseInt(a[prop])
        ? -1
        : 0
    }
  })
  return listjs
}
export function Custome_Section2 (props = []) {
  let lsData = sortResults(props.lsData, 'col14', true)
  let appUrtPath = appGetUrlPath()
  //alert(JSON.stringify(lsData));
  let titleTxt = ''
  let titleSubDesc = ''
  if (lsData !== 'undefined') {
    if (lsData[0].col10 === 'top_text') {
      titleTxt = lsData[0].col1
      titleSubDesc = lsData[0].col2
    }
  }
  //for data sample
  return (
    <section class="start-left-image-right-text">
		<div class="hero_banner image-cover image_bottom bg12">

        {lsData!=="undefined"?lsData.map((item,index)=>{
            if(item.col10==="top_text"){
                return <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-9 col-md-9 col-sm-12">
                        <div class="side_block">
                            <div class="right-text-block">
                                <div class="right-text-title">
                                    <h3 class="title mb-1">{titleTxt}</h3>
                                    <small class="mb-4">{titleSubDesc}</small>
                                </div>
                            </div>
                            <img src={appUrtPath + item.col4} class="img-fluid csection_2_img border-box-2" alt="" />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="hero-right-text">
                            <h3 class="title mb-1">{titleTxt}</h3>
                            <small class="mb-4">{titleSubDesc}</small>
                        </div>
                    </div>
                </div>
            </div>
            }
            
        }):'' }
			
    {/* for other componet */}
        
			<div class="container my-5">
				<div class="row justify-content-center">
                {lsData!=="undefined"?lsData.map((item,index)=>{
                    if(item.col10!=="top_text"){
                        return <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                        <div class="block">
                            <div class="block-image">
                                <div class="block-image-icon">
                                    <img src={appUrtPath + item.col4} class="img-fluid" />
                                </div>
                            </div>
                            <div class="crs_capt_cat">
                                <h4 class="mb-4">{item.col1}</h4>
                                <p class="mb-2">
                                   {parse(item.col3)} 
                                </p>
                                
                            </div>
                        </div>
                    </div>
                    }
                  
                }):''}    
				</div>
			</div>
			
		</div>
	</section>
  )
}
export function Custome_Section1_old (props = []) {
  let lsData = sortResults(props.lsData, 'col14', true)
  let appUrtPath = appGetUrlPath()
  //alert(JSON.stringify(lsData));
  let titleTxt = ''
  let titleSubDesc = ''
  if (lsData !== 'undefined') {
    if (lsData[0].col10 === 'top_text') {
      titleTxt = lsData[0].col1
      titleSubDesc = lsData[0].col2
    }
  }
  //for data sample
  return (
    <section class='u-clearfix u-grey-5 u-section-2' id='carousel_86f6'>
      <div class='u-bottom-right-radius-25 u-palette-2-base u-shape u-shape-round u-shape-1'></div>
      {lsData !== 'undefined' &&
      lsData[0].col10 === 'top_text' &&
      lsData[0].col4 !== '' ? (
        <img
          class='u-image u-image-round u-radius-25 u-image-1'
          data-image-width='900'
          data-image-height='855'
          src={appUrtPath + lsData[0].col4}
        />
      ) : (
        ''
      )}

      <div class='u-align-left u-container-style u-group u-palette-1-dark-2 u-radius-25 u-shape-round u-group-1'>
        <div class='u-container-layout u-valign-middle u-container-layout-1'>
          <h5 class='u-text u-text-default u-text-1'></h5>
          <h1 class='u-text u-text-2'>{titleTxt}</h1>
          <p class='u-text u-text-body-alt-color u-text-3'>{titleSubDesc}</p>
        </div>
      </div>
      <div class='u-list u-list-1'>
        <div class='u-repeater u-repeater-1'>
          {lsData !== 'undefined'
            ? lsData.map((item, index) => {
                if (item.col10 !== 'top_text') {
                  return (
                    <div class='u-align-left u-container-style u-list-item u-radius-25 u-repeater-item u-shape-round u-white u-list-item-1'>
                      <div class='u-container-layout u-similar-container u-valign-top u-container-layout-2'>
                        <span class='u-file-icon u-icon u-icon-1'>
                          <img src={appUrtPath + item.col4} alt='' />
                        </span>
                        <h6 class='u-custom-font u-font-pt-sans u-text u-text-palette-1-base u-text-4'>
                          {item.col1}
                        </h6>
                        <p class='u-text u-text-5'>{parse(item.col3)}</p>
                      </div>
                    </div>
                  )
                }
              })
            : ''}
        </div>
      </div>
    </section>
  )
}
export default Custome_Section2

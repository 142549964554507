import $, { map, parseHTML } from 'jquery';
import React from 'react';
import {appGetUrlPath,RespBkMthdMapp,CallMapMethod,AjaxCallMethod} from '../function_lib/common_lib';
var parse = require('html-react-parser');
let proData;
let appUrtPath;
let description;
let composition;
let imgPath;
let clsObject;
let rateData=[];
let checkAvgStar=0;
let strAvgStar="";

class Single_Product extends React.Component{

    executeRating(stars) {
        
        const starClassActive = "rating__star ion-android-star";
        const starClassInactive = "rating__star ion-android-star-outline";
        const starsLength = stars.length;
        let i;
        stars.map((star,i) => {
          star.onclick = () => {
             // alert("i am in click");
            i = stars.indexOf(star);
            //alert(i);
            $("#rating").val((i+1));
            if (star.className===starClassInactive) {
              for (i; i >= 0; --i) stars[i].className = starClassActive;
            } else {
              for (i; i < starsLength; ++i) stars[i].className = starClassInactive;
            }
          };
        });
      }

    callBackRating(data,msg){
        //alert(data);
        if(data!=="0"){
            alert("Thanks for Review");
            $("#frmSingCommentForm").find("input[type=text]").val("");
        }
    }  
    constructor(props){
      super(props);
      appUrtPath=appGetUrlPath();
      clsObject=this;
      if(this.props.proData!=null){
        proData=this.props.proData;
        rateData=this.props.ratings;
        /* Apply Avg Rating */
        let sumRate=0;
        for(var r=0;r<rateData.length;r++){
            sumRate+=parseInt(rateData[r].rating);
        }
        checkAvgStar=sumRate/rateData.length;
       
        for(var a=0;a<5;a++){
            if(checkAvgStar>0 && checkAvgStar<1){
                strAvgStar+="<i class=\"ion-android-star-half\"></i>";
            }else if(checkAvgStar>=1){
                strAvgStar+="<i class=\"ion-android-star\"></i>";
            }else{
                strAvgStar+="<i class=\"ion-android-star-outline\"></i>";
            }
           
           checkAvgStar--;
        }
    


        imgPath=["default-product.png"];
        //alert(proData[0]["img_path"]);  
        if(proData[0]["img_path"]!==""){
            //alert("i ma in condi");
         imgPath=proData[0]["img_path"].split(',');
          }
        //composition= $.parseHTML();  
       //alert(JSON.stringify(this.props.proData));
      }
    }
    componentDidMount(){
    //    //$("#composition").html(proData[0]["composition"]);
    //    $("#pro_faq").html(proData[0]["faq"]);
    //    $("#pro_description").html(proData[0]["product_description"]);
    //    $("#pro_usage").html(proData[0]["usage"]);
    //    $(".product_desc").html(proData[0]["product_description"]);
    $("#pro_sm_desc").html(proData[0]["product_description"]);
       
       //alert("i am in click set");
       const script = document.createElement("script");
       script.src = "/main.js";
       script.async = true;
       //script.onload = () => clsObject.scriptLoaded();
     
       document.body.appendChild(script);
       AjaxCallMethod(this,"okSuccCNOT");
       
       const ratingStars = [...document.getElementsByClassName("rating__star")];
    //    alert(ratingStars.length);
       this.executeRating(ratingStars);
       $("#landing_rating").html(strAvgStar);
    }
    
    render(){
        return(<div><div className="product-details-area pt-50px pb-50px">
        <div className="container">
        <form>
            <div className="row">
    <input type="hidden" name="item_name" value={proData[0]["product_name"]} />
    <input type="hidden" name="item_code" value={proData[0]["product_code"]}/>
    <input type="hidden" name="item_rate" value={proData[0]["cost"]}/>
    <input type="hidden" name="comp_name" value={0} />
    <input type="hidden" name="imgs" value={imgPath[0]} />
    <input type="hidden" name="item_rf_id" value={proData[0]["id"]} />
    <input type="hidden" name="offer_per" value={proData[0]["offer_per"]}/>
    <input type="hidden" name="pre_cost" value={proData[0]["pre_cost"]}/>

                <div className="col-lg-6 col-sm-12 col-xs-12 mb-lm-30px mb-md-30px mb-sm-30px">
                    {/* <!-- Swiper --> */}
                    <div className="swiper-container zoom-top">
                        <div className="swiper-wrapper">
                            {imgPath.map(imgNm=>(
   <div className="swiper-slide zoom-image-hover">
   <img className="img-responsive m-auto" src={appUrtPath+imgNm} alt="" />
</div>
                            ))

                            }
                        </div>
                    </div>
                    <div className="swiper-container zoom-thumbs slider-nav-style-1 small-nav mt-3 mb-3">
                        <div className="swiper-wrapper">
                            {imgPath.map(imgNm=>(
 <div className="swiper-slide">
 <img className="img-responsive m-auto" src={appUrtPath+imgNm} alt=""/>
</div>
                            ))}
                           
                           
                        </div>
                      
                        <div className="swiper-buttons">
                            <div className="swiper-button-next"></div>
                            <div className="swiper-button-prev"></div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-xs-12" data-aos="fade-up" data-aos-delay="200">
                    <div className="product-details-content quickview-content text-center">
                        <h2>{proData[0]["product_name"].replace(/&amp;/g, "&")}</h2>
                        <p className="reference">Reference:<span> {proData[0]["product_code"]}</span></p>
                        <div className="pro-details-rating-wrap text-center">
                            <div id="landing_rating" className="rating-product">
                                <i className="ion-android-star"></i>
                                <i className="ion-android-star"></i>
                                <i className="ion-android-star"></i>
                                <i className="ion-android-star"></i>
                                <i className="ion-android-star"></i>
                            </div>
                            <span className="read-review"><a className="reviews" href="#des-details3">Read reviews ({rateData.length})</a></span>
                        </div>
                        <div className="pricing-meta">
                            <ul>
                                <li className="old-price not-cut">Rs.{proData[0]["cost"]}</li>
                                {(proData[0]["cost"]!==proData[0]["pre_cost"])?<li className="old-price old">Rs.{proData[0]["pre_cost"]}</li>:''}
                                
                            </ul>
                        </div>
                        <div className="pro-details-quality">
                            <div className="cart-plus-minus">
                                <input className="cart-plus-minus-box" type="text" name="item_qty" value="1" />
                            </div>
                            <div className="pro-details-cart">
                                <button type="button" className="add-cart btn _addToCart btn-primary btn-hover-primary ml-4" href="javascript:void(0)"> Add To
                                    Cart</button>
                            </div>
                        </div>
                        <p id="pro_sm_desc" className="quickview-para"></p>
                        {/* <div className="pro-details-wish-com">
                            <div className="pro-details-wishlist">
                                <a href="wishlist.html"><i className="ion-android-favorite-outline"></i>Add to
                                    wishlist</a>
                            </div>
                            <div className="pro-details-compare">
                                <a href="compare.html"><i className="ion-ios-shuffle-strong"></i>Add to compare</a>
                            </div>
                        </div> */}
                        <div className="pro-details-social-info">
                            <span>Share</span>
                            <div className="social-info">
                                <ul className="d-flex">
                                    <li>
                                        <a title="Facebook" target='_blank' href="https://www.facebook.com/mbdhwellness"><i className="ion-social-facebook"></i></a>
                                    </li>
                                    <li>
                                        <a title="Twitter" target='_blank' href="https://twitter.com/home"><i className="ion-social-twitter"></i></a>
                                    </li>
                                    <li>
                                        <a title='Linkedin' target='_blank' href="https://www.linkedin.com/company/mbdhwellness/"><i className="icon-social-linkedin"></i></a>
                                    </li>
                                    <li>
                                        <a title="Instagram" target='_blank' href="https://www.instagram.com/mbdhwellness/"><i className="ion-social-instagram"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            </form>
        </div>
    </div>
    <div id="proIngredient"></div>
    <div className="description-review-area pb-100px">
    <div className="container1">
        <div className="description-review-wrapper">
            <div className="description-review-topbar nav">
                <a href="#des-details0">Usage</a>
                <a  href="#des-details1">Description</a>
                <a  href="#des-details2">FAQs</a>
                <a  href="#des-details3">Reviews</a>
            </div>
            <div className="tab-content description-review-bottom">
            <div id="des-details0">
                    <div className="product-description-wrapper">
                        <p id="pro_usage">
                        </p>
                        
                    </div>
                </div>
                <div id="des-details1">
                    <div className="product-description-wrapper">
                        <p id="pro_description">
                        </p>
                        
                    </div>
                </div>
                <div id="des-details2">
                    <h2 className='title'>FAQs</h2>
                    <div className="product-anotherinfo-wrapper" id="pro_faq">
                      
                    </div>
                </div>
                <div id="des-details3">
                    <h2 className='title'>Reviews</h2>
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="review-wrapper">
                                {(rateData.length!==0?rateData.map((item,index)=>{
                                    let countStar=parseInt(item.rating);
                                
                                    var strStar=[];
                                    var notStar=[];
                                    for(var s=1;s<=countStar;s++){
                                        strStar.push([s]);
                                       // strStar+="<i className=\"ion-android-star\"></i>";
                                    }
                                    for(var s=1;s<=5-countStar;s++){
                                      notStar.push([s]);
                                        // strStar+="<i className=\"ion-android-star-outline\"></i>";
                                    }
                                    //alert(countStar+strStar);

                                    return  <div className="single-review">
                                    <div className="review-img">
                                        <img src="../user.png" alt="" />
                                    </div>
                                    <div className="review-content">
                                        <div className="review-top-wrap">
                                            <div className="review-left">
                                                <div className="review-name">
                                                    <h4>{item.customer_name}</h4>
                                                </div>
                                                <div className="rating-product">
                                               {(strStar.map((star)=>{
                                                   return <i className="ion-android-star"></i>
                                               }))}
                                                {(notStar.map((star)=>{
                                                   return <i className="ion-android-star-outline"></i>
                                               }))}
                                                </div>
                                            </div>
                                            <div className="review-left">
                                                {/* <a href="#">Reply</a> */}
                                            </div>
                                        </div>
                                        <div className="review-bottom">
                                            <p>
                                                {item.comment}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                }):'')}
                               
                                
                                
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="ratting-form-wrapper pl-50">
                                <h3>Add a Review</h3>
                                <div className="ratting-form">
                                    <form id="frmSingCommentForm">
                                    <input type="hidden" name="curl_type" id="curl_type" value="addProductRating" />  
                <input type="hidden" name="m_type" id="m_type" value="addProductRating" />
                <input type="hidden" name="res_func" id="res_func" value="callBackRating" />
                <input type="hidden" name="account_info_id" id="account_info_id" value="0"/>
                <input type="hidden" name="product_master_id" id="product_master_id" value={proData[0]["id"]}/>
                <input type="hidden" name="invoice_id" id="invoice_id" value="0" />
                <input type="hidden" name="gb_token" id="gb_token" value="5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg" />
                <input type="hidden" name="rating" id="rating" value="5"/>
                <input type="hidden" name="pg_id" id="pg_id" value="0"/>
                <input type="hidden" name="component_id" id="component_id" value="0"/>
                                        <div className="star-box">
                                            <span>Your rating:</span>
                                            <div className="rating-product">
                                                    <i className="rating__star ion-android-star-outline"></i>
                                                    <i className="rating__star ion-android-star-outline"></i>
                                                    <i className="rating__star ion-android-star-outline"></i>
                                                    <i className="rating__star ion-android-star-outline"></i>
                                                    <i className="rating__star ion-android-star-outline"></i>
                                               
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="rating-form-style">
                                                    <input placeholder="Name" type="text" id="customer_name" name="customer_name" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="rating-form-style">
                                                    <input placeholder="Contact No" id="contact_no" name="contact_no" type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="rating-form-style form-submit">
                                                    <textarea name="comment" id="comment" placeholder="Comments"></textarea>
                                                    <button className="btn btn-primary btn-hover-color-primary _btnSetColor" type="button" value="Submit">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
    )
    }
}
export default Single_Product;
import  $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,GetImgCertUser} from '../function_lib/common_lib';
import {CallCMSMapMethod} from '../function_lib/common_cms';
import QueryString from 'query-string';
import Front_Header from './front_header';
import Footer from './footer';
import Cart_Canvas from '../sample_comp/cart_canvas';
import Product_Listing from '../sample_comp/product_listing';
import AOS from 'aos';
/* components */
import Top_Banner from '../sample_comp/top_banner';
import Features_Slider  from '../sample_comp/features_slider';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import Left_Img_Txt_Desc from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';
import './receipt.css';
import './login.css';

let loginUser="";
let userObject="";
let clsObject="";
let loc="";
let products="";
let logInObj="";
let queries;
let rf="0";
class Receipt extends React.Component{
    constructor(props){
        super(props);

        if(props !=null){
            queries = QueryString.parse(this.props.location.search);
                  if(queries.rf!=null){
                    rf=queries.rf;
                  }
          }
        loc=props.location["pathname"].substr(1);
        if(localStorage.getItem("userDS")!=null){
          logInObj=JSON.parse(localStorage.getItem("userDS"));
        }

        if(localStorage.getItem("userData")!=null){
          //alert(localStorage.getItem("userData"));
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          if(userObject){
            //loginUser=userObject[0].person_name;
          }
          
          //window.location.href="./Dashboard";
        }
      }
      componentDidMount(){
          clsObject=this;
        var pgMap=[];
        pgMap.push({name:"curl_type",value:"getPgData"});
        pgMap.push({name:"res_func",value:"callBackpgData"});
        pgMap.push({name:"loc",value:loc});
       // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
        //mapCat.push({name:"emp_id",value:userObject[0].id});
        pgMap["res_func"]="callBackpgData";
        pgMap["curl_type"]="getPgData";
        CallCMSMapMethod(clsObject,pgMap);
        AjaxCallMethod(this,"okSuccCNOT");
      }
      okSuccLogn(data,datares){
        //alert(data);
        if(data!="0" && data!==""){
          $("#getRegLogn").html("Login Successfully..");
         localStorage.setItem('userDS',data);
         window.location.href="/home";
        }else{
          $("#getRegLogn").html("Invalid UserName or Password !");
        }
    }
      okSuccCNOT(data,datares){
        //alert(data);
        if(data!="0" && data!==""){
          $("#getRegResp").html("Login Successfully..");
         localStorage.setItem('userDS',data);
         window.location.href="/home";
        }else{
          $("#getRegResp").html("Invalid UserName or Password !");
        }
    }
      callBackpgData(data,msg){
        //alert("testing of2 "+data);
        //$("#pgComp").html(data);
        var listObj= JSON.parse("{\"access\":"+data+"}");
        //alert("successfully parse");
        //var mainHt=Cart_Canvas();
        var mainHt="";
        if(loc==="home"){
           // mainHt+=Video_Banner_Comp();
        }
        var injextI=0;
        var injCondition=false;
      
        for(var _dlist=0;_dlist<50;_dlist++){
         
            if(listObj.access[_dlist]){
                if(listObj.access[_dlist].top_banner){
                   // alert("I am in conditio");
                   injCondition=true;
                   mainHt+=Top_Banner(listObj.access[_dlist].top_banner);
                }
                else if(listObj.access[_dlist].home_banner){
                   // alert("i am in cond..");
                   injCondition=true;
                    mainHt+=Home_Banner(listObj.access[_dlist].home_banner);
                }else if(listObj.access[_dlist].dual_text_card){
                    injCondition=true;
                    //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
                   // mainHt+=Dual_Banner(listObj.access[_dlist].dual_text_card);
                }else if(listObj.access[_dlist].single_img_text){
                    injCondition=true;
                    mainHt+=Single_Img_Msg(listObj.access[_dlist].single_img_text);
                }else if(listObj.access[_dlist].img_text_desc_img_left){
                    injCondition=true;
                    mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_left);
                }else if(listObj.access[_dlist].text_description){
                    injCondition=true;
                    //alert("i am in text");
                    //alert(Txt_Description(listObj.access[_dlist].text_description));
                    mainHt+=Txt_Description(listObj.access[_dlist].text_description);
                }else if(listObj.access[_dlist].list_slider){
                    injCondition=true;
                    mainHt+=Article_Blogs(listObj.access[_dlist].list_slider);
                }
                if(injCondition){
                    injextI++;
                }
                if(injextI==1){
                    //alert("check in condi == "+loc)
                    if(loc==="home"){
                        mainHt+=Features_Slider();
                        //alert(products);
                        mainHt+=products;
                    }
               }
            }
    
          
            //$("#pgComp").innerHtml=mainHt;
        }
        if(listObj.access.length==0){
            mainHt+=products;
        }
        //alert(mainHt);
        //mainHt+=Product_Hori_Slider();
        $("#pgComp").html(mainHt);
        AOS.init();
        AOS.init({
          once: true
        });
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);
          //this.forceUpdateHandler();
      
    }
    render(){
        return(<div>
             <Front_Header/>
             <div id="pgComp">
           </div>
            <div className="bg-gogreen login-register-area pt-100px pb-100px">
<div className="container">
    <div className="row">
        <div className="col-lg-7 col-md-12 ml-auto mr-auto">
        <div className="ptrmain_div">
		<div>
			<div className="prt_head_div">
				<div style={{width: "80%",flex:"0 0 auto"}}>
					<h3>RETAIL/TAX INVOICE</h3>
					<img src="images/logo.png" />
				</div>
				<div style={{width:"20%",flex:"0 0 auto",textAlign:"center"}}>
					<img src="images/qrcode.png" />
				</div>
				
			</div>
			<div style={{display:"flex",flexWrap:"wrap"}}>
				<div style={{width:"30%",flex:"0 0 auto",border:"1px solid",padding:"10px",fontFamily:"Verdana, Geneva, sans-serif"}}>
					<h4 style={{marginTop:"0px",marginBottom:"10px"}}>SOLD BY</h4>
					<p style={{marginTop:"5px"}}>Million Billion Dreams Hub Private Limited
						B.O: 58, G M Arcade, 3rd Floor, Industrial<br></br>
						Layout,JNC Road, Kormangala 5th Block,<br></br>
						Bangalore- 560034<br></br>
						Contact: 1800 889 0114<br></br>
						Email: <a href="mailto:sales@mbdhwellness.com">sales@mbdhwellness.com</a>
					</p>
					<p style={{marginBottom:"10px"}}>PAN NO: AAHCC4075D<br></br>
						GSTIN: 23AAHCC4075D1Z0<br></br>
						DL No: 20B/1330/20/2019, 21B/1331/20/2019</p>
				</div>
				<div style={{width:"30%",flex:"0 0 auto",border:"1px solid",padding:"10px",margin: "0px 8px 0px 8px",height:"290px",fontFamily: "Verdana, Geneva, sans-serif"}}>
					<h4 style={{marginTop:"0",marginBottom:"10px"}}>SHIPPING ADDRESS</h4>
					<p style={{marginTop: "5px"}}>MANSI BIYANI<br></br>
						4. Mussadipura Biyani Bhawan Satimarg<br></br>
						Ujjain M.P. 456001 
					</p>
				</div>
				<div style={{width:"30%",flex:"0 0 auto",border:"1px solid",padding:"10px",height:"290px",fontFamily: "Verdana, Geneva, sans-serif"}}>
					<h4 style={{marginTop:"0",marginBottom:"10px"}}>BILLING ADDRESS</h4>
					<p style={{marginTop:"5px"}}>MANSI BIYANI<br></br>
						4. Mussadipura Biyani Bhawan Satimarg<br></br>
						Ujjain M.P. 456001 
					</p>
				</div>
			</div>
			
			<div style={{display:"flex",flexWrap:"wrap"}}>
				<div style={{width:"80%",flex:"0 0 auto",fontFamily:"Verdana, Geneva, sans-serif"}}>
					<h5 style={{margin:"10px 0"}}>ORDER ID : AY11465 </h5>
					<h5 style={{margin:"10px 0"}}>ORDER DATE: 11.02.2022  </h5>
					<h5 style={{margin:"10px 0px 0px"}}>PLACE OF SUPPLY: </h5>
				</div>
				
				<div style={{width:"20%",flex:"0 0 auto",fontFamily:"Verdana, Geneva, sans-serif"}}>
					<h5 style={{margin:"10px 0"}}>INVOICE NO: AY11465 </h5>
					<h5 style={{margin:"10px 0px"}}>INVOICE DATE: 11.02.2022 </h5>
				</div>
			</div>
			<hr style={{borderColor:"black",marginBottom:"20px"}}></hr>
			

			{/* <!-- tablesection --> */}
			<table className="ptrTable" width="100%">
			  <tr>
			    <th>QTY</th>
			    <th>PRODUCT</th>
			    <th>DESCRIPTION</th>
			    <th>GROSS AMOUNT</th>
			    <th>DISCOUNT</th>
			    <th>NET AMOUNT</th>
			    <th>SGST</th>
			    <th>CGST</th>
			    <th>IGST</th>
			    <th>Total</th>
			  </tr>
			  <tr>
			    <td>1.</td>
			    <td>CAFEMAXX-5</td>
			    <td>HSN: 30049011-IGST 12%</td>
			    <td>4500 </td>
			    <td>1500</td>
			    <td>2678.57</td>
			    <td>0</td>
			    <td>0</td>
			    <td>321.43</td>
			    <td>3000</td>
			  </tr>
			  <tr>
			    <td>2.</td>
			    <td>IMMUNOHAB</td>
			    <td>HSN: 30049011-IGST 12%</td>
			    <td>2000</td>
			    <td>500</td>
			    <td>1339.29</td>
			    <td>0</td>
			    <td>0</td>
			    <td>160.71</td>
			    <td>1500</td>
			  </tr>
			  <tr>
			    <td></td>
			    <td>SHIPPING</td>
			    <td></td>
			    <td></td>
			    <td></td>
			    <td></td>
			    <td></td>
			    <td></td>
			    <td></td>
			    <td>0</td>
			  </tr>
			  <tr>
			    <td></td>
			    <td>COUPON DISCOUNT</td>
			    <td></td>
			    <td></td>
			    <td></td>
			    <td></td>
			    <td></td>
			    <td></td>
			    <td></td>
			    <td>50</td>
			  </tr>
			  <tr>
			    <td></td>
			    <td>GRAND TOTAL</td>
			    <td></td>
			    <td></td>
			    <td></td>
			    <td></td>
			    <td></td>
			    <td></td>
			    <td></td>
			    <td>4450</td>
			  </tr>
			  <tr>
			    <td colspan="10">
			    	Amount in Words: Four Thousand Five Hundred and Fifty Only
			    </td>
			    
			  </tr>
			  <tr>
			    <td colspan="8">
			    </td>
			    <td colspan="2">
			    	For MBDH Wellness<br></br>
			    	<img src="images/signature.png" /><br></br>
			    	Authorized Signatory
			    </td>
			  </tr>
			</table>
			{/* <!-- endtable --> */}

			{/* <!-- footer --> */}
			<div style={{fontFamily:"Verdana, Geneva, sans-serif"}}>
				<h4 style={{marginBottom:"5px"}}> Declaration:</h4>
				<p style={{marginTop:"5px"}}>The goods sold are intended for end user consumption and not for resale. Not eligible for return. All disputes subject to UJJAIN Jurisdiction only. </p>
			<hr></hr>
			<p style={{textAlign:"center"}}>MBDH Wellness is a registered Brand of Million Billion Dreams Hub Private Limited<br></br>
			Registered Address for Million Billion Dreams Hub Private Limited<br></br>
			4, Mussadipura Near Satimarg Ujjain (M.P) – 456006 | CIN- U74999MP2018PTC045036</p>
			<hr></hr>
			</div>
			{/* <!-- endfooter --> */}
		</div>
	</div>
            <div className="border-box login-register-wrapper">
             Reference Id : {rf}
                
            </div>
        </div>
    </div>
</div>
</div>
<Footer/>
        </div>);
    }
}
export default Receipt;
import $, { extend } from 'jquery';
import React from 'react';
import LogoImg from '../assets/images/my_logo.jpg';
import UserToken from '../function_lib/userToken';
import {getAccessRolesNew} from '../function_lib/common_lib';
import defaultPic from '../assets/images/9.png';
let listRoles;
let loginUser;
let userObject;
class Side_Bar extends React.Component{
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          listRoles= UserToken.getAccessRoles();
          userObject= JSON.parse(localStorage.getItem("userDS"));
          //alert(userObject.length);
          loginUser=userObject[0].person_name;
          //alert(listRoles.length);
        }
      }
      componentDidMount(){
        if(listRoles.length!=0){
          var listHtm=getAccessRolesNew(listRoles);
          //alert(listHtm);
        
          $("#menu_opt").html(listHtm);
          $(".dropdown-toggle").click();
        }
      }

      render(){
          return(             
<aside className="main-sidebar sidebar-dark-primary elevation-4">

<a href="index3.html" className="brand-link">
      <img src={defaultPic} alt="AdminLTE Logo" className="brand-image img-circle elevation-3" />
      <span className="brand-text font-weight-light">Mr. Testing</span>
    </a>


<div className="sidebar">
  
  


  <nav className="mt-2">
    <ul id="menu_opt" className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
    
      <li className="nav-item has-treeview">
        <a href="/dashboard" className="nav-link">
          <i className="nav-icon fa fa-tachometer-alt"></i>
          <p>
            Dashboard
          </p>
        </a>
       
      </li>
      <li className="nav-item">
        <a href="/treeMembers" className="nav-link">
          <i className="nav-icon fa fa-th"></i>
          <p>
            Members
          </p>
        </a>
      </li>
      <li className="nav-item has-treeview">
        <a href="/post" className="nav-link">
          <i className="nav-icon fa fa-copy"></i>
          <p>
            Day Book
            <i className="fa fa-angle-left right"></i>
            <span className="badge badge-info right">6</span>
          </p>
        </a>
        <ul className="nav nav-treeview">
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Top Navigation</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Top Navigation + Sidebar</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Boxed</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link active">
              <i className="far fa-circle nav-icon"></i>
              <p>Fixed Sidebar</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Fixed Navbar</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Fixed Footer</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Collapsed Sidebar</p>
            </a>
          </li>
        </ul>
      </li>
      <li className="nav-item has-treeview">
        <a href="/post" className="nav-link">
          <i className="nav-icon fa fa-chart-pie"></i>
          <p>
            Achievements
            <i className="right fa fa-angle-left"></i>
          </p>
        </a>
        <ul className="nav nav-treeview">
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>ChartJS</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Flot</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Inline</p>
            </a>
          </li>
        </ul>
      </li>
      <li className="nav-item has-treeview">
        <a href="/post" className="nav-link">
          <i className="nav-icon fa fa-tree"></i>
          <p>
            Inspiration
            <i className="fa fa-angle-left right"></i>
          </p>
        </a>
        <ul className="nav nav-treeview">
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>General</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Icons</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Buttons</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Sliders</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Modals & Alerts</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Navbar & Tabs</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Timeline</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Ribbons</p>
            </a>
          </li>
        </ul>
      </li>
      <li className="nav-item has-treeview">
        <a href="/post" className="nav-link">
          <i className="nav-icon fa fa-edit"></i>
          <p>
            Properties
            <i className="fa fa-angle-left right"></i>
          </p>
        </a>
        <ul className="nav nav-treeview">
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>General Elements</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Advanced Elements</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Editors</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Validation</p>
            </a>
          </li>
        </ul>
      </li>
      <li className="nav-item has-treeview">
        <a href="#" className="nav-link">
          <i className="nav-icon fa fa-table"></i>
          <p>
            Incidents
            <i className="fa fa-angle-left right"></i>
          </p>
        </a>
        <ul className="nav nav-treeview">
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Simple Tables</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>DataTables</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>jsGrid</p>
            </a>
          </li>
        </ul>
      </li>
        <li className="nav-item has-treeview">
        <a href="#" className="nav-link">
          <i className="nav-icon fa fa-table"></i>
          <p>
            Life Learning
            <i className="fa fa-angle-left right"></i>
          </p>
        </a>
        <ul className="nav nav-treeview">
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Simple Tables</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>DataTables</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>jsGrid</p>
            </a>
          </li>
        </ul>
      </li>
       <li className="nav-item has-treeview">
        <a href="#" className="nav-link">
          <i className="nav-icon fa fa-table"></i>
          <p>
            Secrets to Share
            <i className="fa fa-angle-left right"></i>
          </p>
        </a>
        <ul className="nav nav-treeview">
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Simple Tables</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>DataTables</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>jsGrid</p>
            </a>
          </li>
        </ul>
      </li>
       <li className="nav-item has-treeview">
        <a href="#" className="nav-link">
          <i className="nav-icon fa fa-table"></i>
          <p>
            Life Analysis/Regrets
            <i className="fa fa-angle-left right"></i>
          </p>
        </a>
        <ul className="nav nav-treeview">
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Simple Tables</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>DataTables</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>jsGrid</p>
            </a>
          </li>
        </ul>
      </li>
       <li className="nav-item has-treeview">
        <a href="#" className="nav-link">
          <i className="nav-icon fa fa-table"></i>
          <p>
            Dreams
            <i className="fa fa-angle-left right"></i>
          </p>
        </a>
        <ul className="nav nav-treeview">
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>Simple Tables</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>DataTables</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/post" className="nav-link">
              <i className="far fa-circle nav-icon"></i>
              <p>jsGrid</p>
            </a>
          </li>
        </ul>
      </li>
   </ul>
  </nav>
  
</div>

</aside>
        )
      }

}
export default Side_Bar;
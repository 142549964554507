import $ from 'jquery';
import React from 'react';
import {displaySteps,AjaxCallMethod,GetImgCertUser} from '../function_lib/common_lib';
import View_Document_Comp from './view_document_comp';
import './tax_submit_component.css';
import './tab_list.css';

let docHeader;
let applicantList;
let logInObj="";
let clsObject;

let listDocuments;


window.jQuery = $;
window.$ = $;
global.jQuery = $;

let _tagCountry=[];

class Questionnair_Component extends React.Component{
    closeMe(clName) {
        //alert("i am in click");
        $(clName).hide();
        $(clName).removeClass("show");
      }
      showMe(clName,refId="0") {
        $(clName).show();
        $(clName).addClass("show");
      }
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        if(localStorage.getItem("userDS")!=null){
            logInObj= JSON.parse(localStorage.getItem("userDS"));
        }
        this.state={ listDoc:[],listFile:[]};
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        clsObject=this;
        listDocuments=[];
      _tagCountry=props.countryList
        

    }
   
    componentDidMount(){
       
    }
    
   
    componentWillReceiveProps(props){
        this.props=props;
        
      // alert("Testing == "+JSON.stringify(this.props));
      docHeader=[];
      applicantList=[];
      if(this.props.viewLoadDoc.length>0){
        listDocuments=this.props.viewLoadDoc[0].doc_list;

          let docList=this.props.viewLoadDoc[0].doc_list;
           // $("#retesting").text(JSON.stringify(this.props));
          /*
          if(this.props.getType==="questionnair" ){
            applicantList.push("Questionnair");

          //docHeader.push("Basic Details");
          //docHeader.push("KYC");
          docHeader.push("Questionnair");
         

          }else if(this.props.getType==="documents" || typeof this.props.getType==="undefined"){
            applicantList.push("Documents");

            //docHeader.push("Basic Details");
            //docHeader.push("KYC");
            docHeader.push("Income");
            docHeader.push("Expenses");
            docHeader.push("Other");
          }
          */
          applicantList.push("Questionnair");
         docHeader.push("General Information");
         docHeader.push("Income");
         docHeader.push("Gain-Loss");
         docHeader.push("Deduction");
         docHeader.push("Miscellaneous");
          
          
          let checkDocHeaderDisplay=[];
          for(let h=0;h<docList.length;h++){
              let headerName=docList[h]["doc_header"];
              if(!(checkDocHeaderDisplay.includes(headerName))){
                checkDocHeaderDisplay.push(headerName);
              }
        }
        /* remove not required docHeader */
        for(let a=0;a<docHeader.length;a++){
            if(!checkDocHeaderDisplay.includes(docHeader[a])){
                docHeader.splice(a,1);
            }
        }

       


          this.setState({listDoc:docList},()=>{
            AjaxCallMethod(this,"_nextField");
            //window.getPagination("#tbQustData");
          });
          //this.forceUpdateHandler();
          
      }
    }
// for view loan doc file {image,pdf,}
viewFile(data){
    this.setState({listFile:data});
    this.forceUpdateHandler();
    this.showMe("#modal_view_doc");
    //alert(JSON.stringify(data));
}
    

    render(){
        return(<div>
            <div id="retesting"></div>
        {(this.state.listDoc.length>0?<div className="modal-content">
          {/* <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">{this.props.viewLoadDoc[0]["_category"]}</h5>
              
            </div> */}
              {/* View docu modal */}
              <View_Document_Comp view_doc={this.state.listFile}/>
            {/* End of View Docu Modal */}
            <form id="msform" name="msform" className='msform' enctype="multipart/form-data" method="POST" action="#">
                {(logInObj==="")?<div className='row'>
                    <div className='col-12'>{"Welcome "+this.props.viewLoadDoc[0]["first_name"]+" "+""+" , Kindly fill requested form to check '"+this.props.viewLoadDoc[0]["_category"]+"' eligibility"}</div>
                </div>:''}
            
                 
{/* new type of document header */}
<ul class="nav nav-tabs justify-content-center scrollmenu mb-10" id="myTab" role="tablist">
{docHeader.map((item,index)=>{
    let docuemntDivId=item.replace(" ","_");
    docuemntDivId+=index;
    let linkStatus="";
    if(index==0){
        linkStatus="active"
    }
    return (<li class="nav-item" role="presentation">
    <button class={"nav-link"+" "+linkStatus} id={"tab"+docuemntDivId} data-bs-toggle="tab" data-bs-target={"#"+docuemntDivId} type="button" role="tab" aria-controls={docuemntDivId} aria-selected="true">{item}</button>
</li>)
})}
</ul>

           
                     
                                  
                                    
                                    


<div id="myTabContent" class="col-md-12 col-xs-12 panel panel-default no-border single-my-account1 tab-content">
  {(docHeader.map((item,index)=>{
    //alert("doc== ["+item+"]");
     let docuemntDivId=item.replace(" ","_");
     docuemntDivId+=index;
     let tabStatus="";
     if(index==0){
        tabStatus="show active"
     }
                                    return(<div id={docuemntDivId} class={"tab-pane container fade "+tabStatus} role="tabpanel" aria-labelledby={docuemntDivId} >
                                <div class="panel-body">
                                    <table id="tbQustData">
                                        <tr><td></td></tr>
                                {listDocuments.map((docitem,di)=>{
                                    let indxList=0;
                                               if(docitem.doc_header===item && docitem.on_event!=="child"){
                                                //indxList++;
                                                if(docitem.type[0]==="text" || docitem.type[0]===""){
                                                    return( <tr><td><div className="row border-bottom">
                                                    <div className="col-lg-12 col-md-12">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">{docitem.title}</label>
                                                                <div className="input-group input-group-sm">
                                                                    <input defaultValue={docitem.doc_value} autoComplete='off' type="text" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={""} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-3 vcenter-item'>
                                                        {docitem.status}<br></br>
                                                        {docitem.reason_msg}
                                                        </div>
                                                     </div></td></tr>)
                                                }else if(docitem.type[0]==="radio_button"){
                                                    //alert(docitem.doc_value);
                                                    let strRadioList=docitem.descr;
                                                    let arrRadioList=strRadioList.split('>');
                                                    //alert(arrRadioList.length);
                                                    
                                                    return(<tr><td><div className="row border-bottom">
                                                    <div className="col-lg-12 col-md-12">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">{docitem.title}</label>
                                                                <div className="input-group input-group-sm">
                                                                   {arrRadioList.map((itemRadio,indexRadio)=>{
                                                                    return (<div><input id={(docitem._id.$oid+"_"+itemRadio).trim()} name={docitem._id.$oid} type="radio" value={itemRadio} />
                                                                    <label for={docitem._id.$oid+"_"+itemRadio}>{itemRadio}</label>
                                                                    </div>);
                                                                   })}
                                                                    {/* <input defaultValue={docitem.doc_value} autoComplete='off' type="text" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={docitem.title} /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-3 vcenter-item'>
                                                        {docitem.status}<br></br>
                                                        {docitem.reason_msg}
                                                        </div>

                                                        <div className="col-lg-12 col-md-12" id={docitem._id.$oid+"_subquest"}>
                                                                   
                                                        </div>   
                                                     </div>
                                                    
                                                     </td></tr>)
                                                }else if(docitem.type[0]==="dropdown"){
                                                    let strDropdownList=docitem.descr;
                                                    let arrDropdownList=strDropdownList.split('>');
                                                    //alert(docitem.doc_value);
                                                    
                                                    return(<tr><td><div className="row border-bottom">
                                                    <div className="col-lg-12 col-md-12">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">{docitem.title}</label>
                                                                <div className="input-group input-group-sm">
                                                                    <select id={docitem._id.$oid} name={docitem._id.$oid} className="form-control">
                                                                       <option value="0">- Select -</option>
                                                                      
                                                                       {arrDropdownList.map((itemdd,indexdd)=>{
                                                                        if(itemdd==="{#country#}"){
                                                                          //  alert("i m in condition "+docitem.title);
                                                                         return _tagCountry.map((country,indexco)=>{
                                                                            return (<option selected={docitem.doc_value===country["name"]} value={country["name"]}>{country["name"]}</option>)
                                                                           })
                                                                        }else{
                                                                            return (<option selected={docitem.doc_value===itemdd} value={itemdd}>{itemdd}</option>);
                                                                        }
                                                                    

                                                                   })}
                                                                    </select>
                                                                   
                                                                    {/* <input defaultValue={docitem.doc_value} autoComplete='off' type="text" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={docitem.title} /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-3 vcenter-item'>
                                                        {docitem.status}<br></br>
                                                        {docitem.reason_msg}
                                                        </div>
                                                     </div></td></tr>)
                                                }else if(docitem.type[0]==="date"){
                                                    
                                                    return(<tr><td> <div className="row border-bottom">
                                                    <div className="col-lg-12 col-md-12">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">{docitem.title}</label>
                                                                <div className="input-group input-group-sm">
                                                                    <input defaultValue={docitem.doc_value}  autoComplete='off' type="date" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={docitem.title} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-3 vcenter-item'>
                                                        {docitem.status}<br></br>
                                                        {docitem.reason_msg}
                                                        </div>
                                                     </div></td></tr>)
                                                }
                                                else if(docitem.type[0]==="file_upload"){
                                                   let uploadedCheck="";
                                                   if(docitem.doc_path!==""){
                                                       uploadedCheck="<a href=\"javascript:void(0)\">View</a>"
                                                   }
                                                return(<tr><td> <div className="row border-bottom">
                                                <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <label for="exampleInputEmail1">{docitem.title}</label>{uploadedCheck!==""?<a onClick={()=>this.viewFile(docitem)} href='javascript:void(0)'>View Uploaded</a>:''}
                                                            <div className="input-group input-group-sm">
                                                                <input type="file" name={docitem._id.$oid} className="form-control" placeholder="Check Contact No." />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-3 vcenter-item'>
                                                        {docitem.status}<br></br>
                                                        {docitem.reason_msg}
                                                        </div>
                                                 </div></td></tr>)
                                               } 
                                               }
                                        
                                          })}
                                   
                                   </table>
                                   
                                </div>
                            </div>
                        )
                        
                                  }))}

</div>     
 {/* end of main div tab */}
                                      <div className="col-md-6 respSSL_lod red"></div>
                                      <div id="testing2dd2222"></div>
                                
           
            </form>
          </div>:'')}
          </div>
         )
    }
}
export default Questionnair_Component;
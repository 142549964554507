import React from 'react';
import {new_enc_dec_m} from '../function_lib/common_lib';
import {getCountryList} from '../function_lib/city_india';

let cListQuest=[];

export const checkAnswers=(docitem)=>{
  let _tagCountry=getCountryList();
  if(docitem.type[0]==="text" || docitem.type[0]===""){
    return( <tr><td><div className="row border-bottom">
    <div className="col-lg-12 col-md-12">
            <div className="form-group">
                <label for="exampleInputEmail1">{docitem.title}</label>
                <div className="input-group input-group-sm">
                    <input defaultValue={docitem.doc_value} autoComplete='off' type="text" id={"quest_value"} name={"quest_value"} className="form-control" placeholder={""} />
                </div>
            </div>
        </div>
        <div className='col-3 vcenter-item'>
        {docitem.status}<br></br>
        {docitem.reason_msg}
        </div>
     </div></td></tr>)
}else if(docitem.type[0]==="radio_button"){
    //alert(docitem.doc_value);
    let strRadioList=docitem.descr;
    let arrRadioList=strRadioList.split('>');
    //alert(arrRadioList.length);
    
    return(<tr><td><div className="row border-bottom">
    <div className="col-lg-12 col-md-12">
            <div className="form-group">
                <label for="exampleInputEmail1">{docitem.title}</label>
                <div className="input-group input-group-sm">
                   {arrRadioList.map((itemRadio,indexRadio)=>{
                    return (<div><input  id={docitem._id.$oid+"_"+itemRadio} name={"quest_value"} type="radio" value={itemRadio} />
                    <label for={docitem._id.$oid+"_"+itemRadio}>{itemRadio}</label>
                    </div>);
                   })}
                    {/* <input defaultValue={docitem.doc_value} autoComplete='off' type="text" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={docitem.title} /> */}
                </div>
            </div>
        </div>
        <div className='col-3 vcenter-item'>
        {docitem.status}<br></br>
        {docitem.reason_msg}
        </div>
     </div></td></tr>)
}else if(docitem.type[0]==="dropdown"){
    let strDropdownList=docitem.descr;
    let arrDropdownList=strDropdownList.split('>');
    //alert(docitem.doc_value);
    
    return(<tr><td><div className="row border-bottom">
    <div className="col-lg-12 col-md-12">
            <div className="form-group">
                <label for="exampleInputEmail1">{docitem.title}</label>
                <div className="input-group input-group-sm">
                    <select id={"quest_value"} name={"quest_value"} className="form-control">
                       <option value="0">- Select -</option>
                      
                       {arrDropdownList.map((itemdd,indexdd)=>{
                        if(itemdd==="{#country#}"){
                          //  alert("i m in condition "+docitem.title);
                         return _tagCountry.map((country,indexco)=>{
                            return (<option selected={docitem.doc_value===country["name"]} value={country["name"]}>{country["name"]}</option>)
                           })
                        }else{
                            return (<option selected={docitem.doc_value===itemdd} value={itemdd}>{itemdd}</option>);
                        }
                    

                   })}
                    </select>
                   
                    {/* <input defaultValue={docitem.doc_value} autoComplete='off' type="text" id={docitem._id.$oid} name={docitem._id.$oid} className="form-control" placeholder={docitem.title} /> */}
                </div>
            </div>
        </div>
        <div className='col-3 vcenter-item'>
        {docitem.status}<br></br>
        {docitem.reason_msg}
        </div>
     </div></td></tr>)
}else if(docitem.type[0]==="date"){
    
    return(<tr><td> <div className="row border-bottom">
    <div className="col-lg-12 col-md-12">
            <div className="form-group">
                <label for="exampleInputEmail1">{docitem.title}</label>
                <div className="input-group input-group-sm">
                    <input defaultValue={docitem.doc_value}  autoComplete='off' type="date" id={"quest_value"} name={"quest_value"} className="form-control" placeholder={docitem.title} />
                </div>
            </div>
        </div>
        <div className='col-3 vcenter-item'>
        {docitem.status}<br></br>
        {docitem.reason_msg}
        </div>
     </div></td></tr>)
}
else if(docitem.type[0]==="file_upload"){
   let uploadedCheck="";
   if(docitem.doc_path!==""){
       uploadedCheck="<a href=\"javascript:void(0)\">View</a>"
   }
return(<tr><td> <div className="row border-bottom">
<div className="col-lg-12 col-md-12">
        <div className="form-group">
            <label for="exampleInputEmail1">{docitem.title}</label>{uploadedCheck!==""?<a onClick={()=>this.viewFile(docitem)} href='javascript:void(0)'>View Uploaded</a>:''}
            <div className="input-group input-group-sm">
                <input type="file" name={"quest_value"} className="form-control" placeholder="Check Contact No." />
            </div>
        </div>
    </div>
    <div className='col-3 vcenter-item'>
        {docitem.status}<br></br>
        {docitem.reason_msg}
        </div>
 </div></td></tr>)
}

}



export const Comp_Qust_Mapping=(props)=>{
  cListQuest=props.listQuest;
  
//alert(JSON.stringify(props.quest));

  return (<div className="modal fade bd-example-modal-xl"  id="divQustMapping" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Questionnair Mapping</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmQustMapping" name="frmQustMapping" enctype="multipart/form-data" method="POST" action="#">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="callBackAddLoan" />
                            <input type="hidden" name="curl_type" id="curl_type" value="updateQuestionMapping" />
                            <input type="hidden" name="m_type" id="m_type" value="updateQuestionMapping" />
                            <input type="hidden" name="type_id" id="type_id" value="2" />
                            <input type="hidden" name="local_token" id="local_token" value={props.logInObj[0].app_user_id}/>
                            <input type="hidden" name="app_user_id" id="app_user_id" value={props.logInObj[0].app_user_id} />
                            <input type="hidden" name="quest_id" id="quest_id" value={typeof props.quest.doc_header!=="undefined"?props.quest["_id"]["$oid"]:'0'}/>
                            <div className="card-body">
                                <div className="row">
                                <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Category *</label>
                                            <div className="input-group input-group-sm">
                                                <b>{props.quest.doc_header}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Sub Header *</label>
                                            <div className="input-group input-group-sm">
                                               <b> {props.quest.sub_category}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">On answer of *</label>
                                            <div className="input-group input-group-sm">
                                              {typeof props.quest.title!=="undefined"?checkAnswers(props.quest):''}
                                                
                                              
                                            </div>
                                        </div>
                                    </div>
                                
                                    </div>
                                   <div className='row'> 
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Sub Question List*</label>
                                            <div className="input-group input-group-sm">
                                                <select id="sub_quest" name="sub_quest[]" multiple="multiple" className="form-control select2 required">
                                                <option value="0">-Select-</option>
                                                  {props.listQuest.map((item,index)=>{
                                                    return (<option value={item["_id"]["$oid"]}>{item["title"]}</option>)
                                                  })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary" onClick={()=>props.UpdQustMapping("frmQustMapping")}>Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>)
}

export const Comp_Remove_Quest_Bulk=(props)=>{
    return (<div className="modal fade bd-example-modal-xl"  id="divBulkRemove" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div className="modal-dialog modal-xl" role="document">
  <div className="modal-content">
  <div className="modal-header bg-primary">
      <h5 className="modal-title text-white" id="exampleModalLabel">Bulk Delete</h5>
      <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true" className="text-white">&times;</span>
      </button>
    </div>
    <form id="frmBulkRemove" name="frmBulkRemove" enctype="multipart/form-data" method="POST" action="#">
   
    <div className="modal-body">
    <div className="row">
              {/* <!-- left column --> */}
              <div className="col-md-12">
                  <div className="card card-danger">
                          <input type="hidden" name="res_func" id="res_func" value="callBackAddLoan" />
                          <input type="hidden" name="curl_type" id="curl_type" value="addCategoryDocList" />
                          <input type="hidden" name="m_type" id="m_type" value="addCategoryDocList" />
                          <input type="hidden" name="type_id" id="type_id" value="2" />
                          <input type="hidden" name="local_token" id="local_token" value={props.logInObj[0].app_user_id}/>
                          <input type="hidden" name="app_user_id" id="app_user_id" value={props.logInObj[0].app_user_id} />
                          <div className="card-body">
                              <div className="row">
                              <div className="col-lg-6 col-md-12">
                                      <div className="form-group">
                                          <label for="exampleInputEmail1">Category *</label>
                                          <div className="input-group input-group-sm">
                                              <select id="loan_category" name="loan_category" className="form-control loan_category required">
                                                <option value="">-Select-</option>
                                              </select>
                                          </div>
                                      </div>
                                  </div>
                                  </div>
                                 <div className='row'> 
                                  <div className="col-lg-6 col-md-12">
                                      <div className="form-group">
                                          <label for="exampleInputEmail1">Year Master *</label>
                                          <div className="input-group input-group-sm">
                                              <select id="year_master" name="year_master" className="form-control year_master required">
                                                <option value="">-Select-</option>
                                              </select>
                                          </div>
                                      </div>
                                  </div>
                                 
                                  
                              </div>
                            
                              <div className="col-md-6 respSSL">* On Submit Click Option ,It will delete all Questionnairs associated with selected Category and FY Year</div>
                              <div id="testing2dd2222"></div>
                          </div>
                      
                  </div>
              </div>
          </div>
    </div>
    <div className="modal-footer">
    <div id="getReponse" style={{color:"red"}}></div>
      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      <button type="button" onClick={()=>props.DeleteBulkQust("#frmBulkRemove")} className="btn btn-primary">Submit Bulk Delete</button>
    </div>
    </form>
  </div>
  </div>
  </div>)
}

export const Comp_Import_Qust=(props)=>{
return (<div className="modal fade bd-example-modal-xl"  id="divImportQuest" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div className="modal-dialog modal-xl" role="document">
  <div className="modal-content">
  <div className="modal-header bg-primary">
      <h5 className="modal-title text-white" id="exampleModalLabel">Documnet List</h5>
      <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true" className="text-white">&times;</span>
      </button>
    </div>
    <form id="frmImportQuest" name="frmImportQuest" enctype="multipart/form-data" method="POST" action="#">
   
    <div className="modal-body">
    <div className="row">
              {/* <!-- left column --> */}
              <div className="col-md-12">
                  <div className="card card-danger">
                          <input type="hidden" name="res_func" id="res_func" value="callBackAddLoan" />
                          <input type="hidden" name="curl_type" id="curl_type" value="addCategoryDocList" />
                          <input type="hidden" name="m_type" id="m_type" value="addCategoryDocList" />
                          <input type="hidden" name="type_id" id="type_id" value="2" />
                          <input type="hidden" name="local_token" id="local_token" value={props.logInObj[0].app_user_id}/>
                          <input type="hidden" name="app_user_id" id="app_user_id" value={props.logInObj[0].app_user_id} />
                          <div className="card-body">
                              <div className="row">
                              <div className="col-lg-6 col-md-12">
                                      <div className="form-group">
                                          <label for="exampleInputEmail1">Category *</label>
                                          <div className="input-group input-group-sm">
                                              <select id="loan_category" name="loan_category" className="form-control loan_category required">
                                                <option value="0">-Select-</option>
                                              </select>
                                          </div>
                                      </div>
                                  </div>
                                  </div>
                                 <div className='row'> 
                                  <div className="col-lg-6 col-md-12">
                                      <div className="form-group">
                                          <label for="exampleInputEmail1">Year Master *</label>
                                          <div className="input-group input-group-sm">
                                              <select id="year_master" name="year_master" className="form-control year_master required">
                                                <option value="0">-Select-</option>
                                              </select>
                                          </div>
                                      </div>
                                  </div>
                                 
                                  <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                          <label for="exampleInputEmail1">Upload File *</label>
                                          <div className="input-group input-group-sm">
                                             <input className="form-control" type="file" id="fl_import" name="fl_import" />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            
                              <div className="col-md-6 respSSL"></div>
                              <div id="testing2dd2222"></div>
                          </div>
                      
                  </div>
              </div>
          </div>
    </div>
    <div className="modal-footer">
    <div id="getReponse" style={{color:"red"}}></div>
      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      <button type="button" onClick={()=>props.UpdImportFile("#frmImportQuest")} className="btn btn-primary">Save changes</button>
    </div>
    </form>
  </div>
  </div>
  </div>)
}
export default Comp_Import_Qust;
import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import QueryString from 'query-string';
import {CallMapMethod,BtnSetColor,webUrlPath,AjaxCallMethod,CallMapMethod_CBack,RespBkMthdMapp} from '../function_lib/common_lib';
// import listReactFiles from 'list-react-files'
import Header from './header';
import Tax_Header from '../user_components/tax_header';
import Side_Bar from './side_bar';
import Footer from './footer';
import LogoImg from '../assets/images/my_logo.jpg';
import defaultPic from '../assets/images/9.png';



let loginUser="";
let userObject="";
let logInObj;
let clsTaxPayMgmt;

let webUrl;
let rf="0";
let queries;
class Tax_Payer_Mgmt extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          //UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= JSON.parse(localStorage.getItem("userDS"));
          logInObj=userObject;
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          //window.location.href="./Dashboard";
        }
        if(props !=null){
            queries = QueryString.parse(this.props.location.search);
                  if(queries.rf!=null){
                    rf=queries.rf;
                  }
          }
          this.state={ listLoanRequest:[],viewLoadDoc:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          webUrl=webUrlPath();
        clsTaxPayMgmt=this;
      }
      getSearch(data){
        if(data==="0"){
          data="";
        }
        //getSearchOutPut("desk_thumb","child_desk_thumb",data);
       }     
// pre data load
getActiveFyYears(){
  var map=[];
  map.push({name:"curl_type",value:"getYearMaster"});
  map.push({name:"res_func",value:"callBackParent"});
  map.push({name:"local_token",value:logInObj[0].app_user_id});
  map.push({name:"parent_id",value:"0"});
  map.push({name:"type_id",value:"4"});
  map["res_func"]="callBackParent";
  map["curl_type"]="getYearMaster";
  CallMapMethod_CBack(clsTaxPayMgmt,map,function(data,msg){
      
      if(data!=="0" && data!==""){
          let listCate=JSON.parse(data);
          var strData="";
          let strYearMaster="<option value=\"0\">-Select Year-</option>";
          for(var i=0;i<listCate.length;i++){
            strYearMaster+="<option value=\""+listCate[i].id+"\">"+listCate[i]["display_year"]+"</option>";
         
          }
          $("#fy_year").html(strYearMaster);
          //$("#serLoanCategroy").html(serLoanMast);
      }
  });
}
getCategory(){
    var map=[];
    map.push({name:"curl_type",value:"getCUserEntry"});
    map.push({name:"res_func",value:"callBackParent"});
    map.push({name:"local_token",value:logInObj[0].app_user_id});
    map.push({name:"parent_id",value:"0"});
    map.push({name:"type_id",value:"4"});
    map["res_func"]="callBackParent";
    map["curl_type"]="getCUserEntry";
    CallMapMethod_CBack(clsTaxPayMgmt,map,function(data,msg){
        
        if(data!=="0" && data!==""){
            let listCate=JSON.parse(data);
            var strData="";
            let strLoanMaster="<option value=\"0\">-Select Tax-</option>";
            let serLoanMast="<option value=\"0\">-Select Tax-</option>";
            for(var i=0;i<listCate.length;i++){
              strLoanMaster+="<option value=\""+listCate[i].id+"\">"+listCate[i]["col1"]+"</option>";
              serLoanMast+="<option value=\""+listCate[i]["col1"]+"\">"+listCate[i]["col1"]+"</option>";
            }
            $("#loan_category").html(strLoanMaster);
            //$("#serLoanCategroy").html(serLoanMast);
        }
    });
  }

  getTaxRequest(status="0"){
    //child component use this method loan_document_upload_comp
    var map=[];
    map.push({name:"curl_type",value:"getTaxPayers"});
    map.push({name:"res_func",value:"callBackloanR"});
    map.push({name:"local_token",value:logInObj[0].app_user_id});
    map.push({name:"user_type",value:logInObj[0].type});
    //alert(status);
    /*
    if(status!=="0"){
      map.push({name:"status",value:status});
    }
    not required as of now
    */
    map.push({name:"parent_id",value:"0"});
    map.push({name:"type_id",value:"4"});
    map["res_func"]="callBackloanR";
    map["curl_type"]="getTaxPayers";
    CallMapMethod_CBack(clsTaxPayMgmt,map,function(data,msg){
      //alert(data);
        if(data!=="0" && data!==""){
            let listData=JSON.parse(data);
            var strData="";
            clsTaxPayMgmt.setState({listLoanRequest:listData},()=>{
              RespBkMthdMapp(clsTaxPayMgmt,"_btnShareOption","");
             
              if(rf!=="0"){
                clsTaxPayMgmt.getSearch(rf);
                //alert("i m in setup "+rf);
                $("#serLoanStatus").val(rf);
              }
            });
            clsTaxPayMgmt.forceUpdateHandler();
           
          }
    });
  }

      
  okSucc(data,smg){
    if(data==="001"){
      alert("record added successfully");
      clsTaxPayMgmt.getTaxRequest();
      $("#frmCreate").find("input[type=text]").val("");
    }
    // alert(data);
  }
      componentDidMount(){

         // alert(userObject[0].type);
         this.getCategory();
         this.getTaxRequest();
         this.getActiveFyYears();

         AjaxCallMethod(this,"okSuccCNOT");
          const script = document.createElement("script");
          script.src = "/main.js";
          script.async = true;
          //script.onload = () => clsObject.scriptLoaded();
        
          document.body.appendChild(script);
         
          //listReactFiles("pub_dir/").then(files => console.log(files))
      }
      
     
     

    render(){
        return(<div className="bg-gray">
            <Header/>
             {/* dashboard tax options  */}
            {/* <Tax_Header/> */}
            <div className="container">
            <div className="row">
                <div className="col-md-12 pb-20px pt-20px">
            <div class="card card-primary card-outline cover-height">
                <div className="card-header">
                <h5 class="card-title1 pb-5"><i class="fa fa-arrow-circle-right"></i> <b>Tax Payers</b><span className="badge bg-primary total_emp"></span><span class="float-right mt-2 badge bg-primary pointer" data-toggle="modal" data-target="#divEmployee">Add Tax Payer</span></h5>
                </div>
              <div class="card-body">
              <table id="desk_thumb" class="dtgrid table table-bordered table-hover text-sm text-nowrap12 table-responsive">
                  <thead>
                    <tr class="border">
                        <th>S.No.</th>
                        <th>Req Date</th>
                        <th>FY</th>
                        <th>Name</th>
                        <th>Contact No</th>
                        <th>Category</th>
                        <th>Status</th>
                        <th>Assign To</th>
                        <th>Created By</th> 
                        <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="tbData">
                    {(this.state.listLoanRequest.map((item,index)=>{
                      $(".total_emp").text((index+1));
                      let checkList=item.first_name+" "+item.last_name;
                      checkList=checkList.replace(/\s/g, '_');
                      return <tr className='child_desk_thumb'>
                        <td>{index+1}</td>
                        <td>{item.request_date}</td>
                        <td>{item.year_display}</td>
                        <td>{item.first_name+" "+item.last_name}</td>
                        <td>{item.contact_no}</td>
                        <td>{item._category}</td>
                        <td>{item.status}</td>
                        <td><a href="javascript:void(0)" onClick={()=>this.viewLoanDoc(item)} data-toggle="modal" data-target="#divAssignUser">{(item.assign_to_name==="" || typeof item.assign_to_name==="undefined" ?"Assign":item.assign_to_name)}</a></td>
                        <td>{item.ins_by_name}</td>
                        <td>
                        
                        <div className="main-menu manu-color-white">
                        <ul>
                          <li class="dropdown">
                        <a className="font-sm" href="#"><span className="badge bg-primary">Action <i class="ion-ios-arrow-down"></i></span></a>
                        <ul class="sub-menu">
                        <li><a class="dropdown-item" href={webUrl+"quest_overview/?doc_rf="+item._id.$oid}  target="_blank">View Questionnair</a></li>
                        <li><a class="dropdown-item" href="javascript:void(0)" title={item._category+" for "+item.first_name+" document request"} url={webUrl+"quest_overview/?doc_rf="+item._id.$oid} text={item._category+" for "+item.first_name+" document request"} className='_btnShareOption'>Get Link</a></li>
                        <li><a target='_blank' href={"https://cms.1040-prep.com/controller/pgControl.php?pm=expLoanPdf&loan_request_id="+item._id.$oid}>Get Pdf</a></li>
                        <li><a  href="javascript:void(0)" onClick={()=>this.deleteLoanRequest(item)}>Delete</a></li>
                          </ul>
                          </li>
                          </ul>
                          </div>
                          </td>
                        {/* <td><a href={webUrl+"quest_overview/?doc_rf="+item._id.$oid} target="_blank">View</a></td> */}
                        {/* <td><a href="javascript:void(0)" onClick={()=>this.deleteLoanRequest(item)}>Delete</a></td> */}
                        {/* <td><a href="javascript:void(0)" title={item._category+" for "+item.first_name+" document request"} url={webUrl+"quest_overview/?doc_rf="+item._id.$oid} text={item._category+" for "+item.first_name+" document request"} className='_btnShareOption'>Get Link</a><br></br> */}
                        {/* <a target='_blank' href={"https://cms.1040-prep.com/controller/pgControl.php?pm=expLoanPdf&loan_request_id="+item._id.$oid}>Get PDF</a> */}
                        {/* </td> */}
                      </tr>
                    }))}
                  </tbody>
                </table>
              </div>
            </div>
            </div>
           
             

             

            </div>
           
  </div>
     
        <Footer/>
{/* add tax payer */}
<div className="modal fade bd-example-modal-xl"  id="divEmployee" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Add New TaxPayer</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmCreate" name="frmCreate">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createTaxRequest" />
                            <input type="hidden" name="m_type" id="m_type" value="createTaxRequest" />
                            <input type="hidden" name="type_id" id="type_id" value="2" />
                            <input type="hidden" name="local_token" id="local_token" value={logInObj[0]["id"]}/>
                            <input type="hidden" name="upper_id" id="upper_id" value={logInObj[0]["id"]}/>
                            <input type="hidden" name="app_user_id" id="app_user_id" value={logInObj[0].app_user_id} />
                            {(logInObj[0].type!=="2")?(<div><input type="hidden" name="ins_by" id="ins_by" value={logInObj[0].id}/>
                            <input type="hidden" name="ins_name" id="ins_name" value={logInObj[0]["first_name"]+" "+logInObj[0].last_name} /></div>):''}
                           
                            <div className="card-body">
                                <div className="row">
                                <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Contact No *(if exist the fields will be auto populated)</label>
                                            <div className="input-group input-group-sm">
                                                <input autoComplete='off' type="text" id="contact_no" name="contact_no" className="form-control" placeholder="Check Contact No." />
                                            </div>
                                        </div>
                                    </div>
                                 </div>
                                 <div className='row'>  
                                 <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">First Name</label>
                                            <div className="input-group input-group-sm">
                                                <input autoComplete='off' type="text" id="first_name" name="first_name" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Last Name</label>
                                            <div className="input-group input-group-sm">
                                                <input autoComplete='off' type="text" id="last_name" name="last_name" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Email id</label>
                                            <div className="input-group input-group-sm">
                                                <input autoComplete='off' type="text" id="email_id" name="email_id" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>    
                                <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Request For *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="loan_category" name="loan_category" className="form-control">
                                                  <option value="0">-Select-</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">FY Year *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="fy_year" name="fy_year" className="form-control">
                                                  <option value="0">-Select-</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>  
                                   
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" onClick={()=>BtnSetColor(this,"frmCreate")} className="btn _btnSetColor_1111 btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>

      </div>)
    }
}
export default Tax_Payer_Mgmt